import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Feature, Overlay, Map } from 'ol';
import { LineString } from 'ol/geom';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Fill } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsMeasurementToolActive,
  toggleTool,
} from 'redux/slices/viewerOptions';
import { getLength } from 'ol/sphere';
import { unByKey } from 'ol/Observable';
import { EventsKey } from 'ol/events';
import { DEFAULT_MICRONS_PER_PIXEL } from 'utils/Constants';
import './style.css';
import { store } from 'redux/store';
import VectorSource from 'ol/source/Vector';
import { ImageInfo } from 'redux/slices/api';

interface MeasurementToolInteractionProps {
  source: VectorSource;
  imageInfo: ImageInfo;
};

export default function MeasurementToolInteraction({ source, imageInfo }: MeasurementToolInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const isMeasurementToolActive = useSelector(selectIsMeasurementToolActive);

  const drawMeasurementLine = useRef<Draw | null>(null);
  const measurementTooltipElement = useRef<HTMLDivElement | null>(null);
  const measurementTooltipOverlay = useRef<Overlay | null>(null);
  const measurementFeature = useRef<Feature | null>(null);
  const geometryChangeListener = useRef<EventsKey | null>(null);

  function createMeasureTooltip(olMap: Map) {
    if (measurementTooltipElement.current && measurementTooltipElement.current.parentNode) {
      measurementTooltipElement.current.parentNode.removeChild(measurementTooltipElement.current);
    }
    measurementTooltipElement.current = document.createElement('div');
    measurementTooltipElement.current.className = 'measurement-tool-tooltip';
    measurementTooltipOverlay.current = new Overlay({
      element: measurementTooltipElement.current,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    olMap.addOverlay(measurementTooltipOverlay.current);
  }

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        if (drawMeasurementLine.current) {
          drawMeasurementLine.current.abortDrawing();
          if (map && measurementTooltipOverlay.current) {
            map.removeOverlay(measurementTooltipOverlay.current);
          }
        }
        break;
      case 'm':
        const { isMeasurementToolActive } = store.getState().viewerOptions;
        if (isMeasurementToolActive && map && measurementTooltipOverlay.current) {
          map.removeOverlay(measurementTooltipOverlay.current);
        }
        dispatch(toggleTool('measurement'))
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMeasurementToolDrawStart = (event: DrawEvent) => {
    if (!map) return;

    measurementFeature.current = event.feature;

    // @ts-ignore - coordinate DOES exist on this DrawEvent
    let tooltipCoord = event.coordinate;

    if (!measurementFeature.current) return;

    geometryChangeListener.current = measurementFeature.current.getGeometry()!.on('change', function (evt) {
      if (!measurementTooltipElement.current || !measurementTooltipOverlay.current) return;

      const geom = evt.target;

      const output = formatLength(geom);
      tooltipCoord = geom.getLastCoordinate();

      measurementTooltipElement.current.innerHTML = output;
      measurementTooltipOverlay.current.setPosition(tooltipCoord);
    });

    createMeasureTooltip(map);
  };

  const handleMeasurementToolDrawEnd = () => {
    if (!map || !geometryChangeListener.current || !measurementTooltipOverlay.current) return;

    measurementFeature.current = null;
    measurementTooltipElement.current = null;
    map.removeOverlay(measurementTooltipOverlay.current);
    unByKey(geometryChangeListener.current);
  };

  /**
   * Format length output.
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  const formatLength = function (line: LineString) {
    const micronsPerPixel = imageInfo.micronsPerPixel ?? DEFAULT_MICRONS_PER_PIXEL;
    const length = getLength(line) * micronsPerPixel;

    let output;
    if (length > 100) {
      output = `${(Math.round((length / 1000) * 100) / 100).toFixed(2)} mm`;
    } else {
      output = `${(Math.round(length * 100) / 100).toFixed(0)} μm`;
    }
    return output;
  };

  useEffect(() => {
    if (!map || !source) return;
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source]);

  useEffect(() => {
    if (!map || !source) return;

    if (isMeasurementToolActive) {
      drawMeasurementLine.current = new Draw({
        source: source,
        type: 'LineString',
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
        maxPoints: 2,
      });

      drawMeasurementLine.current.on('drawstart', handleMeasurementToolDrawStart);

      drawMeasurementLine.current.on('drawend', handleMeasurementToolDrawEnd);

      map.addInteraction(drawMeasurementLine.current);
    } else {
      if (drawMeasurementLine.current) {
        map.removeInteraction(drawMeasurementLine.current);
        drawMeasurementLine.current.un('drawstart', handleMeasurementToolDrawStart);
        drawMeasurementLine.current.un('drawend', handleMeasurementToolDrawEnd);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeasurementToolActive]);

  return null;
}
