import { Alert, Button, Card, Form, Input, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './style.css';
import logo from 'assets/images/mindpeak_logoform_digital_purple_borderless.png';
import { useNavigate } from 'react-router-dom';
import { isUserStudyParticipant } from 'utils/Utils';
import { useAppDispatch } from 'utils/Hooks';
import { authenticateImageServer, selectIsLoginLoading, selectLoginError } from 'redux/slices/userSlice';
import { useSelector } from 'react-redux';

export default function Login() {
  const dispatch = useAppDispatch();
  const loggingIn = useSelector(selectIsLoginLoading);
  const loginError = useSelector(selectLoginError);
  const navigate = useNavigate();

  const handleSubmit = (values: { email: string; password: string }) => {
    dispatch(authenticateImageServer({ username: values['email'].trim(), password: values['password'] })).then(
      (res: any) => {
        if (!res.error) {
          if (isUserStudyParticipant()) {
            notification['info']({
              message: `You are being redirected to study.mindpeak.ai.`,
              duration: 5,
            });

            setTimeout(() => {
              window.location.href = window._env_.REACT_APP_STUDY_URL || 'https://study.mindpeak.ai';
            }, 5000);
          } else {
            navigate('/');
            window.location.reload();
          }
        }
      },
    );
  };

  return (
    <div className="login" style={{ background: '#7AE1BF' }}>
      <div className="form-container">
        <Form className="login-form" initialValues={{ remember: true }} onFinish={handleSubmit} layout="vertical">
          <Card>
            <Form.Item style={{ textAlign: 'center' }}>
              <img width={360} src={logo} alt="Mindpeak Logo" />
            </Form.Item>
            <Form.Item
              label="Username"
              name="email"
              rules={[{ required: true, message: 'Please input your Username!' }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
                autoComplete="current-username"
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Button
              data-test="reset-password-link"
              style={{ padding: 0, float: 'right' }}
              type="link"
              href="/reset-password"
            >
              Forgot password?
            </Button>
            <Form.Item style={{ clear: 'both' }}>
              <Button
                htmlType="submit"
                type="primary"
                style={{ width: '100%', marginTop: 16 }}
                loading={loggingIn}
                size="large"
              >
                {loggingIn ? 'Logging In' : 'Log in'}
              </Button>
            </Form.Item>
            {loginError && <Alert message={loginError.name} description={loginError.message} type="error" showIcon />}
          </Card>
        </Form>
      </div>
    </div>
  );
}
