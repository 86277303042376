import { useDispatch, useSelector } from 'react-redux';
import { Button, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { selectActiveAnnotationClassification, setActiveAnnotationClassification } from 'redux/slices/viewerOptions';

export default function ClearActiveClassificationButton() {
  const dispatch = useDispatch();
  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);

  const handleClearActiveClassification = () => {
    const ClearActiveClassificationButton = document.getElementById('clear-active-classification-button');
    if (ClearActiveClassificationButton) {
      ClearActiveClassificationButton.blur();
    }
    dispatch(setActiveAnnotationClassification(null));
  };

  const __handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'x') {
      handleClearActiveClassification();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
  });

  if (!activeAnnotationClassification) return null;

  return (
    <Tooltip
      title={
        <span>
          Clear Active Classification
          <Tag style={{ marginLeft: 4, marginRight: 0 }}>x</Tag>
        </span>
      }
    >
      <Button
        id="clear-active-classification-button"
        type="text"
        onClick={handleClearActiveClassification}
        icon={<CloseOutlined />}
      />
    </Tooltip>
  );
}
