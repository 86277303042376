import { useSelector } from 'react-redux';
import {
  selectIsTileLoadProgressBarActive,
  selectTileLoadProgressLoaded,
  selectTileLoadProgressLoading,
} from 'redux/slices/viewerOptions';
import './style.css';

export default function TileLoadProgressBar() {
  const isTileLoadProgressBarActive = useSelector(selectIsTileLoadProgressBarActive);
  const tileLoadProgressLoading = useSelector(selectTileLoadProgressLoading);
  const tileLoadProgressLoaded = useSelector(selectTileLoadProgressLoaded);

  if (!isTileLoadProgressBarActive) return null;

  return (
    <div
      id="tile-load-progress-bar"
      style={{ width: ((tileLoadProgressLoaded / tileLoadProgressLoading) * 100).toFixed(1) + '%' }}
    />
  );
}
