import { Alert, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectActiveTool } from 'redux/slices/viewerOptions';

const { Text } = Typography;

export default function CustomPolygonSplitToolOverlay() {
  const activeTool = useSelector(selectActiveTool);

  if (activeTool !== 'polygon-split') return null;

  return (
    <Alert
      style={{ zIndex: 2, position: 'absolute', left: 8, bottom: 40 }}
      message="The Polygon Split Tool is now active"
      description={
        <div>
          <Text>Click once to start drawing a line.</Text>
          <br />
          <Text>Double-click to &apos;complete&apos; the line and split the Polygon.</Text>
          <br />
          <Text>
            Press <Text keyboard>Escape</Text> at any time to cancel/clear the line you are currently drawing.
          </Text>{' '}
          <br />
          <Text>
            Press <Text keyboard>x</Text> at any time to switch between the Split Tool and normal Polygon Annotation
          </Text>
          <br />
          <Text italic>Note: The line must completely intersect the Polygon to split it</Text>
        </div>
      }
      type="info"
      showIcon
      closable
    />
  );
}
