import { Feature as TurfFeature, MultiPolygon, polygon } from '@turf/helpers';
import concaveman from 'concaveman';
import { Polygon as TurfPolygon } from '@turf/helpers/dist/js/lib/geojson';
import { getArea } from 'ol/sphere';
import Polygon from 'ol/geom/Polygon';

export const simplifyMultiPolygon = (multiPolygonGeometry: MultiPolygon) => {
  const polygonCoordinates = multiPolygonGeometry.coordinates.reduce((allCoords, coords) => {
    return [...allCoords, ...coords[0]];
  }, [] as number[][]);
  return polygon([concaveman(polygonCoordinates)]);
};

export const calculatePolygonArea = (polygon: TurfFeature<TurfPolygon | MultiPolygon>) => {
  let polygonArea = 0;
  if (polygon.geometry.type === 'MultiPolygon') {
    polygon.geometry.coordinates.forEach(subPolygon => {
      polygonArea += getArea(new Polygon(subPolygon));
    });
  } else {
    polygonArea = getArea(new Polygon(polygon.geometry.coordinates));
  }
  return polygonArea;
};
