import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { selectIsViewerLocked } from 'redux/slices/viewerOptions';
import { LockOutlined } from '@ant-design/icons';

export default function CustomLockToolOverlay() {
  const isViewerLocked = useSelector(selectIsViewerLocked);

  if (!isViewerLocked) return null;

  return (
    <Alert
      style={{ zIndex: 2, position: 'absolute', left: 8, bottom: 40 }}
      message="The Viewer is currently locked. "
      description={
        <span>
          This means that you cannot zoom, pan, or rotate the Image.
          <br />
          Click the <LockOutlined /> icon, or press 'L' to unlock the viewer and allow movement again.
          <br />
          (<i>Hiding this message will not unlock the viewer.</i>)
        </span>
      }
      type="info"
      showIcon
      closable
    />
  );
}
