import { Badge, Modal, Spin, Tabs } from 'antd';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  clearChangeset,
  selectChangesetWithPointer,
  setSelectedAnnotationIds,
  setWSIRegion,
} from 'redux/slices/annotationDetails';
import {
  selectIsSidebarCollapsed,
  setActiveAnnotationClassification,
  setActiveReviewAnnotation,
  setActiveReviewRowIndex,
  resetAnnotationFilters,
  setAnnotationReviewFilters,
  setIsAnnotatingEnabled,
  setIsAnnotationsVisible,
  setIsSidebarCollapsed,
  setJumpToLocation,
  setShowWSIRegion,
} from 'redux/slices/viewerOptions';
import './style.css';
import CustomPageHeader from 'components/CustomPageHeader';
import { ClassificationSchema, JobAnnotationsSchema, JobSchema } from 'redux/slices/imageServerApi';
import BaseChangesetTable from 'components/ChangesetTables/BaseChangesetTable';
import AnnotationTable from 'components/AnnotationTables/AnnotationTable';
import { useComments } from 'redux/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import JobInfo from './JobInfo';

interface JobSidebarProps {
  job: JobSchema;
  annotations: JobAnnotationsSchema;
  classifications: Array<ClassificationSchema>;
}

const JobSidebar: React.FC<JobSidebarProps> = ({ job, annotations, classifications }) => {
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
  const changeset = useSelector(selectChangesetWithPointer);

  const { comments } = useComments();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCollapse = () => {
    dispatch(setIsSidebarCollapsed(!isSidebarCollapsed));
  };

  const leaveJob = () => {
    batch(() => {
      dispatch(clearChangeset());
      dispatch(setIsAnnotatingEnabled(false));
      dispatch(resetAnnotationFilters());
      dispatch(setAnnotationReviewFilters([]));
      dispatch(setSelectedAnnotationIds([]));
      dispatch(setJumpToLocation(''));
      dispatch(setActiveReviewAnnotation(''));
      dispatch(setActiveReviewRowIndex(0));
      dispatch(setAnnotationReviewFilters([]));
      dispatch(setActiveAnnotationClassification(null));
      dispatch(setIsAnnotationsVisible(true));
      dispatch(setIsSidebarCollapsed(false));
      dispatch(setWSIRegion(null));
      dispatch(setShowWSIRegion(false));
    });
    // Go back one page
    navigate(-1);
  };

  const showLeaveConfirm = () => {
    if (changeset.length > 0) {
      Modal.confirm({
        title: 'You have unsaved changes',
        content: 'Are you sure you want to leave this Job?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: () => leaveJob(),
        onCancel() { },
      });
    } else {
      leaveJob();
    }
  };

  return (
    <>
      <CustomPageHeader
        title={job.id}
        isCollapsed={isSidebarCollapsed}
        onCollapse={onCollapse}
        onBack={showLeaveConfirm}
      />
      <div id="hotspot-details">
        {!isSidebarCollapsed &&
          <Tabs
            defaultActiveKey="1"
            size='small'
            type="card"
            items={[
              {
                key: '1',
                label: 'Job Info',
                children: <JobInfo job={job} />,
              },
              {
                key: '2',
                label: <span><Badge
                  size="small"
                  offset={[-3, -1]}
                  count={changeset.length !== 0 ? <ExclamationCircleOutlined style={{ color: "red" }} /> : 0}
                /> Changeset</span>,
                children: <BaseChangesetTable job={job} changeset={changeset} classifications={classifications} />,
              },
              {
                key: '3',
                label: <span><Badge
                  size="small"
                  offset={[-3, -1]}
                  count={comments.filter(c => !c.is_resolved).length !== 0 ? <ExclamationCircleOutlined style={{ color: "red" }} /> : 0}
                /> Annotations</span>,
                children: <AnnotationTable
                  annotations={annotations}
                  classifications={classifications}
                  task={job.task}
                />,
              },
            ]}
          ></Tabs>
        }
      </div>
    </>
  );
}

export default JobSidebar;
