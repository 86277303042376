import * as turf from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

/**
 * Checks if a DotAnnotation is within a Polygon.
 * @param x the points x coordinate to check
 * @param y the points y coordinate to check
 * @param polygon the polygon to check
 * @returns true if the cell is within the polygon
 */
export const isPointInPolygon = (x: number, y: number, polygon: Array<Array<number>>): boolean => {
  const turfPolygon = turf.polygon([[...polygon, polygon[0]]]);
  return booleanPointInPolygon([x, y], turfPolygon);
};

/**
 * Conserve aspect ratio of the original image region.
 *
 * @param srcWidth width of source image
 * @param srcHeight height of source image
 * @param maxWidth maximum available width
 * @param maxHeight maximum available height
 * @returns object containing width and height
 */
export const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: Math.round(srcWidth * ratio), height: Math.round(srcHeight * ratio) };
};
