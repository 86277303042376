import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { CHANGE_ACTION, USER_GROUP } from 'utils/Constants';
import { AnnotationEventSchema, AnnotationSchema, PartialAnnotationEvent } from 'types';
import { CellAnnotationSchema, PolygonAnnotationSchema, UserSchema, JobAnnotationsSchema } from 'redux/slices/imageServerApi';
import { AnnotationFilters } from 'redux/slices/viewerOptions';
import { AuthenticatedUser } from 'redux/slices/userSlice';

export const getRoundedPolygonPixels = (polygonCoordinates: Array<Array<Array<number>>>) => {
  let roundedCoordinates: Array<Array<number>> = [];
  polygonCoordinates[0].forEach(item => {
    let x = Math.round(item[0]);
    let y = -Math.round(item[1]);
    roundedCoordinates.push([x, y]);
  });

  return roundedCoordinates;
};

export const getRoundedSinglePixel = (dotCoordinate: number[]) => {
  return { x: Math.round(dotCoordinate[0]), y: Math.round(dotCoordinate[1]) };
};

export const storeUserInfo = ({
  accessToken,
  groups,
  allowToAnnotate,
  disableHotspotPulling,
  classificationScenarios,
  assignmentRequestLimit,
}: UserInfo) => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  const existingToken = user?.accessToken;
  const tokenToUse = accessToken || existingToken;

  window.localStorage.setItem(
    'user',
    JSON.stringify({
      accessToken: tokenToUse,
      groups,
      allowToAnnotate,
      disableHotspotPulling,
      classificationScenarios,
      assignmentRequestLimit,
    }),
  );

  if (tokenToUse) {
    const { username } = parseJwt(tokenToUse);
    Sentry.configureScope(scope => {
      scope.setUser({
        username,
      });
    });
  }
};

export const getCurrentUser = (): AuthenticatedUser | null => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  const token = user?.accessToken;

  if (user && !user.groups) {
    user.groups = [user.group];
  }

  if (token) {
    const userToken = parseJwt(token);
    return { ...user, ...userToken };
  }
  return null;
};

export const parseJwt = (token: string | null) => {
  if (!token) return {};
  try {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(
      // eslint-disable-next-line no-undef
      atob(base64Url)
        .split('')
        // eslint-disable-next-line prefer-template
        .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join(''),
    );
    return JSON.parse(base64);
  } catch (error) {
    return {};
  }
};

export const generateAnnotationName = (
  annotationsObject: { [id: string]: { name: string } },
  prefix: string,
) => {
  const annotationsList = Object.values(annotationsObject);
  if (annotationsList.length > 0) {
    const numbers = annotationsList.map(item => {
      return parseInt(item['name'].split('_')[1], 10);
    });
    let s = `00000${Math.max(...numbers) + 1}`;
    return `${prefix}_${s.substr(s.length - 5)}`;
  }
  return `${prefix}_00001`;
};

export const generatePolygonAnnotationName = (
  polygonAnnotations: { [id: string]: PolygonAnnotation },
  changeset: Array<ChangesetItem>,
) => {
  const annotationsList: Array<ChangesetItem | PolygonAnnotation> = [
    ...Object.values(polygonAnnotations),
    ...changeset.filter(change => change.action === CHANGE_ACTION.ADD),
  ];

  if (annotationsList.length > 0) {
    const numbers = annotationsList.map(item => {
      return parseInt(item['name'].split('_')[1], 10);
    });
    let s = `00000${Math.max(...numbers) + 1}`;
    return `p_${s.substr(s.length - 5)}`;
  }
  return `p_00001`;
};

export const lightOrDark = (color: any) => {
  color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

  let r = color >> 16;
  let g = (color >> 8) & 255;
  let b = color & 255;

  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (hsp > 200) {
    return 'light';
  }
  return 'dark';
};

export const captureApplicationError = (error: string) => {
  // eslint-disable-next-line no-console
  console.error('captured error:', error);
  Sentry.captureException(error);
};

export const isUserAnnotator = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.ANNOTATOR);
};

export const isUserPathologist = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.PATHOLOGIST);
};

export const isUserCandidatePathologist = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.CANDIDATE_PATHOLOGIST) || false;
};

export const isUserStudyParticipant = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.STUDY_PARTICIPANT) && getCurrentUser()?.groups.length === 1;
};

export const isUserDataScientist = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.DATA_SCIENTIST);
};

export const isUserQualityAssurance = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.QUALITY_ASSURANCE);
};

export const isUserAnnotationManager = () => {
  return getCurrentUser()?.groups.includes(USER_GROUP.ANNOTATION_MANAGER)
}

export const isUserAdmin = () => {
  return isUserDataScientist() || isUserAnnotationManager() || isUserQualityAssurance();
}

export const combineChangesetEntries = (previousChange: ChangesetItem | null, nextChange: ChangesetItem) => {
  switch (nextChange.action) {
    case CHANGE_ACTION.ADD:
      if (!previousChange) return nextChange;
      if (previousChange.action === CHANGE_ACTION.REMOVE) {
        throw new Error('Changeset corruption: You cannot add an annotation that was already deleted.');
      } else if (previousChange.action === CHANGE_ACTION.UPDATE) {
        throw new Error('Changeset corruption: You cannot update an annotation that was already deleted.');
      }
      return nextChange;
    case CHANGE_ACTION.UPDATE:
      if (!previousChange) {
        if (nextChange.isInDatabase) return nextChange;
        return null;
      }
      if (previousChange.action === CHANGE_ACTION.REMOVE) {
        throw new Error('Changeset corruption: You cannot update an annotation that was already deleted.');
      } else if (previousChange.action === CHANGE_ACTION.ADD) {
        return { ...nextChange, action: CHANGE_ACTION.ADD };
      }
      return nextChange;
    case CHANGE_ACTION.REMOVE:
      if (!previousChange) {
        if (nextChange.isInDatabase) return nextChange;
        return null;
      }
      if (previousChange.action === CHANGE_ACTION.REMOVE) {
        throw new Error('Changeset corruption: You cannot delete an annotation that was already deleted.');
      } else {
        if (nextChange.isInDatabase) return nextChange;
        return null;
      }
    default:
      return nextChange;
  }
};

/**
 * Strips the `action` and `type` properties off of change object. The original change object is not mutated.
 * @param change the object from the changeset
 * @returns the bare annotation object (hotspot, polygon, dot, segmentation)
 */
const stripChangesetProperties = (change: ChangesetItem) => {
  const { action, ...annotation } = change;
  return annotation;
};

/**
 * Iterates through the changes of the changeset and applies all the changes to the provided annotations object,
 * without actually modifying the original annotations object.
 * @param annotations
 * @param changeset
 * @returns {*}
 */
export const applyChangesetChanges = (
  annotations: { [id: string]: DotAnnotation | PolygonAnnotation | Hotspot },
  changeset: Array<ChangesetItem>,
) => {
  const annotationsCopy = JSON.parse(JSON.stringify(annotations));
  return changeset.reduce((annotationsCopy, change) => {
    if (!change.id) throw new Error('Cannot apply change: Changeset actions must have an "id" attribute');
    if (!change.action) throw new Error('Cannot apply change: Changeset actions must have an "action" attribute');
    if (!change.type) throw new Error('Cannot apply change: Changeset actions must have an "type" attribute');
    switch (change.action) {
      case CHANGE_ACTION.ADD:
        if (change.id in annotationsCopy) throw new Error('Cannot apply change because annotation already exists');
        annotationsCopy[change.id] = stripChangesetProperties(change);
        break;
      case CHANGE_ACTION.UPDATE:
        if (!(change.id in annotationsCopy)) throw new Error('Cannot update annotation as it does not exist.');
        annotationsCopy[change.id] = {
          ...annotationsCopy[change.id],
          ...stripChangesetProperties(change),
        };
        break;
      case CHANGE_ACTION.REMOVE:
        if (!(change.id in annotationsCopy)) throw new Error('Cannot remove annotation as it does not exist.');
        delete annotationsCopy[change.id];
        break;
      default:
        break;
    }
    return annotationsCopy;
  }, annotationsCopy);
};

export const createAnnotationEvent = (event: PartialAnnotationEvent): AnnotationEventSchema => {
  if (!event.type || !event.payload) {
    throw new Error('Both type and payload must be defined');
  }
  return { id: uuidv4(), type: event.type, payload: event.payload, timestamp: new Date().toISOString() } as AnnotationEventSchema;
}

/**
 * Applies a series of annotation events to a given set of job annotations.
 *
 * @param {JobAnnotationsSchema} annotations - The original annotations to which events will be applied.
 * @param {AnnotationEventSchema[]} events - An array of annotation events to apply to the annotations.
 *
 * @returns {JobAnnotationsSchema} - A copy of the original annotations with all the specified events applied.
 */
export const applyAnnotationEvents = (annotations: JobAnnotationsSchema, events: AnnotationEventSchema[]): JobAnnotationsSchema => {
  const annotationsCopy: JobAnnotationsSchema = JSON.parse(JSON.stringify(annotations));
  const eventsCopy: AnnotationEventSchema[] = JSON.parse(JSON.stringify(events))
  eventsCopy.forEach(event => {
    const filteredPayload = Object.fromEntries(Object.entries(event.payload).filter(([_, v]) => v != null && v != undefined));
    switch (event.type) {
      case "ADD_CELL":
        annotationsCopy.cells[event.payload.id] = { ...event.payload, is_reviewed: false };
        break;
      case "ADD_POLYGON":
        annotationsCopy.polygons[event.payload.id] = { ...event.payload, is_reviewed: false }
        break;
      case "ADD_ROI":
        annotationsCopy.rois[event.payload.id] = event.payload
        break;
      case "ADD_COMMENT":
        annotationsCopy.comments[event.payload.id] = { ...event.payload, timestamp: event.timestamp, is_resolved: false };
        break;
      case "DELETE_CELL":
        delete annotationsCopy.cells[event.payload.id];
        break;
      case "DELETE_POLYGON":
        delete annotationsCopy.polygons[event.payload.id];
        break;
      case "DELETE_ROI":
        delete annotationsCopy.rois[event.payload.id]
        break;
      case "QA_QUICK_CHECK":
        annotationsCopy.meta.qa = { ...event.payload, timestamp: event.timestamp };
        // Iterate all cells and polygons and flag as reviewed
        Object.keys(annotationsCopy.cells).forEach(key => {
          annotationsCopy.cells[key] = { ...annotationsCopy.cells[key], is_reviewed: true };
        });
        Object.keys(annotationsCopy.polygons).forEach(key => {
          annotationsCopy.polygons[key] = { ...annotationsCopy.polygons[key], is_reviewed: true };
        });
        break
      case "RESOLVE_COMMENT":
        annotationsCopy.comments[event.payload.id].is_resolved = true
        break;
      case "UPDATE_CELL":
        annotationsCopy.cells[event.payload.id] = { ...annotationsCopy.cells[event.payload.id], ...filteredPayload }
        break;
      case "UPDATE_POLYGON":
        annotationsCopy.polygons[event.payload.id] = { ...annotationsCopy.polygons[event.payload.id], ...filteredPayload }
        break;
      case "UPDATE_ROI":
        annotationsCopy.rois[event.payload.id] = { ...annotationsCopy.rois[event.payload.id], ...filteredPayload }
        break;
    }
  })
  return annotationsCopy;
}

/**
 * Simplifies a list of AnnotationEvents by removing redundant events.
 * For example, if a cell is added and then removed, both events are removed.
 * Multiple updates to the same annotation are combined into one.
 *
 * @param {AnnotationEvent[]} events - The list of annotation events to simplify.
 * @returns {AnnotationEvent[]} - The simplified list of annotation events.
 */
export function simplifyChangeset(events: AnnotationEventSchema[]): AnnotationEventSchema[] {
  const annotationState: { [id: string]: AnnotationEventSchema | null } = {};

  for (const event of events) {
    if (event.type === 'QA_QUICK_CHECK') continue;
    const { id } = event.payload;

    switch (event.type) {
      case 'ADD_ROI':
      case 'ADD_CELL':
      case 'ADD_POLYGON':
        annotationState[id] = event;
        break;

      case 'DELETE_CELL':
      case 'DELETE_POLYGON':
      case 'DELETE_ROI':
        if (annotationState[id]?.type.startsWith('ADD_')) {
          annotationState[id] = null;
        } else {
          annotationState[id] = event;
        }
        break;

      case 'UPDATE_CELL':
      case 'UPDATE_ROI':
      case 'UPDATE_POLYGON':
        if (annotationState[id]?.type.startsWith('UPDATE_')) {
          annotationState[id] = {
            ...annotationState[id],
            payload: {
              ...annotationState[id]?.payload,
              ...event.payload,
            },
          };
        } else {
          annotationState[id] = event;
        }
        break;

      default:
        annotationState[id] = event;
        break;
    }
  }

  return Object.values(annotationState).filter(event => event !== null) as AnnotationEventSchema[];
}

/**
 * 
 * @param annotation 
 * @param annotationFilters 
 * @returns 
 */
export const checkIsFiltered = (annotation: PolygonAnnotationSchema | CellAnnotationSchema, annotationFilters: AnnotationFilters | null) => {
  let is_filtered = false;

  if (annotationFilters) {
    if (annotationFilters.class_id && !annotationFilters.class_id.includes(annotation.class_id)) {
      is_filtered = true;
    } else if (annotationFilters.is_reviewed && !annotationFilters.is_reviewed.includes(annotation.is_reviewed)) {
      is_filtered = true;
    }
  }

  return is_filtered;
};