import React, { useEffect, useState } from 'react';
import { Table, TableProps, TableColumnsType, Button, Space, Row, Col, Typography, Tooltip } from 'antd';
import { GetImagesApiArg, ImageSchema, useGetImagesQuery, useGetLabsQuery, useGetOrgansQuery, useGetScannersQuery, useGetStainingsQuery, useGetTumorSubtypesQuery, useGetTumorTypesQuery } from 'redux/slices/imageServerApi';
import { useFilters } from 'redux/hooks';
import { CheckCircleTwoTone, CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import PipelineCreationModal from 'components/PipelineCreationModal';

interface ImageTableProps {
}

type TableRowSelection<T> = TableProps<T>['rowSelection'];

const { Text } = Typography;

export const ImageTable: React.FC<ImageTableProps> = () => {
  const [page, setPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pageSize] = useState<number>(100);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useFilters<GetImagesApiArg>();

  const { data, isLoading, isFetching, refetch } = useGetImagesQuery({ ...filters, page })
  const { data: labs } = useGetLabsQuery();
  const { data: stainings } = useGetStainingsQuery();
  const { data: organs } = useGetOrgansQuery();
  const { data: scanners } = useGetScannersQuery();
  const { data: tumorTypes } = useGetTumorTypesQuery();
  const { data: tumorSubtypes } = useGetTumorSubtypesQuery();

  const columns: TableColumnsType<ImageSchema> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Lab',
      dataIndex: ['scan', 'lab', 'name'],
      key: 'labId',
      filters: labs?.map(l => ({ text: l.name, value: l.id })),
      filteredValue: filters.labId,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Staining',
      dataIndex: ['scan', 'staining', 'name'],
      key: 'staining',
      filters: stainings?.map(s => ({ text: s.name, value: s.id })),
      filteredValue: filters.staining,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Scanner',
      dataIndex: ['scan', 'scanner'],
      key: 'scanner',
      filters: scanners?.map(s => ({ text: s.name, value: s.id })),
      filteredValue: filters.scanner,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Organ',
      dataIndex: ['scan', 'organ', 'name'],
      key: 'organ',
      filters: organs?.map(o => ({ text: o.name, value: o.id })),
      filteredValue: filters.organ,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Tumor Type',
      dataIndex: ['scan', 'tumor_type', 'name'],
      key: 'tumorType',
      filters: tumorTypes?.map(tt => ({ text: tt.name, value: tt.id })),
      filteredValue: filters.tumorType,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Tumor Subtype',
      dataIndex: ['scan', 'tumor_subtype', 'name'],
      key: 'tumorSubtype',
      filters: tumorSubtypes?.map(tst => ({ text: tst.name, value: tst.id })),
      filteredValue: filters.tumorSubtype,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Has Annotations',
      dataIndex: 'num_pipelines',
      key: 'hasAnnotations',
      render: (value) => value !== 0 ? <Tooltip title={`${value} Pipelines`}><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> : <CloseCircleTwoTone twoToneColor="red" />,
      filters: [{ text: 'True', value: true }, { text: 'False', value: false }],
      filteredValue: filters.hasAnnotations,
      filterMultiple: false,
    }
  ];

  const images = data ? data.items : [];
  const totalItems = data ? data.count : 0;

  const handleSelectRowChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleTableChange = (pagination: any, filters: any) => {
    setPage(pagination.current);
    setFilters(filters);
    refetch();
  };

  const handleButtonCreatePipelines = () => {
    setIsModalOpen(true);
  };

  const handleRowClick = (record: ImageSchema) => {
    const selectedKey = record.id!;
    setSelectedRowKeys((prevSelectedKeys) => {
      const isSelected = prevSelectedKeys.includes(selectedKey);
      if (isSelected) {
        return prevSelectedKeys.filter(key => key !== selectedKey);
      } else {
        return [...prevSelectedKeys, selectedKey];
      }
    });
  };

  const rowSelection: TableRowSelection<ImageSchema> = {
    selectedRowKeys,
    onChange: handleSelectRowChange,
    preserveSelectedRowKeys: true
  };

  useEffect(() => {
    // Reset selection when Filters are changed
    setSelectedRowKeys([]);
  }, [filters])

  return (
    <>
      <Row style={{ padding: '8px' }} justify="space-between">
        <Col>
          <Text strong style={{ lineHeight: '32px' }}>{selectedRowKeys.length} Images selected</Text>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleButtonCreatePipelines}
            disabled={selectedRowKeys.length === 0}
          >
            Create Annotation Pipelines
          </Button>
        </Col>
      </Row>
      <PipelineCreationModal open={isModalOpen} onCancel={() => { setIsModalOpen(false) }} imageIds={selectedRowKeys} />
      <Table
        loading={isLoading || isFetching}
        dataSource={images}
        columns={columns}
        rowKey="id"
        pagination={{ current: page, pageSize, total: totalItems, showTotal: total => `${total} images` }}
        onChange={handleTableChange}
        rowSelection={rowSelection}
        sticky
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </>
  );
};
