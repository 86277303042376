import { AimOutlined, ClockCircleOutlined, PartitionOutlined, PictureOutlined, ReconciliationOutlined, UserOutlined } from "@ant-design/icons";
import { List, Space, Spin } from "antd";
import { formatDistanceToNow } from 'date-fns';
import { JobSchema, useGetClassificationScenarioQuery, useGetGroupsQuery, useGetTaskQuery } from "redux/slices/imageServerApi"
import { isUserAdmin } from "utils/Utils";

interface JobInfoProps {
    job: JobSchema
}

const JobInfo: React.FC<JobInfoProps> = ({ job }) => {
    const { data: task } = useGetTaskQuery({ name: job.task });
    const { data: userGroups } = useGetGroupsQuery({ skip: !isUserAdmin() });
    const { data: classificationScenario } = useGetClassificationScenarioQuery({ id: job.classification_scenario! }, { skip: !job.classification_scenario })

    if (!task) {
        return <Spin />
    }

    const listItems = [
        {
            title: 'Image',
            icon: <PictureOutlined />,
            text: job.image.name
        },
        {
            title: 'Classification Scenario',
            icon: <PartitionOutlined />,
            text: classificationScenario?.name
        },
        {
            title: 'Task Description',
            icon: <ReconciliationOutlined />,
            text: task.description
        },
        {
            title: 'Eligible User Group',
            icon: <UserOutlined />,
            text: userGroups?.find(ug => ug.id === task.eligible_user_group)?.name || 'loading...'
        },
        {
            title: 'Annotations',
            icon: <AimOutlined />,
            text: `${job.num_annotation_events} Annotation Events`
        },
    ]

    if (job.assigned_at) {
        listItems.push({
            title: 'Assignment',
            icon: <ClockCircleOutlined />,
            text: `assigned ${formatDistanceToNow(new Date(job.assigned_at!), { addSuffix: true })}`
        })
    }

    return (
        <div style={{ padding: '10px' }}>
            <List
                itemLayout="horizontal"
                dataSource={listItems}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<span style={{ fontSize: "1.5em", color: "gray" }}>{item.icon}</span>}
                            title={item.title}
                            description={item.text}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}

export default JobInfo