import { useContext, useEffect, useRef } from 'react';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import VectorLayer from 'ol/layer/Vector';
import Stroke from 'ol/style/Stroke';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { useSelector } from 'react-redux';

import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import {
  selectSelectedPolygonIssue,
} from 'redux/slices/annotationDetails';

import { OL_LAYER_NAME } from 'utils/Constants';
import { selectIsAnnotationsVisible } from 'redux/slices/viewerOptions';
import VectorSource from 'ol/source/Vector';
import { useGetClassificationsQuery } from 'redux/slices/imageServerApi';

interface PolygonAnnotationLayerProps {
  source: VectorSource<Feature<Polygon>>;
  polygonAnnotations: Array<PolygonAnnotation>;
  classifications: Array<Classification>;
  visible?: boolean;
}

export default function PolygonAnnotationIssuesLayer({ source }: PolygonAnnotationLayerProps) {
  const { map } = useContext(OpenLayersMapContext);
  const { data: classifications } = useGetClassificationsQuery();
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const selectedPolygonIssue: any = useSelector(selectSelectedPolygonIssue);
  let polygonAnnotationIssuesLayer = useRef<VectorLayer<VectorSource<Feature<Polygon>>> | null>(null);

  const isLayerActive = map && classifications && classifications.length > 0;

  useEffect(() => {
    if (!map || !isLayerActive || classifications.length === 0) return;
    polygonAnnotationIssuesLayer.current = new VectorLayer({
      className: 'polygon-annotation-issues-layer',
      properties: { name: OL_LAYER_NAME.ANNOTATION_POLYGON_ISSUE },
      source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      zIndex: 100,
      style: new Style({
        stroke: new Stroke({
          color: '#ff0000',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 1.0)',
        }),
      }),
    });
    map.addLayer(polygonAnnotationIssuesLayer.current);

    return () => {
      if (map) {
        if (polygonAnnotationIssuesLayer.current) {
          map.removeLayer(polygonAnnotationIssuesLayer.current);
        }
        if (source) {
          source.clear();
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source, classifications]);

  useEffect(() => {
    if (!isLayerActive || !map || !polygonAnnotationIssuesLayer.current) return;
    polygonAnnotationIssuesLayer.current.setVisible(isAnnotationsVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotationsVisible]);

  useEffect(() => {
    if (!source || !polygonAnnotationIssuesLayer.current) return;
    polygonAnnotationIssuesLayer.current.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonIssue]);

  useEffect(() => {
    if (!isLayerActive || !source) return;
    source.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  useEffect(() => {
    if (!isLayerActive) return;

    if (!selectedPolygonIssue) {
      source.clear();
      return;
    }

    let newPolygonAnnotations: Feature<Polygon>[] = [];
    Object.entries(selectedPolygonIssue.suggestedPolygons).forEach(([id, suggestedPolygon]) => {
      const suggestedPolygonCoordinates = (suggestedPolygon as SuggestedPolygon).coordinates;

      if (!suggestedPolygonCoordinates) return;

      let newOLPolygonSuggested = new Feature<Polygon>({});
      newOLPolygonSuggested.setGeometry(new Polygon(suggestedPolygonCoordinates as any));
      newOLPolygonSuggested.setId(id);
      newOLPolygonSuggested.set('classification', selectedPolygonIssue.originalPolygonAnnotation.classification);
      newOLPolygonSuggested.set('is_reviewed', selectedPolygonIssue.originalPolygonAnnotation.is_reviewed);
      newOLPolygonSuggested.set('is_closed', true);
      newOLPolygonSuggested.set('is_filtered', selectedPolygonIssue.originalPolygonAnnotation.isFiltered);
      newPolygonAnnotations.push(newOLPolygonSuggested);

      const suggestedPolygonDiff = (suggestedPolygon as SuggestedPolygon).diff;
      if (!suggestedPolygonDiff) return;

      let newOLPolygonDiff = new Feature<Polygon>({});
      newOLPolygonDiff.setGeometry(new Polygon(suggestedPolygonDiff as any));
      newOLPolygonDiff.setId(`${id}-diff`);
      newOLPolygonDiff.set('classification', selectedPolygonIssue.originalPolygonAnnotation.classification);
      newOLPolygonDiff.set('is_reviewed', selectedPolygonIssue.originalPolygonAnnotation.is_reviewed);
      newOLPolygonDiff.set('is_closed', true);
      newOLPolygonDiff.set('is_filtered', selectedPolygonIssue.originalPolygonAnnotation.isFiltered);
      newOLPolygonDiff.set('is_diff', true);
      newPolygonAnnotations.push(newOLPolygonDiff);
    });

    source.clear();
    source.addFeatures(newPolygonAnnotations);
    source.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonIssue, classifications]);

  return null;
}
