import { Button, Checkbox, Col, Empty, Form, Layout, Radio, Row, Select, Spin } from 'antd';
import OpenLayersMapComposition from 'components/OpenLayersMapComposition';
import ViewerFooter from 'components/Footer';
import { useSelector } from 'react-redux';
import { selectIsSidebarCollapsed } from 'redux/slices/viewerOptions';
import { useGetTumorTypesQuery, useGetSignificantCharacteristicsQuery, useGetSpecimenTypesQuery, useUpdateScanMutation, ScanSchema, useGetOrgansQuery } from 'redux/slices/imageServerApi';
import "./style.css"
import { useImage } from 'redux/hooks';
import type { FormProps } from 'antd';
import WSIRegionControl from 'components/ViewerOptions/WSIRegionControl';

const { Sider } = Layout;

type FieldTypes = {
    imageType?: string;
    organ?: number;
    tumorType?: number;
    exclude: ScanSchema['exclude'];
    assessable?: boolean;
    productScope?: boolean;
    tumorLocation?: string;
    significantCharacteristics?: Array<string>;
    specimenType?: string;
};

const ImageQALayout: React.FC = () => {
    const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
    const { image } = useImage();
    const { data: organs, isFetching: isFetchingOrgans } = useGetOrgansQuery();
    const { data: tumorTypes, isFetching: isFetchingTumorTypes } = useGetTumorTypesQuery();
    const { data: significantCharacteristics, isFetching: isFetchingSignificantCharacteristics } = useGetSignificantCharacteristicsQuery();
    const { data: specimenTypes, isFetching: isFetchingSpecimenTypes } = useGetSpecimenTypesQuery();
    const [updateScan] = useUpdateScanMutation();

    const [form] = Form.useForm();

    if (!image) return null;

    const onFinish: FormProps<FieldTypes>['onFinish'] = async (values) => {
        if (!image.scan) return;

        await updateScan({
            anonymizationName: image.scan.anonymization_name,
            updateScanSchema: {
                assessable: values.assessable ?? null,
                exclude: values.exclude ?? null,
                image_type: values.imageType ?? null,
                organ: values.organ ?? null,
                product_scope: values.productScope ?? null,
                significant_characteristics: values.significantCharacteristics ?? null,
                specimen_type: values.specimenType ?? null,
                tumor_location: values.tumorLocation ?? null,
                tumor_type: values.tumorType ?? null,
            }
        }).unwrap();
    };

    const renderScanQAForm = (scan: ScanSchema) => {
        const initialValues: FieldTypes = {
            imageType: scan.image_type ?? undefined,
            organ: scan.organ?.id ?? undefined,
            tumorType: scan.tumor_type?.id ?? undefined,
            exclude: scan.exclude,
            assessable: scan.assessable ?? undefined,
            productScope: scan.product_scope ?? undefined,
            tumorLocation: scan.tumor_location ?? undefined,
            significantCharacteristics: scan.significant_characteristics ?? [],
            specimenType: scan.specimen_type ?? undefined
        }
        return (
            <>
                <WSIRegionControl />
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={initialValues}
                    style={{ padding: '10px', maxWidth: 600 }}
                    onFinish={onFinish}
                    onFinishFailed={(e) => { console.log(e) }}
                >
                    <Form.Item<FieldTypes> label="Image Type" name="imageType">
                        <Radio.Group>
                            <Radio.Button value="wsi">WSI</Radio.Button>
                            <Radio.Button value="fov">FOV</Radio.Button>
                            <Radio.Button value="screen">Screenshot</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item<FieldTypes> label="Tumor Location" name="tumorLocation">
                        <Radio.Group>
                            <Radio.Button value="primary">Primary</Radio.Button>
                            <Radio.Button value="metastatic">Metastatic</Radio.Button>
                            <Radio.Button value="unclear">Unclear</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item<FieldTypes> label="Organ" name="organ">
                        <Select
                            options={organs?.map(o => ({ value: o.id, label: o.name }))}
                            loading={isFetchingOrgans}
                        />
                    </Form.Item>
                    <Form.Item<FieldTypes> label="Tumor type" name="tumorType">
                        <Select
                            options={tumorTypes?.map(t => ({ value: t.id, label: t.name }))}
                            loading={isFetchingTumorTypes}
                        />
                    </Form.Item>
                    <Form.Item<FieldTypes> label="Specimen type" name="specimenType">
                        <Select
                            options={specimenTypes?.map(s => ({ value: s.name, label: s.name }))}
                            loading={isFetchingSpecimenTypes}
                        />
                    </Form.Item>
                    <Form.Item<FieldTypes> label="Significant Characteristics" name="significantCharacteristics">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={significantCharacteristics?.map(sc => ({ value: sc.name, label: sc.name }))}
                            loading={isFetchingSignificantCharacteristics}
                        />
                    </Form.Item>
                    <Row>
                        <Col span={7}>
                            <Form.Item<FieldTypes> name="exclude" valuePropName="checked">
                                <Checkbox>
                                    Exclude
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item<FieldTypes> name="assessable" valuePropName="checked">
                                <Checkbox>
                                    Assessable
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item<FieldTypes> name="productScope" valuePropName="checked">
                                <Checkbox>
                                    Product Scope
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* TODO: text area for comments/notes */}
                    <Form.Item<FieldTypes>>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }

    return (
        <Layout>
            {
                <Sider
                    collapsible
                    collapsed={isSidebarCollapsed}
                    className="annotation-tool-sider"
                    collapsedWidth={80}
                    width={360}
                    trigger={null}
                    theme="light"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                >
                    {image.scan ?
                        renderScanQAForm(image.scan) :
                        <Empty description="No Scan data available" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </Sider>
            }

            <Layout style={{ overflow: 'auto' }} className="annotation-tool-layout-content">
                <OpenLayersMapComposition image={image} />
                <ViewerFooter image={image} />
            </Layout>
        </Layout>
    )
}

export default ImageQALayout;
