import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Empty, Table, Tag, Typography } from 'antd';
import { selectScrollToAnnotationId, setJumpToLocation } from 'redux/slices/viewerOptions';
import { CHANGE_ACTION } from 'utils/Constants';
import { lightOrDark } from 'utils/Utils';
import scrollIntoView from 'scroll-into-view';
import { setSelectedAnnotationIds } from 'redux/slices/annotationDetails';
import { AnnotationEventSchema } from 'types';
import { ClassificationSchema, JobSchema, useGetClassificationScenarioQuery } from 'redux/slices/imageServerApi';

const { Text } = Typography;

interface BaseChangesetTableProps {
  changeset: Array<AnnotationEventSchema>;
  job: JobSchema;
  classifications: Array<ClassificationSchema>;
};

export default function BaseChangesetTable({ changeset, job, classifications }: BaseChangesetTableProps) {
  const dispatch = useDispatch();
  const scrollToAnnotationId = useSelector(selectScrollToAnnotationId);

  const prevScrollToAnnotationIdRef = useRef<string | null>();

  useEffect(() => {
    if (scrollToAnnotationId && prevScrollToAnnotationIdRef.current !== scrollToAnnotationId) {
      const dataRowItem = document.querySelector(`[data-row-key="${scrollToAnnotationId}"]`);
      if (dataRowItem) {
        scrollIntoView(dataRowItem, {
          align: {
            top: 0,
          },
        });
      }
      prevScrollToAnnotationIdRef.current = scrollToAnnotationId;
    }
  }, [scrollToAnnotationId]);

  return (
    <Table
      className="changeset-table"
      dataSource={changeset}
      size="small"
      pagination={false}
      scroll={{ y: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 222 }}
      rowKey="id"
      onRow={record => {
        return {
          onClick: () => {
            dispatch(setJumpToLocation(record.payload.id));
          },
          onMouseEnter: () => {
            dispatch(setSelectedAnnotationIds([record.payload.id]));
          },
          onMouseLeave: () => {
            dispatch(setSelectedAnnotationIds([]));
          },
        };
      }}
      showHeader={false}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You have not made any unsaved changes" />,
      }}
    >
      <Table.Column
        title="Annotations"
        key="classification"
        render={(event: AnnotationEventSchema) => {

          const associatedClass = classifications.find(x => x.name === event.payload.class_id!);
          const tagColor = associatedClass ? associatedClass.color : '#000000';
          const tagText = associatedClass?.display_name;

          return {
            props: {
              className: scrollToAnnotationId === event.id ? 'data-row viewer-data-active-row' : 'data-row',
            },
            children: (
              <div data-test="annotation-changeset-row">
                <Text>{event.type}</Text>
                {event.payload.name && <Text code>{event.payload.name}</Text>}
                {tagText && <Tag
                  color={tagColor}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    color: lightOrDark(tagColor) === 'light' ? '#000000' : undefined,
                  }}
                >
                  {tagText}
                </Tag>}
              </div>
            ),
          };
        }}
      />
    </Table>
  );
}
