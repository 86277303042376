import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Select from 'ol/interaction/Select';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedPolygonIssue } from 'redux/slices/annotationDetails';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import { Collection } from 'ol';
import { Layer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';

interface PolygonAnnotationIssuesInteractionProps {
  source: VectorSource;
};


export default function PolygonAnnotationIssuesInteraction({ source }: PolygonAnnotationIssuesInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);

  const selectedPolygonIssue = useSelector(selectSelectedPolygonIssue);

  const selectPolygonAnnotationRef = useRef<Select | null>(null);

  useEffect(() => {
    if (!map) return;

    selectPolygonAnnotationRef.current = new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.ANNOTATION_POLYGON_ISSUE;
      },
      condition: () => false,
      addCondition: () => false,
      removeCondition: () => false,
      toggleCondition: () => false,
      multi: false,
      filter: () => false,
      features: new Collection([]),
      style: feature => {
        if (feature.get('is_diff') === true) {
          return [
            new Style({
              stroke: new Stroke({
                color: '#ffffff',
                width: 5,
              }),
            }),
            new Style({
              stroke: new Stroke({
                color: '#ff0000',
                width: 3,
              }),
            }),
          ];
        }

        return [
          new Style({
            stroke: new Stroke({
              color: '#ffffff',
              width: 5,
            }),
          }),
          new Style({
            stroke: new Stroke({
              color: '#0099ff',
              width: 3,
            }),
          }),
        ];
      },
    });
    map.addInteraction(selectPolygonAnnotationRef.current);
  }, [map]);

  useEffect(() => {
    if (!map || !selectedPolygonIssue) return;
    if (selectPolygonAnnotationRef.current) {
      selectPolygonAnnotationRef.current.getFeatures().clear();
      selectPolygonAnnotationRef.current.getFeatures().extend(source.getFeatures());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonIssue]);

  return null;
}
