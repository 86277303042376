import { Modal, Form, Select, Button, InputNumber } from "antd";
import React from "react";
import { useCreatePipelineMutation, useGetBacklogsQuery, useGetClassificationScenariosQuery, useGetWorkflowsQuery } from "redux/slices/imageServerApi";

interface PipelineCreationModalProps {
    open: boolean;
    onCancel: () => void;
    imageIds: React.Key[];
}

const PipelineCreationModal: React.FC<PipelineCreationModalProps> = ({
    open, onCancel, imageIds
}) => {
    const { data: classificationScenarios, isFetching: isFetchingClassScenarios } = useGetClassificationScenariosQuery();
    const { data: backlogs, isFetching: isFetchingBacklogs } = useGetBacklogsQuery();
    const { data: workflows, isFetching: isFetchingWorkflows } = useGetWorkflowsQuery();
    const [createPipeline] = useCreatePipelineMutation();
    const [form] = Form.useForm();

    const handleFinish = async (values: any) => {
        const payload = {
            ...values,
            image_ids: imageIds,
        };
        await createPipeline({ pipelineCreateSchema: payload }).unwrap();
        onCancel(); // Close the modal on successful submission
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            title="Create Annotation Pipelines"
            footer={null} // We'll use form buttons instead of Modal buttons
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Classification Scenario"
                    name="classification_scenario_id"
                    rules={[{ required: true, message: 'Please select a classification scenario!' }]}
                >
                    <Select
                        loading={isFetchingClassScenarios}
                        placeholder="Select a classification scenario"
                        options={classificationScenarios?.map(cs => ({ value: cs.id, label: cs.name }))}
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        showSearch
                    />
                </Form.Item>

                <Form.Item
                    label="Backlog"
                    name="backlog_id"
                >
                    <Select
                        loading={isFetchingBacklogs}
                        placeholder="Select a backlog"
                        options={backlogs?.map(b => ({ value: b.id, label: b.name }))}
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        showSearch
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    label="Workflow"
                    name="workflow"
                    rules={[{ required: true, message: 'Please select a workflow!' }]}
                >
                    <Select
                        loading={isFetchingWorkflows}
                        placeholder="Select a workflow"
                        options={workflows?.map(w => ({ value: w.name, label: w.name }))}
                    />
                </Form.Item>

                <Form.Item
                    label="Priority"
                    name="priority"
                >
                    <InputNumber min={0} max={10} placeholder="Enter a priority" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PipelineCreationModal;
