import { LinkOutlined } from "@ant-design/icons";
import { Button, Card, Layout, Progress, Spin } from "antd"
import { Col, Row } from 'antd';
import { BacklogSchema, useGetBacklogsQuery } from "redux/slices/imageServerApi"

interface BacklogGridProps { }

const BacklogGrid: React.FC<BacklogGridProps> = () => {
    const { data: backlogs, isLoading, isFetching } = useGetBacklogsQuery();

    const renderBacklogCard = (backlog: BacklogSchema) => (
        <Col className="gutter-row" span={3} key={backlog.id}> {/* Adjust span to 6 for better width in a 24-grid system */}
            <Card title={backlog.name} style={{ width: '100%', textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Progress type="circle" style={{ marginBottom: '10px' }} percent={Math.round(backlog.num_output_jobs / backlog.num_leaf_node_jobs * 100)} />
                    <Button type="default" icon={<LinkOutlined />} style={{ margin: '5px', width: 'fit-content' }}>
                        {backlog.num_jobs_total} Jobs in total
                    </Button>
                    <Button type="default" icon={<LinkOutlined />} style={{ margin: '5px', width: 'fit-content' }}>
                        {backlog.num_jobs_completed} Jobs Completed
                    </Button>
                    <Button type="default" icon={<LinkOutlined />} style={{ margin: '5px', width: 'fit-content' }}>
                        {backlog.num_jobs_total - backlog.num_jobs_completed} WIP Jobs
                    </Button>
                    <Button
                        icon={<LinkOutlined />}
                        href={`/annotation-outputs?backlogId=${backlog.id}`}
                        style={{ margin: '5px', width: 'fit-content' }}
                        target="_blank"
                    >
                        Outputs: {backlog.num_output_jobs}
                    </Button>
                    <Button
                        icon={<LinkOutlined />}
                        href={`/annotation-outputs?backlogId=${backlog.id}`}
                        style={{ margin: '5px', width: 'fit-content' }}
                        target="_blank"
                    >
                        Leaf nodes: {backlog.num_leaf_node_jobs}
                    </Button>
                </div>
            </Card>
        </Col>
    )

    return (
        <Layout style={{ padding: '20px' }}>
            <Spin spinning={isLoading || isFetching}>
                <Row gutter={[16, 24]}>
                    {
                        backlogs?.map(renderBacklogCard)
                    }
                </Row>
            </Spin>
        </Layout>
    )
}

export default BacklogGrid;
