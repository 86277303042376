import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseJwt, storeUserInfo } from 'utils/Utils';
import { backendUrl, getRequestWithAuth } from 'service/api';
import { AppDispatch, RootState } from 'redux/store';

export type AuthenticatedUser = {
  accessToken: string;
  allowToAnnotate: boolean;
  assignmentRequestLimit: number;
  classificationScenarios: Array<{ name: string, allow_polygon_annotation: boolean }>;
  disableHotspotPulling: boolean;
  groups: Array<string>;
  labId: number;
}

export const authenticateImageServer = createAsyncThunk<
  AuthenticatedUser,
  { username: string; password: string },
  { state: RootState; dispatch: AppDispatch; rejectWithValue: any }
>('user/authenticateImageServer', async payload => {
  const { username, password } = payload;
  const authenticationUrl = backendUrl('user/authenticate/');

  const bodyFormData = new window.FormData();
  bodyFormData.append('email', username);
  bodyFormData.append('password', password);

  const response = await fetch(authenticationUrl, {
    method: 'POST',
    body: bodyFormData,
  });

  const responseJson = await response.json();

  if (!response.ok) {
    throw new Error(responseJson['user'][0]);
  }

  return {
    accessToken: responseJson.token,
    allowToAnnotate: responseJson.allow_to_annotate,
    assignmentRequestLimit: responseJson.assignment_request_limit,
    classificationScenarios: responseJson.classification_scenarios,
    disableHotspotPulling: responseJson.disable_hotspot_pulling,
    groups: responseJson.groups,
    labId: responseJson.lab_id
  };
});

export const refreshAuthentication = createAsyncThunk(
  'user/refreshAuthentication',
  async (payload, { rejectWithValue, signal }) => {
    const authenticationUrl = backendUrl('user/refresh_authentication/');

    const response = await getRequestWithAuth(authenticationUrl, signal);
    if (response.status === 404 || response.status === 500) {
      return rejectWithValue(null);
    }
    const responseJson = await response.json();

    if (!response.ok) {
      throw new Error(responseJson['user'][0]);
    }

    return {
      accessToken: responseJson.token,
      allowToAnnotate: responseJson.allow_to_annotate,
      assignmentRequestLimit: responseJson.assignment_request_limit,
      classificationScenarios: responseJson.classification_scenarios,
      disableHotspotPulling: responseJson.disable_hotspot_pulling,
      groups: responseJson.groups,
      labId: responseJson.lab_id
    };
  },
);

interface UserState {
  authenticatedUser: AuthenticatedUser | null,
  groups: Array<string>;
  allowToAnnotate: boolean;
  disableHotspotPulling: boolean;
  classificationScenarios: Array<string>;
  assignmentRequestLimit: number;
  isReAuthDialogShown: boolean;
  accessToken: string | null;
  isLoginLoading: boolean;
  loginError: any;
};

const initialState = {
  authenticatedUser: null,
  groups: [],
  allowToAnnotate: false,
  disableHotspotPulling: false,
  classificationScenarios: [],
  assignmentRequestLimit: 1,
  isReAuthDialogShown: false,
  accessToken: null,
  isLoginLoading: false,
  loginError: null,
} as UserState;

const loginErrorReducer = (state: UserState, payload: { error: any }) => {
  const { error } = payload;
  state.isLoginLoading = false;
  state.loginError = error;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsReAuthDialogShown: (state, action) => {
      state.isReAuthDialogShown = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(authenticateImageServer.pending, state => {
      state.isLoginLoading = true;
    });

    builder.addCase(authenticateImageServer.fulfilled, (state, action) => {
      state.authenticatedUser = action.payload;
      state.isReAuthDialogShown = false;
      state.loginError = null;
      state.isLoginLoading = false;

      localStorage.setItem('user', JSON.stringify(action.payload));
    });

    builder.addCase(authenticateImageServer.rejected, loginErrorReducer);

    builder.addCase(refreshAuthentication.fulfilled, (state, action) => {
      state.authenticatedUser = action.payload;
      state.isReAuthDialogShown = false;

      localStorage.setItem('user', JSON.stringify(action.payload));
    });
    builder.addCase(refreshAuthentication.rejected, state => {
      state.isReAuthDialogShown = true;
    });
  },
});

export const { setIsReAuthDialogShown } = userSlice.actions;

export const selectIsLoginLoading = (state: RootState) => state.user.isLoginLoading;
export const selectLoginError = (state: RootState) => state.user.loginError;
export const selectIsReAuthDialogShown = (state: RootState) => state.user.isReAuthDialogShown;

export const selectParsedAccessToken = () => {
  const user = JSON.parse(window.localStorage.getItem('user') || '{}');
  const existingToken = user?.accessToken;
  if (!existingToken) return null;
  return parseJwt(existingToken);
};

export default userSlice.reducer;
