/// <reference types="vite-plugin-svgr/client" />
import RulerIcon from 'assets/icons/Ruler.svg?react';
import { Tag, Tooltip } from 'antd';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Control } from 'ol/control';
import { useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsMeasurementToolActive, selectActiveTool, toggleTool } from 'redux/slices/viewerOptions';
import { store } from 'redux/store';
import './style.css';

export default function CustomMeasurementTool() {
  const dispatch = useDispatch();
  const { map } = useContext(OpenLayersMapContext);
  const activeTool = useSelector(selectActiveTool);

  let CustomMeasurementTool = useRef<Control | null>(null);

  const createCustomMeasurementTool = () => {
    const button = document.createElement('button');
    button.id = 'custom-measurement-button';

    const customMeasurementSpan = document.createElement('span');
    const root = createRoot(customMeasurementSpan);
    root.render(
      <Tooltip
        title={
          <span>
            Measurement Tool
            <Tag color="#fafafa" style={{ marginLeft: '8px', color: '#000000', border: '1px solid #d9d9d9' }}>
              m
            </Tag>
          </span>
        }
        color="#6300cc"
        placement="right"
      >
        <RulerIcon style={{ margin: '3px' }} />
      </Tooltip>,
    );
    button.appendChild(customMeasurementSpan);

    const customMeasurementToolContainerDiv = document.createElement('div');
    customMeasurementToolContainerDiv.id = 'custom-measurement-tool-id';
    customMeasurementToolContainerDiv.className = 'custom-measurement-tool ol-unselectable ol-control';

    customMeasurementToolContainerDiv.appendChild(button);

    button.addEventListener('click', () => {
      dispatch(toggleTool('measurement'))
    });
    return new Control({ element: customMeasurementToolContainerDiv });
  };

  useEffect(() => {
    if (activeTool === 'measurement') {
      const button = document.getElementById('custom-measurement-button');
      if (button) {
        button.id = 'custom-measurement-button-focus';
      }
    } else {
      const button = document.getElementById('custom-measurement-button-focus');
      if (button) {
        button.id = 'custom-measurement-button';
      }
    }
  }, [activeTool]);

  useEffect(() => {
    if (!map) return;

    CustomMeasurementTool.current = createCustomMeasurementTool();
    map.addControl(CustomMeasurementTool.current);

    return () => {
      if (CustomMeasurementTool.current) {
        map.removeControl(CustomMeasurementTool.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}
