import { CheckOutlined, CheckSquareTwoTone, CloseSquareTwoTone, MinusSquareTwoTone, StopOutlined } from "@ant-design/icons";
import { Button, Form, Radio, Tag, Tooltip } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { useDispatch, useSelector } from "react-redux";
import { appendToChangeset } from "redux/slices/annotationDetails";
import { selectIsAnnotatingEnabled } from "redux/slices/viewerOptions";
import { createAnnotationEvent } from "utils/Utils";

const QaCheckInput: React.FC = () => {
    const dispatch = useDispatch();
    const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

    const onChangeQuality = (e: RadioChangeEvent) => {
        const quality = e.target.value;
        dispatch(appendToChangeset(createAnnotationEvent({ type: 'QA_QUICK_CHECK', payload: { quality } })))
    };

    return (
        <Radio.Group id="quickcheck-status-radio" label="QA" style={{ color: '#ffffff' }} onChange={onChangeQuality} disabled={!isAnnotatingEnabled}>
            <Tooltip
                title={
                    <span>
                        Not Checked
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            1
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="not_checked" value="not_checked">
                    <MinusSquareTwoTone twoToneColor="#999999" />
                </Radio.Button>
            </Tooltip>
            <Tooltip
                title={
                    <span>
                        Excellent
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            2
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="excellent" value="excellent">🤩</Radio.Button>
            </Tooltip>
            <Tooltip
                title={
                    <span>
                        Good
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            3
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="good" value="good">🙂</Radio.Button>
            </Tooltip>
            <Tooltip
                title={
                    <span>
                        Bad
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            4
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="bad" value="bad">😕</Radio.Button>
            </Tooltip>
            <Tooltip
                title={
                    <span>
                        Very Bad
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            5
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="very_bad" value="very_bad">😖</Radio.Button>
            </Tooltip>
            <Tooltip
                title={
                    <span>
                        Exclude
                        <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                            6
                        </Tag>
                    </span>
                }
            >
                <Radio.Button key="exclude" value="exclude">
                    <StopOutlined />
                </Radio.Button>
            </Tooltip>
        </Radio.Group>
    )
}

export default QaCheckInput;
