import { Skeleton, Spin } from 'antd';
import { getThumbnailBlobPromise, thumbnailUrl } from 'service/api';
import { useEffect, useRef, useState } from 'react';
import './style.css';
import { ImageSchema } from 'redux/slices/imageServerApi';

interface WSIThumbnailProps {
  image: ImageSchema;
  height: number;
  onClick?: () => void;
  lazy?: boolean;
}

const WSIThumbnail : React.FC<WSIThumbnailProps> = ({ image, height, onClick, lazy = true }) => {
  const [thumbnailSize, setThumbnailSize] = useState<number[] | null>(null);
  const [thumbnailObjectURL, setThumbnailObjectURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isInViewport, setIsInViewport] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const containerRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (lazy && !isInViewport) return;
    const getThumbnail = async (image: ImageSchema) => {
      if (!image?.name) return;
      const thumbnailURI = thumbnailUrl(`${image.name}${image.file_type}`);
      try {
        const thumbnailBlobPromise = (await getThumbnailBlobPromise(thumbnailURI)) as Blob;

        const thumbnailBlob = URL.createObjectURL(thumbnailBlobPromise);
        const img = new Image();
        img.src = thumbnailBlob;
        img.onload = () => {
          const wsiWidth = img.width;
          const wsiHeight = img.height;
          setThumbnailSize([wsiWidth, wsiHeight]);
          setThumbnailObjectURL(thumbnailBlob);
        };
      } catch (err) {
        setShowSkeleton(true);
      }
      setIsLoading(false);
    };
    getThumbnail(image);
  }, [image, isInViewport, lazy]);

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      // IntersectionObserver Supported
      let config = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const onChange = (changes: Array<IntersectionObserverEntry>, observer: IntersectionObserver) => {
        changes.forEach(change => {
          if (change.intersectionRatio > 0) {
            // Stop watching and load the image
            setIsInViewport(true);
            observer.unobserve(change.target);
          }
        });
      };

      let observer = new IntersectionObserver(onChange, config);
      if (imageRef.current) {
        observer.observe(imageRef.current);
      }
    } else {
      // IntersectionObserver NOT Supported
      setIsInViewport(true);
    }
  }, []);

  const fitImageIntoContainer = (
    imageWidth: number,
    imageHeight: number,
    containerWidth: number,
    containerHeight: number,
  ) => {
    const imageRation = imageWidth / imageHeight;
    const containerRatio = containerWidth / containerHeight;
    const newDimensions = { width: 0, height: 0 };
    if (imageRation > containerRatio) {
      // Image is more landscape than container - fit to width
      newDimensions.width = containerWidth;
      newDimensions.height = imageHeight * (containerWidth / imageWidth) - 2;
    } else {
      // Image is more portrait than container - fit to height
      newDimensions.width = imageWidth * (containerHeight / imageHeight);
      newDimensions.height = containerHeight - 2;
    }
    return newDimensions;
  };

  const generateStyle = () => {
    const style = { width: 0, height: 0 };
    if (!thumbnailSize || !containerRef.current) return style;
    if (isLoading) return { display: 'none' };
    const [thumbnailWidth, thumbnailHeight] = thumbnailSize;
    const { width: containerWidth } = containerRef.current;
    const imageDimensions = fitImageIntoContainer(thumbnailWidth, thumbnailHeight, containerWidth, height);
    style['width'] = imageDimensions.width;
    style['height'] = imageDimensions.height;
    return style;
  };

  return (
    <div className="thumbnail-container" ref={containerRef} style={{ height }} onClick={onClick}>
      <Spin style={{ width: '100%' }} spinning={isLoading && isInViewport}>
        {!image || showSkeleton ? (
          <>
            <Skeleton.Image style={{ width: '100%' }} />
          </>
        ) : (
          <img src={thumbnailObjectURL} ref={imageRef} style={generateStyle()} alt="" />
        )}
      </Spin>
    </div>
  );
}

export default WSIThumbnail;