import { Divider, Layout, Tooltip, Typography } from 'antd';
import FooterImageInfo from 'components/FooterImageInfo';
import MouseCoordinates from 'components/MouseCoordinates';
import { LockFilled, WarningTwoTone } from '@ant-design/icons';
import SelectedDotAnnotationCount from 'components/SelectedDotAnnotationCount';
import { ClassificationSchema, ImageSchema, useGetScanQuery } from 'redux/slices/imageServerApi';
import { useGetImageInfoQuery } from 'redux/slices/api';
import { useJob } from 'redux/hooks';
import { isUserAnnotationManager } from 'utils/Utils';

const { Footer } = Layout;
const { Text } = Typography;

interface ViewerFooterProps {
  image: ImageSchema;
  classifications?: ClassificationSchema[];
}

const ViewerFooter: React.FC<ViewerFooterProps> = ({ image, classifications }) => {
  const { job } = useJob()
  const { data: imageInfo } = useGetImageInfoQuery(`${image.name}${image.file_type}`);
  const { data: scanInfo } = useGetScanQuery({ anonymizationName: image.name! }, { skip: !image.name || !isUserAnnotationManager() })

  return (
    <>
      <Footer
        style={{
          height: '32px',
          lineHeight: '32px',
          padding: '0px 16px',
          background: '#ffffff',
        }}
      >
        <div
          style={{
            height: '32px',
            verticalAlign: 'middle',
            width: '100%',
          }}
        >
          <FooterImageInfo scanInfo={scanInfo}/>
          <Divider type="vertical" />
          {!classifications || classifications.length === 0 && (
            <span>
              <Tooltip title="No classifications associated with this scenario">
                <WarningTwoTone />
              </Tooltip>
              <Divider type="vertical" />
            </span>
          )}
          {!imageInfo?.micronsPerPixel && (
            <span>
              <Tooltip
                title="Unable to detect microns per pixel of image -
              using default of 0.25. Scale may not be accurate."
              >
                <WarningTwoTone />
              </Tooltip>
              <Divider type="vertical" />
            </span>
          )}
          {job?.is_completed ? (
            <><LockFilled /><Text type="secondary"> This Annotation Job is completed and cannot be edited</Text></>
          ) : (
            <SelectedDotAnnotationCount />
          )}
          <MouseCoordinates />
        </div>
      </Footer>
    </>
  );
}

export default ViewerFooter;
