import { Badge, Button, Card, Checkbox, Col, Empty, Row, Segmented, Table, Tag, Tooltip, Typography } from 'antd';
import { ClassificationSchema, CommentAnnotationSchema, JobAnnotationsSchema } from 'redux/slices/imageServerApi';
import { useEffect, useState } from 'react';
import { SegmentedValue } from 'antd/es/segmented';
import { createAnnotationEvent, getCurrentUser, lightOrDark } from 'utils/Utils';
import { AnnotationEventSchema, AnnotationSchema } from 'types';
import { useDispatch } from 'react-redux';
import { resetAnnotationFilters, setAnnotationFilters, setJumpToLocation } from 'redux/slices/viewerOptions';
import { DateTime } from 'luxon';
import { CalendarOutlined, CheckCircleOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { appendToChangeset } from 'redux/slices/annotationDetails';

import "./style.css"
import { useComments } from 'redux/hooks';

const { Text } = Typography;

interface AnnotationTableProps {
    annotations: JobAnnotationsSchema;
    classifications: Array<ClassificationSchema>;
    task: string;
}

const AnnotationTable: React.FC<AnnotationTableProps> = ({ annotations, classifications, task }) => {
    const dispatch = useDispatch();
    const [selectedSegment, setSelectedSegment] = useState('rois');
    const { comments } = useComments();

    const roisColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'User',
            dataIndex: 'username',
            key: 'username',
        },
    ];

    const commentsColumns = [
        {
            title: 'Comment Snippet',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: 'User',
            dataIndex: 'username',
            key: 'username',
        },
    ];

    let columns;

    switch (selectedSegment) {
        case 'rois':
            columns = roisColumns;
            break;
        case 'comments':
            columns = commentsColumns;
            break;
        default:
            columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Classification',
                    dataIndex: 'class_id',
                    key: 'class_id',
                    filters: classifications.map(c => ({ text: c.name, value: c.id })),
                    onFilter: (value, record) => record.class_id === value,
                    render: (class_id: string) => {
                        const classification = classifications.find(c => c.id === class_id);
                        return (
                            <Tag
                                color={classification ? classification.color : '#000'}
                                style={{
                                    float: 'right',
                                    color: lightOrDark(classification ? classification.color : '#000') === 'light' ? '#000000' : undefined,
                                }}
                            >
                                {classification ? classification.name : 'No Classification'}
                            </Tag>
                        )
                    }
                },
                {
                    title: 'Reviewed',
                    dataIndex: 'is_reviewed',
                    key: 'is_reviewed',
                    filters: [
                        { text: 'Reviewed', value: true },
                        { text: 'Not Reviewed', value: false }
                    ],
                    onFilter: (value: boolean, record: AnnotationSchema) => record.is_reviewed === value,
                    render: (is_reviewed: boolean) => (
                        <div style={{ textAlign: 'center' }}>
                            <Checkbox checked={is_reviewed} disabled />
                        </div>
                    )
                },
            ];
    }

    function getSelectedAnnotations(key: string): AnnotationSchema[] {
        const annotationRecord = annotations[key as keyof typeof annotations];

        if (!annotationRecord) {
            throw new Error(`Invalid segment selected: ${key}`);
        }

        return Object.values(annotationRecord);
    }

    const handleResolveComment = (id: string) => {
        dispatch(appendToChangeset(createAnnotationEvent({
            type: 'RESOLVE_COMMENT', payload: {
                id,
                username: getCurrentUser().username
            }
        })))
    }

    const renderCommentSection = () => {
        const comments: CommentAnnotationSchema[] = getSelectedAnnotations('comments')
        if (!comments || comments.length === 0) return <Empty description="No comments" />;
        const commentCards = comments.map((comment, index) => (
            <Card id={comment.id} key={comment.id} className="card-style" onClick={() => dispatch(setJumpToLocation(comment.id))}>
                <Text type="secondary"><UserOutlined className="user-icon" />{comment.username}</Text>
                <br />
                <Text type="secondary">
                    <CalendarOutlined className="calendar-icon" />
                    {DateTime.fromISO(comment.timestamp).toLocaleString(DateTime.DATETIME_MED)}
                </Text>
                <div className="comment-content">{comment.content}</div>
                <div className="comment-resolve">{
                    comment.is_resolved ?
                        <Tooltip title="resolved"><Button disabled icon={<CheckCircleOutlined />}></Button></Tooltip> :
                        <Tooltip title="resolve comment"><Button type='primary' danger onClick={() => handleResolveComment(comment.id)} icon={<ExclamationCircleOutlined />}></Button></Tooltip>}
                </div>
                {comment.type && <Tag className="comment-type">{comment.type}</Tag>}
            </Card>
        ));
        return <div style={{ height: 'calc(100vh - 238px)', overflow: 'auto' }}>{commentCards}</div>
    }

    const renderAnnotationTable = () => {
        return (
            <Table
                virtual
                size="middle"
                key={selectedSegment}
                columns={columns}
                dataSource={getSelectedAnnotations(selectedSegment)}
                pagination={false}
                showSorterTooltip={false}
                onChange={(_, filters) => {
                    dispatch(setAnnotationFilters(filters));
                }}
                scroll={{
                    y: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 238,
                    x: 300,
                }}
                onRow={(record: AnnotationSchema) => {
                    return {
                        onClick: () => {
                            dispatch(setJumpToLocation(record.id));
                        },
                    }
                }}
            />
        )
    }

    useEffect(() => {
        // Reset filters in Redux store when selected annotation tab changes
        dispatch(resetAnnotationFilters());
    }, [selectedSegment, dispatch]);

    return (
        <>
            <Segmented
                block
                size='large'
                options={[
                    { label: 'ROIs', value: 'rois' },
                    { label: 'Cells', value: 'cells' },
                    { label: 'Polygons', value: 'polygons' },
                    {
                        label: <span>
                            <Badge
                                size="small"
                                offset={[-3, -1]}
                                count={comments.filter(c => !c.is_resolved).length !== 0 ? <ExclamationCircleOutlined style={{ color: "red" }} /> : 0}
                            />
                            Comments</span>,
                        value: 'comments'
                    },
                ]}
                onChange={(value: SegmentedValue) => setSelectedSegment(value.toString())}
            />
            {
                selectedSegment === 'comments' ? renderCommentSection() : renderAnnotationTable()
            }

        </>
    );

};

export default AnnotationTable;
