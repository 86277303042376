import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAnnotationsVisible,
  selectShowWSIRegion,
} from 'redux/slices/viewerOptions';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { selectWSIRegion, setWSIRegion } from 'redux/slices/annotationDetails';
import DrawRegular from 'ol-ext/interaction/DrawRegular';
import Transform, { ScaleEvent, TranslateEvent } from 'ol-ext/interaction/Transform';
import { always } from 'ol/events/condition';
import { DrawEvent } from 'ol/interaction/Draw';
import { Extent, getBottomRight, getTopLeft } from 'ol/extent';
import VectorSource from 'ol/source/Vector';

const convertPolygonExtentToWSIRegionCoordinates = (extent: Extent) => {
  const topLeft = getTopLeft(extent);
  const bottomRight = getBottomRight(extent);

  const x0 = Math.round(topLeft[0]);
  const y0 = Math.round(-topLeft[1]);
  const x1 = Math.round(bottomRight[0]);
  const y1 = Math.round(-bottomRight[1]);

  return [x0, y0, x1, y1];
};

interface WSIRegionInteractionProps {
  source: VectorSource;
};


export default function WSIRegionInteraction({ source }: WSIRegionInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();
  const showWSIRegion = useSelector(selectShowWSIRegion);
  const wsiRegion = useSelector(selectWSIRegion);

  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);

  const drawWSIRegion = useRef<DrawRegular | null>(null);
  const transformWSIRegion = useRef<Transform | null>(null);

  const __handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        if (drawWSIRegion.current) {
          drawWSIRegion.current?.reset();
        }
        break;
      case 'Backspace':
        if (drawWSIRegion.current) {
          drawWSIRegion.current?.reset();
        }
        break;
      case 'Delete':
        if (transformWSIRegion.current) {
          source.clear();
          dispatch(setWSIRegion(null));
        }
        break;
      default:
        break;
    }
  };

  const drawWSIRegionOnDrawStart = () => {
    if (source.getFeatures().length > 0) {
      drawWSIRegion.current?.reset();
    }

    if (!isAnnotationsVisible && drawWSIRegion.current) {
      drawWSIRegion.current?.reset();
    }
  };

  const drawWSIRegionOnDrawEnd = (event: DrawEvent) => {
    const coordinates = convertPolygonExtentToWSIRegionCoordinates(event.feature.getGeometry()!.getExtent());
    dispatch(setWSIRegion({ coordinates, unit: 'pixel' }));

    //Remove interaction so user can only draw one WSIRegion
    map.removeInteraction(drawWSIRegion.current);
  };

  const onScaleEnd = (event: ScaleEvent) => {
    const coordinates = convertPolygonExtentToWSIRegionCoordinates(event.feature.getGeometry()!.getExtent());
    dispatch(setWSIRegion({ coordinates, unit: 'pixel' }));
    //Remove new feature as existing 'wsi-region-feature' will be updated by redux change
    source.removeFeature(event.feature);
  };

  const onTranslateEnd = (event: TranslateEvent) => {
    const coordinates = convertPolygonExtentToWSIRegionCoordinates(event.feature.getGeometry()!.getExtent());
    dispatch(setWSIRegion({ coordinates, unit: 'pixel' }));
    //Remove new feature as existing 'wsi-region-feature' will be updated by redux change
    source.removeFeature(event.feature);
  };

  useEffect(() => {
    if (!map || !source) return;

    drawWSIRegion.current = new DrawRegular({
      source,
      condition: e => e.originalEvent.button === 0,
      sides: 4,
      canRotate: false,
    });

    drawWSIRegion.current.on('drawstart', drawWSIRegionOnDrawStart);
    drawWSIRegion.current.on('drawend', drawWSIRegionOnDrawEnd);

    if (!wsiRegion) {
      map.addInteraction(drawWSIRegion.current);
    }

    document.addEventListener('keydown', __handleKeyDown);

    transformWSIRegion.current = new Transform({
      enableRotatedTransform: false,
      hitTolerance: 2,
      translateFeature: false,
      scale: true,
      rotate: false,
      keepRectangle: true,
      translate: true,
      stretch: true,
      modifyCenter: always,
      pointRadius: function (f) {
        const radius = f.get('radius') || 10;
        return [radius, radius];
      },
    });
    if (wsiRegion) {
      map.addInteraction(transformWSIRegion.current);
    }

    transformWSIRegion.current.on('scaleend', onScaleEnd);
    transformWSIRegion.current.on('translateend', onTranslateEnd);

    return () => {
      if (map) {
        if (drawWSIRegion.current) {
          map.removeInteraction(drawWSIRegion.current);
          drawWSIRegion.current.un('drawstart', drawWSIRegionOnDrawStart);
          drawWSIRegion.current.un('drawend', drawWSIRegionOnDrawEnd);
        }
        document.removeEventListener('keydown', __handleKeyDown);
        if (transformWSIRegion.current) {
          map.removeInteraction(transformWSIRegion.current);
          transformWSIRegion.current.un('scaleend', onScaleEnd);
          transformWSIRegion.current.un('translateend', onTranslateEnd);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (!map || !drawWSIRegion.current || !transformWSIRegion.current) return;

    if (wsiRegion) {
      const transformInteractionExists = map
        .getInteractions()
        .getArray()
        .some((interaction: any) => interaction instanceof Transform);
      if (!transformInteractionExists) {
        map.addInteraction(transformWSIRegion.current);
      }
      map.removeInteraction(drawWSIRegion.current);
    } else {
      const drawInteractionExists = map
        .getInteractions()
        .getArray()
        .some((interaction: any) => interaction instanceof DrawRegular);
      if (!drawInteractionExists) {
        map.addInteraction(drawWSIRegion.current);
      }
      map.removeInteraction(transformWSIRegion.current);
    }
  }, [map, wsiRegion]);

  return null;
}
