import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import Router from 'router';
import * as Sentry from '@sentry/react';
import { version } from 'version';
import { ConfigProvider } from 'antd';
import ErrorBoundary from 'components/ErrorBoundary';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: `${window._env_.REACT_APP_SENTRY_DSN}`,
    environment: import.meta.env.VITE_SENTRY_ENV || 'local',
    // Safely ignoring ResizeObserver errors: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: version.version,
  });
}


const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#6300cc',
            fontFamily:
              "'Barlow', 'Times New Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n" +
              "    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          },
        }}
      >
        <Router />
      </ConfigProvider>
    </Provider>
  </ErrorBoundary>,
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
