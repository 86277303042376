import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Feature } from 'ol';
import { LineString, Polygon } from 'ol/geom';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Fill } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import polygonSplitter from 'polygon-splitter';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appendToChangeset } from 'redux/slices/annotationDetails';
import {
  selectIsAnnotatingEnabled,
} from 'redux/slices/viewerOptions';
import { createAnnotationEvent, generateAnnotationName, getRoundedPolygonPixels, isUserPathologist } from 'utils/Utils';
import { v4 as uuidv4 } from 'uuid';
import { Coordinate } from 'ol/coordinate';
import VectorSource, { VectorSourceEvent } from 'ol/source/Vector';
import { useAnnotations, useImage, usePolygons } from 'redux/hooks';

interface PolygonSplitInteractionProps {
  source: VectorSource;
};

export default function PolygonSplitInteraction({ source }: PolygonSplitInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const { image } = useImage();
  const { annotations } = useAnnotations();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

  const drawSplitLine = useRef<Draw | null>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        if (drawSplitLine.current) {
          drawSplitLine.current.abortDrawing();
        }
        break;
      case 'Backspace':
        if (drawSplitLine.current) {
          drawSplitLine.current.removeLastPoint();
        }
        break;
      default:
        break;
    }
  };

  const splitPolygonByLine = (lineFeature: Feature<LineString>, polygonFeature: Feature<Polygon>) => {
    if (!image || !annotations) return;

    const polygon = {
      type: 'Polygon',
      coordinates: polygonFeature.getGeometry()!.getCoordinates(),
    };
    const lineString = {
      type: 'LineString',
      coordinates: lineFeature.getGeometry()!.getCoordinates(),
    };
    const output = polygonSplitter(polygon, lineString);
    const outputPolygonCoordinates = output.geometry?.coordinates;

    if (output && output.geometry?.type === 'MultiPolygon' && outputPolygonCoordinates.length >= 2) {
      // Remove old Polygon Feature
      const polygonId = polygonFeature.getId() as string;
      dispatch(
        appendToChangeset(
          createAnnotationEvent({
            type: 'DELETE_POLYGON',
            payload: {
              id: polygonId,
            },
          })
        ),
      );

      source.removeFeature(polygonFeature);

      outputPolygonCoordinates.forEach((coordinates: Coordinate[][]) => {
        let splitPolygon = new Feature({
          geometry: new Polygon(coordinates),
        });

        let tempId = uuidv4();
        splitPolygon.setId(tempId);
        splitPolygon.set('classification', polygonFeature.get('classification'));
        splitPolygon.set('is_closed', true);
        splitPolygon.set('is_reviewed', isUserPathologist());
        splitPolygon.set('is_filtered', false);
        source.addFeatures([splitPolygon]);

        const splitPolygonCoordinates = (splitPolygon.getGeometry() as Polygon).getCoordinates();
        const splitPolygonClassId = polygonFeature.get('class_id');

        dispatch(
          appendToChangeset(
            createAnnotationEvent({
              type: 'ADD_POLYGON', payload: {
                id: tempId,
                name: generateAnnotationName(annotations.polygons, 'p'),
                class_id: splitPolygonClassId,
                coordinates: getRoundedPolygonPixels(splitPolygonCoordinates),
                is_reviewed: isUserPathologist()
              }
            })
          ),
        );
      });
    }
  };

  const handleDrawSplitLineRefEnd = (event: DrawEvent) => {
    event.feature.setId('split_line_feature');
    event.feature.set('removeMe', true);
    event.feature.set('classification', 'split_line');
    event.feature.set('is_closed', true);
    event.feature.set('is_reviewed', false);
    event.feature.set('is_filtered', false);

    source.forEachFeatureIntersectingExtent(
      (event.feature.getGeometry() as Polygon).getExtent(),
      (polygonFeature: Feature) => {
        splitPolygonByLine(event.feature as Feature<LineString>, polygonFeature as Feature<Polygon>);
      },
    );
  };

  useEffect(() => {
    if (!map || !source || !isAnnotatingEnabled) return;

    drawSplitLine.current = new Draw({
      source,
      type: 'LineString',
      condition: e => {
        return e.originalEvent.button === 0;
      },
      style: new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: '#6300cc',
          }),
          stroke: new Stroke({
            color: '#000000',
            width: 1,
          }),
        }),
        stroke: new Stroke({
          lineDash: [4, 4],
          color: '#6300cc',
          width: 3,
        }),
      }),
    });

    drawSplitLine.current.on('drawend', handleDrawSplitLineRefEnd);

    //This removes the 'Split' line on completion so feature is not added to source
    source.on('addfeature', (event: VectorSourceEvent) => {
      if (event.feature && event.feature.get('removeMe')) {
        source.removeFeature(event.feature);
      }
    });

    map.addInteraction(drawSplitLine.current);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      if (drawSplitLine.current) {
        map.removeInteraction(drawSplitLine.current);
      }

      document.removeEventListener('keydown', handleKeyDown);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingEnabled]);

  return null;
}
