import { Typography } from 'antd';
import CustomPolygonSplitToolOverlay from 'components/CustomControls/CustomPolygonSplitToolOverlay';
import {
  CellInteraction,
  ROIInteraction,
  Interactions,
  MeasurementToolInteraction,
  PolygonDrawInteraction,
  PolygonSelectModifyInteraction,
  PolygonAnnotationIssuesInteraction,
  PolygonSplitInteraction,
} from 'components/Interactions';
import {
  CellLayer,
  ROILayer,
  Layers,
  PolygonAnnotationIssuesLayer,
  PolygonAnnotationLayer,
  WholeSlideImageLayer,
} from 'components/Layers';
import OpenLayersMap from 'components/OpenLayersMap';
import CustomControls from 'components/CustomControls';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import TileLoadProgressBar from '../TileLoadProgressBar';
import CustomMeasurementTool from 'components/CustomControls/CustomMeasurementTool';
import { isUserPathologist } from 'utils/Utils';
import WSIRegionLayer from 'components/Layers/WSIRegionLayer';
import WSIRegionInteraction from 'components/Interactions/WSIRegionInteraction';
import CustomScaleZoomBar from 'components/CustomControls/CustomScaleZoomBar';
import CustomLockTool from 'components/CustomControls/CustomLockTool';
import CustomLockToolOverlay from 'components/CustomControls/CustomLockToolOverlay';
import OverviewLayer from 'components/Layers/OverviewLayer';
import { useGetClassificationScenarioQuery, useGetPipelineQuery, useGetTaskQuery, JobSchema, JobAnnotationsSchema, ImageSchema } from 'redux/slices/imageServerApi';
import { useDispatch, useSelector } from 'react-redux';
import { Tool, selectActiveTool, selectIsAnnotatingEnabled, selectIsAnnotationsVisible, setAvailableTools } from 'redux/slices/viewerOptions';
import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';
import CustomTool from 'components/CustomControls/CustomTool';
import { AimOutlined, CommentOutlined, ExpandOutlined, GatewayOutlined, ScissorOutlined, SelectOutlined } from '@ant-design/icons';
import CommentInteraction from 'components/Interactions/CommentInteraction';
import CommentLayer from 'components/Layers/CommentLayer';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { OpenLayersSources } from 'types';
import { useGetImageInfoQuery } from 'redux/slices/api';
import AnnotationTooltipInteraction from 'components/Interactions/AnnotationTooltipInteraction';

interface OpenLayersMapCompositionProps {
  image: ImageSchema
  job?: JobSchema,
  annotations?: JobAnnotationsSchema,
}

const OpenLayersMapComposition: React.FC<OpenLayersMapCompositionProps> = ({ image, job, annotations }) => {
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const activeTool = useSelector(selectActiveTool);

  // OpenLayers Sources
  const [cellSource] = useState(new VectorSource<Feature<Point>>({ features: [] }));
  const [roiSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));
  const [polyAnnotationSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));
  const [polygonAnnotationIssuesSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));
  const [tempPolygonSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));
  const [commentSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));
  const [measurementToolSource] = useState(new VectorSource<Feature<Point>>({ features: [] }));
  const [wsiRegionSource] = useState(new VectorSource<Feature<Polygon>>({ features: [] }));


  const { data: pipeline } = useGetPipelineQuery({ id: job?.pipeline }, { skip: !job });
  const { data: classificationScenario } = useGetClassificationScenarioQuery({ id: pipeline?.classification_scenario }, { skip: !pipeline });
  const { data: task } = useGetTaskQuery({ name: job?.task }, { skip: !job });
  const { data: imageInfo } = useGetImageInfoQuery(`${image.name}${image.file_type}`);

  const dispatch = useDispatch();

  const sources: OpenLayersSources = useMemo(() => {
    return {
      cellSource,
      roiSource,
      polyAnnotationSource,
      tempPolygonSource,
      wsiRegionSource,
      commentSource
    };
  }, [cellSource, roiSource, polyAnnotationSource, tempPolygonSource, wsiRegionSource, commentSource]);


  /**
 * Returns a list of tools for the current task. This might be on image-server in the future.
 * @returns the list of available tools.
 */
  const getAvailableTools = (): Tool[] => {
    if (!task) return ['wsi-region'];
    switch (task.name) {
      case "ANNOTATE_ROIS":
        return ['roi', 'comment'];
      case "ANNOTATE_ROI_CELLS":
        return ['cell', 'comment'];
      case "REVIEW_ROI_CELLS":
        return ['roi', 'cell', 'comment'];
      case "ANNOTATE_POLYGONS":
      case "DRAW_SEGMENTATION_ROIS":
      case "REVIEW_POLYGONS":
        return ["polygon", "polygon-select", "polygon-split", "comment"]
      default:
        return []
    }
  }

  // Set available tools.
  useEffect(() => {
    const availableTools = getAvailableTools();
    dispatch(setAvailableTools(availableTools));
  }, [task]);

  if (!image || !imageInfo) return null;

  const renderActiveToolInteraction = (activeTool: Tool | null) => {
    // Special case for WSI Region
    if (activeTool === 'wsi-region') return <WSIRegionInteraction source={wsiRegionSource} />;
    if (!job || !annotations) return null;
    switch (activeTool) {
      case "roi":
        return <ROIInteraction source={roiSource} />
      case "cell":
        return job.roi_id ? <CellInteraction source={cellSource} annotations={annotations} roiId={job.roi_id} imageInfo={imageInfo} /> : null
      case "measurement":
        return <MeasurementToolInteraction source={measurementToolSource} />
      case "polygon":
        return <PolygonDrawInteraction source={polyAnnotationSource} />
      case "polygon-select":
        return <PolygonSelectModifyInteraction source={polyAnnotationSource} />
      case "polygon-split":
        return <PolygonSplitInteraction source={polyAnnotationSource} />
      case "comment":
        return <CommentInteraction source={commentSource} />
      case null:
        return null
      default:
        throw new Error(`Active Tool ${activeTool} does not have an interactions component`)
    }
  }

  const renderLayers = () => {
    const layers = [];
    if (!task && image.wsi_region) {
      layers.push(<WSIRegionLayer key="wsi-region-layer" wsiRegion={image.wsi_region} source={wsiRegionSource} />);
    }
    if (annotations && classificationScenario && pipeline) {
      if (job?.roi_id) {
        layers.push(
          <CellLayer
            key="cell-layer"
            source={cellSource}
            cellAnnotations={Object.values(annotations.cells)}
            roiId={job.roi_id}
            classifications={classificationScenario.classifications}
            visible={isAnnotationsVisible}
          />
        );
      }
      layers.push(
        <ROILayer key="roi-layer" source={roiSource} roiAnnotations={Object.values(annotations.rois)} visible={isAnnotationsVisible} />,
        <PolygonAnnotationLayer key="polygon-layer" source={polyAnnotationSource} polygonAnnotations={Object.values(annotations.polygons)} classifications={classificationScenario.classifications} visible={isAnnotationsVisible} />,
        <CommentLayer key="comment-layer" commentAnnotations={Object.values(annotations.comments)} source={commentSource} />
      );
      if (isUserPathologist()) {
        layers.push(<PolygonAnnotationIssuesLayer key="polygon-issues-layer" source={polygonAnnotationIssuesSource} />);
      }
    }
    layers.push(
      <WholeSlideImageLayer key="whole-slide-image-layer" image={image} />,
      <OverviewLayer key="overview-layer" />
    );
    return layers;
  }

  /**
   * Custom Tool configurations - these are the props that are used in CustomTool component.
   */
  const TOOL_CONFIGS: Partial<Record<Tool, { name: Tool, icon?: ReactElement, iconText?: string, label: string }>> = {
    'comment': { name: 'comment', icon: <CommentOutlined />, label: 'Comment Tool' },
    'cell': { name: 'cell', icon: <AimOutlined />, label: 'Cell Tool' },
    'roi': { name: 'roi', iconText: "ROI", label: 'ROI Tool' },
    'polygon': { name: 'polygon', icon: <GatewayOutlined />, label: 'Polygon Tool' },
    'polygon-select': { name: 'polygon-select', icon: <SelectOutlined />, label: 'Polygon Select Tool' },
    'polygon-split': { name: 'polygon-split', icon: <ScissorOutlined />, label: 'Polygon Split Tool' },
    'wsi-region': { name: 'wsi-region', icon: <ExpandOutlined />, label: 'WSI Region Tool' }
  };

  return (
    // @ts-ignore - using to fix Property 'id' does not exist on type 'IntrinsicAttributes & SpinProps'.
    <OpenLayersMap sources={sources}>
      <Layers>
        {renderLayers()}
      </Layers>
      <Interactions>
        {renderActiveToolInteraction(activeTool)}
        <AnnotationTooltipInteraction />
        <PolygonAnnotationIssuesInteraction source={polygonAnnotationIssuesSource} />
      </Interactions>
      <CustomControls>
        <div>
          {getAvailableTools().map((toolName, index) => {
            const toolConfig = TOOL_CONFIGS[toolName];
            if (!toolConfig) return null;
            return (
              <CustomTool
                key={toolConfig.name}
                name={toolConfig.name}
                label={toolConfig.label}
                disabled={task && !isAnnotatingEnabled}
                icon={toolConfig.icon}
                iconText={toolConfig.iconText}
                offset={80 + index * 24}
              />
            );
          })}
        </div>
        <CustomScaleZoomBar />
        <CustomMeasurementTool />
        <CustomPolygonSplitToolOverlay />
        <CustomLockTool />
        <CustomLockToolOverlay />
      </CustomControls>
      <TileLoadProgressBar />
    </OpenLayersMap>
  );
}

export default OpenLayersMapComposition;