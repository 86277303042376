import { useContext, useEffect, useRef } from 'react';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Feature, MapBrowserEvent } from 'ol';
import { selectIsAnnotatingEnabled } from 'redux/slices/viewerOptions';
import { Layer } from 'ol/layer';
import { DRAW_ACTION_TYPE, OL_LAYER_NAME } from 'utils/Constants';
import { FeatureLike } from 'ol/Feature';
import { useSelector } from 'react-redux';
import { Geometry, Polygon } from 'ol/geom';
import { calculatePolygonArea } from 'utils/ol';
import { polygon } from '@turf/helpers';
import './style.css';
import { useGetClassificationsQuery } from 'redux/slices/imageServerApi';

export default function AnnotationTooltipInteraction() {
  const { map } = useContext(OpenLayersMapContext);
  const handlePointerMove = useRef<((event: MapBrowserEvent<PointerEvent>) => void) | null>(null);
  const highlightedPolygon = useRef<FeatureLike | undefined>(undefined);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const { data: classifications } = useGetClassificationsQuery();
  const offset = 10;

  const renderFeatureClass = (feature: FeatureLike) => {
    if (!classifications) return 'loading classes...';
    const featureClass = classifications.find(c => c.id === feature.get('class_id'));
    if (!featureClass) return feature.get('class_id') || 'no class defined';
    return featureClass.name;
  }

  const renderTooltipContent = (feature: FeatureLike) => {
    return `${renderFeatureClass(feature)}\n ${feature.get('last_editor')}`
  }

  useEffect(() => {
    if (!map) return;

    if (isAnnotatingEnabled) return;

    handlePointerMove.current = (event: MapBrowserEvent<PointerEvent>) => {
      const info = document.getElementById('annotation-info-tooltip');
      if (!info) return;
      if (!map) return;

      // To make sure that this works for nested features, we check which of the features is smallest and use that
      const availableFeatures = map.getFeaturesAtPixel(event.pixel, {
        layerFilter: (layer: Layer) => {
          return [OL_LAYER_NAME.ANNOTATION_POLYGON, OL_LAYER_NAME.ANNOTATIONS].includes(layer.get('name'));
        },
      });

      let smallestArea = 0;
      let selectedFeature = null;
      if (availableFeatures.length > 1) {
        availableFeatures.forEach((feat: Feature<Geometry>) => {
          const featureGeometry = feat.getGeometry() as Polygon;
          const featureCoordinates = featureGeometry.getCoordinates();

          const polygonArea = calculatePolygonArea(polygon(featureCoordinates));
          if (!smallestArea || polygonArea < smallestArea) {
            smallestArea = polygonArea;
            selectedFeature = feat;
          }
        });
      } else {
        selectedFeature = availableFeatures[0];
      }

      if (selectedFeature) {
        const { x, y } = event.originalEvent
        info.style.left = x + offset + 'px';
        info.style.top = y + offset + 'px';
        if (selectedFeature !== highlightedPolygon.current) {
          info.style.visibility = 'visible';
          info.innerText = renderTooltipContent(selectedFeature);
        }
      } else {
        info.style.visibility = 'hidden';
      }
      highlightedPolygon.current = selectedFeature;
    };

    map.on('pointermove', handlePointerMove.current);
    return function cleanup() {
      map.un('pointermove', handlePointerMove.current);
    };
  }, [map, isAnnotatingEnabled, classifications]);

  return <div id="annotation-info-tooltip"></div>;
}
