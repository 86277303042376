import { MapBrowserEvent } from 'ol';
import OverviewMap from 'ol/control/OverviewMap';
import IIIFInfo from 'ol/format/IIIFInfo';
import TileLayer from 'ol/layer/Tile';
import IIIF from 'ol/source/IIIF';
import View from 'ol/View';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import './style.css';
import { authenticatedTileLoader } from 'service/api';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import {
  selectIsAnnotatingEnabled,
  selectIsSidebarCollapsed,
  setIsAnnotatingEnabled,
} from 'redux/slices/viewerOptions';
import { calculateAspectRatioFit } from 'utils/geometry';
import { RootState } from 'redux/store';
import { ImageInfo } from 'redux/slices/api';

interface OverviewLayerProps {
  imageInfo: ImageInfo;
}

const OverviewLayer: React.FC<OverviewLayerProps> = ({ imageInfo }) => {
  const { map } = useContext(OpenLayersMapContext);
  const store = useStore<RootState>();
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
  const handleResize = useRef<any | undefined>(undefined);
  const overviewMapControl = useRef<OverviewMap | undefined>(undefined);
  const handleMouseEnter = useRef<any>(undefined);
  const handleMouseLeave = useRef<any>(undefined);
  const previousIsAnnotatingEnabledState = useRef<any>(undefined);

  useEffect(() => {
    if (!map || !imageInfo) return;

    const imageInfoUnfrozen = JSON.parse(JSON.stringify(imageInfo));
    const iiifInfo = new IIIFInfo(imageInfoUnfrozen).getTileSourceOptions();

    if (iiifInfo === undefined || iiifInfo.version === undefined) {
      throw new Error("error reading image info json - 'Data is not valid IIIF image information.'");
    }

    iiifInfo.zDirection = -1;

    const iiifTileSource = new IIIF(iiifInfo);
    iiifTileSource.setTileLoadFunction(authenticatedTileLoader);

    const extent = iiifTileSource.getTileGrid()!.getExtent();

    overviewMapControl.current = new OverviewMap({
      className: 'ol-overviewmap ol-custom-overviewmap',
      layers: [
        new TileLayer({
          source: iiifTileSource,
        }),
      ],
      view: new View({
        center: [0, 0],
        extent: extent,
        showFullExtent: true,
        smoothExtentConstraint: false,
      }),
      collapsed: false,
      label: '«',
      collapseLabel: '»',
      rotateWithView: true,
    });

    const handleClickEvent = (event: MapBrowserEvent<PointerEvent>) => {
      if (overviewMapControl.current) {
        overviewMapControl.current.getMap()!.getView().setCenter(event.coordinate);
      }
    };
    overviewMapControl.current.getOverviewMap().on('click', handleClickEvent);

    handleMouseEnter.current = () => {
      const state = store.getState();
      previousIsAnnotatingEnabledState.current = selectIsAnnotatingEnabled(state);
      dispatch(setIsAnnotatingEnabled(false));
    };

    handleMouseLeave.current = () => {
      dispatch(setIsAnnotatingEnabled(previousIsAnnotatingEnabledState.current));
    };

    handleResize.current = () => {
      setTimeout(() => {
        if (!overviewMapControl.current) return;

        // Resize Viewport to size within 300 x 300 taking into account WSI aspect ratio
        // and also the native screen resolution
        const maxSize = (window.innerWidth / 1920) * window.devicePixelRatio * 200;
        const rotation = ((map.getView().getRotation() * 180) / Math.PI) % 360;
        const flipAspect = (rotation >= 45 && rotation <= 135) || (rotation >= 225 && rotation <= 315);

        const calculatedAspectRatioFit = calculateAspectRatioFit(
          flipAspect ? imageInfo.height : imageInfo.width,
          flipAspect ? imageInfo.width : imageInfo.height,
          maxSize,
          maxSize,
        );

        overviewMapControl.current
          .getOverviewMap()
          .getView()
          .setViewportSize([calculatedAspectRatioFit.width, calculatedAspectRatioFit.height]);

        // Update the size of the overview map DOM element
        let overviewMapMap = document.getElementsByClassName('ol-overviewmap-map')[0] as HTMLElement;

        if (!overviewMapMap) return;

        overviewMapMap.style.width = `${calculatedAspectRatioFit.width}px`;
        overviewMapMap.style.height = `${calculatedAspectRatioFit.height}px`;
        overviewMapControl.current.getOverviewMap().getView().setResolution(1920);
        overviewMapControl.current.getOverviewMap().updateSize();

        // Set min and max zoom to initial zoom level so that overview zoom cannot be changed
        const currentZoom = overviewMapControl.current.getOverviewMap().getView().getZoom();
        if (currentZoom) {
          overviewMapControl.current.getOverviewMap().getView().setMinZoom(currentZoom);
          overviewMapControl.current.getOverviewMap().getView().setMaxZoom(currentZoom);
        }

        // Update the size back to square 300 x 300
        overviewMapMap.style.width = `${maxSize}px`;
        overviewMapMap.style.height = `${maxSize}px`;

        overviewMapControl.current.getOverviewMap().render();
      }, 1);
    };
    window.addEventListener('resize', handleResize.current);

    map.once('rendercomplete', () => {
      if (!overviewMapControl.current) return;
      // Add Overview after main map has completed rendering as otherwise Overview renders blank
      map.addControl(overviewMapControl.current);
      map.getView().on('change:rotation', () => {
        handleResize.current();
      });

      overviewMapControl.current
        .getOverviewMap()
        .getTargetElement()
        .addEventListener('mouseenter', handleMouseEnter.current);
      overviewMapControl.current
        .getOverviewMap()
        .getTargetElement()
        .addEventListener('mouseleave', handleMouseLeave.current);

      handleResize.current();
    });

    return function cleanup() {
      if (map) {
        if (!overviewMapControl.current) return;
        map.removeControl(overviewMapControl.current);
        window.removeEventListener('resize', handleResize.current);
        overviewMapControl.current.getOverviewMap().un('click', handleClickEvent);

        if (overviewMapControl.current.getOverviewMap().getTargetElement()) {
          overviewMapControl.current
            .getOverviewMap()
            .getTargetElement()
            .removeEventListener('mouseenter', handleMouseEnter.current);
          overviewMapControl.current
            .getOverviewMap()
            .getTargetElement()
            .removeEventListener('mouseleave', handleMouseLeave.current);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageInfo, map]);

  useEffect(() => {
    if (!map || !handleResize.current || !overviewMapControl.current) return;

    map.removeControl(overviewMapControl.current);

    map.addControl(overviewMapControl.current);

    overviewMapControl.current
      .getOverviewMap()
      .getTargetElement()
      .addEventListener('mouseenter', handleMouseEnter.current);
    overviewMapControl.current
      .getOverviewMap()
      .getTargetElement()
      .addEventListener('mouseleave', handleMouseLeave.current);

    handleResize.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarCollapsed]);

  return null;
}

export default OverviewLayer;
