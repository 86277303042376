import dotAnnotationSprites from 'assets/images/dot_annotation_sprites.png';

import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectChangesetWithPointer,
  selectSelectedAnnotationIds,
} from 'redux/slices/annotationDetails';
import { selectAnnotationFilters } from 'redux/slices/viewerOptions';
import { OL_LAYER_NAME } from 'utils/Constants';
import { checkIsFiltered } from 'utils/Utils';
import VectorSource from 'ol/source/Vector';

import { CellAnnotationSchema, ClassificationSchema } from 'redux/slices/imageServerApi';

interface CellLayerProps {
  source: VectorSource<Feature<Point>>;
  cellAnnotations: Array<CellAnnotationSchema>;
  roiId: string;
  classifications: Array<ClassificationSchema>;
  visible?: boolean;
};


const CellLayer: React.FC<CellLayerProps> = ({ source, cellAnnotations, roiId, classifications, visible = true }) => {
  const { map } = useContext(OpenLayersMapContext);

  const annotationFilters = useSelector(selectAnnotationFilters);
  const isLayerActive = map && classifications.length > 0;
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);
  const changeset = useSelector(selectChangesetWithPointer);

  const layer = useRef<WebGLPointsLayer<VectorSource<Feature<Point>>> | null>(null);

  // TODO: use toString in boolean feature properties bc. it does not work in shaders

  const webGlStyleJSON = (colorArray: Array<string>) => ({
    'icon-src': dotAnnotationSprites,
    'icon-offset': [
          'case',
          // Unselected and unreviewed
          ['all', ['!=', ['get', 'is_selected'], 'true'], ['!=', ['get', 'is_reviewed'], 'true']],
          [0, 0],
          // Unselected and reviewed
          ['all', ['!=', ['get', 'is_selected'], 'true'], ['==', ['get', 'is_reviewed'], 'true']],
          [32, 0],
          // Selected and unreviewed
          ['all', ['==', ['get', 'is_selected'], 'true'], ['!=', ['get', 'is_reviewed'], 'true']],
          [64, 0],
          // Selected and reviewed
          ['all', ['==', ['get', 'is_selected'], 'true'], ['==', ['get', 'is_reviewed'], 'true']],
          [96, 0],
          // Default.
          [0, 0],
        ],
    'icon-size': [32, 32],
    'icon-color': colorArray,
    'icon-width': 128,
    'icon-height': 32,
    'icon-scale': 0.7,
    'icon-rotate-with-view': false,
    'icon-opacity': [
      'case',
      ['all', ['==', ['get', 'is_visible'], 'true'], ['==', ['get', 'is_filtered'], 'true']],
      0.2,
      ['all', ['==', ['get', 'is_visible'], 'true'], ['!=', ['get', 'is_filtered'], 'true']],
      1,
      0,
    ],
    'icon-displacement': [0, 0],
  });

  const initOLLayers = () => {
    const colorsArray = [
      'case',
      ...classifications.reduce((acc: any, classification) => {
        return [...acc, ['==', ['get', 'class_id'], classification.id], classification.color];
      }, []),
      '#000000',
    ];

    return new WebGLPointsLayer({
      className: 'webgl-annotations-layer',
      properties: {
        name: OL_LAYER_NAME.ANNOTATIONS,
      },
      source,
      style: webGlStyleJSON(colorsArray),
      disableHitDetection: false,
    });
  };

  //This exposes Annotation Source for Cypress Tests
  window.cellSource = source;

  useEffect(() => {
    if (!isLayerActive || !map || !source || classifications.length === 0) return;
    source.clear();
    layer.current = initOLLayers();
    map.addLayer(layer.current);

    return () => {
      if (map) {
        if (layer.current) {
          layer.current.dispose();
          map.removeLayer(layer.current);
        }
        if (source) {
          source.clear();
        }
      }
    };
  }, [isLayerActive, map, source, classifications]);

  useEffect(() => {
    if (!map || !layer.current) return;
    layer.current.setVisible(visible);
  }, [map, visible]);

  useEffect(() => {
    if (!isLayerActive) return;

    let newFeatures: Feature<Point>[] = [];

    source.clear();

    cellAnnotations.forEach(cell => {
      if (source.getFeatureById(cell.id)) return;

      let feature = new Feature<Point>();

      feature.setGeometry(new Point([cell.coordinate[0], -cell.coordinate[1]]));
      feature.setId(cell.id);
      // feature.set('roi_id', cell.hotspot_id);
      feature.set('class_id', cell.class_id);
      feature.set('is_reviewed', cell.is_reviewed ? 'true' : 'false');
      feature.set('is_selected', selectedAnnotationIds.includes(cell.id) ? 'true' : 'false');
      feature.set('is_visible', 'true');
      feature.set('last_editor', cell.last_editor);
      feature.set('is_filtered', checkIsFiltered(cell, annotationFilters));
      newFeatures.push(feature);
    });


    source.addFeatures(newFeatures);
  }, [isLayerActive, cellAnnotations, selectedAnnotationIds, roiId, changeset, annotationFilters]);

  return null;
}

export default CellLayer;
