import React from 'react';
import ReactFlow, { Node, Edge, Background } from 'reactflow';
import dagre from 'dagre';
import { Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { JobTreeSchema, useGetJobTreeQuery } from 'redux/slices/imageServerApi';

import 'reactflow/dist/style.css';
import './style.css'

interface JobTreeModalProps {
    rootJobId: string | null;
    onCancel: () => void;
}

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'LR') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 172;
    const nodeHeight = 100;

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const renderNodeContent = (node: JobTreeSchema) => {
    return (
        <a href={`/jobs/${node.id}`}>
            <h4>{node.task_name}</h4>
            <p>{node.username}</p>
            <p>Branch: {node.branch_id}</p>
        </a>
    )
}

const transformJobTreeToNodesAndEdges = (jobTree?: JobTreeSchema) => {
    if (!jobTree) return { nodes: [], edges: [] };

    let nodes: Node[] = [];
    let edges: Edge[] = [];

    const traverseTree = (node: JobTreeSchema) => {
        // Create a node for the current job
        nodes.push({ id: node.id, data: { label: renderNodeContent(node) }, position: { x: 0, y: 0 } });

        // Create edges for the children of the current job
        node.next_jobs.forEach((child) => {
            edges.push({ id: `${node.id}-${child.id}`, source: node.id, target: child.id, animated: true, type: 'smoothstep' });
            traverseTree(child);
        });
    };

    traverseTree(jobTree);

    ({ nodes, edges } = getLayoutedElements(nodes, edges))

    return { nodes, edges };
};

const JobTreeModal: React.FC<JobTreeModalProps> = ({ rootJobId, onCancel }) => {
    const { data: jobTree, isFetching } = useGetJobTreeQuery({ id: rootJobId }, { skip: !rootJobId })
    const { nodes, edges } = transformJobTreeToNodesAndEdges(jobTree);
    const navigate = useNavigate();

    return (
        <Modal
            title="Annotation Process Flow"
            open={!!rootJobId}
            onCancel={onCancel}
            footer={null}
            width="80%"
        >
            <Spin spinning={isFetching}>
                <div style={{ width: '100%', height: '800px' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        fitView
                        maxZoom={1}
                        panOnDrag={true}
                    >
                        <Background variant="dots" gap={12} size={1} />
                    </ReactFlow>
                </div>
            </Spin>
        </Modal>
    );
};

export default JobTreeModal
