import { ReactNode } from 'react';
import { Layout } from 'antd';
import Header from 'components/Header';
import UserReAuthDialog from 'components/UserReAuthDialog';

import './style.css';

const { Sider } = Layout;

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <Layout style={{ height: '100vh', bottom: 0 }}>
      <Header />
      {children}
      <UserReAuthDialog />
    </Layout>
  );
}

export default AppLayout;
