import { Card, Col, Flex, Layout, Pagination, Row, Spin, Tag } from 'antd';
import { BgColorsOutlined, DeploymentUnitOutlined, ExclamationCircleOutlined, ExperimentOutlined, FileImageOutlined } from '@ant-design/icons';
import './style.css';
import WSIThumbnail from 'components/WSIThumbnail';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { GetImagesApiArg, ImageSchema, ScanSchema, useGetImagesQuery } from 'redux/slices/imageServerApi';
import { useFilters } from 'redux/hooks';
import { ImageScanFilters } from 'components/ImageScanFilters';

export default function ImageGrid() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useFilters<GetImagesApiArg>();

  const { data: images, error, isLoading, isFetching } = useGetImagesQuery({ page, ...filters });

  const loadImage = (image: ImageSchema) => {
    navigate(`/images/${image.id}`);
  };

  const renderScanInfoTags = (scan: ScanSchema | null) => {
    if (!scan) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          No Scan info available
        </Tag>
      )
    }
    return [
      <Tag icon={<ExperimentOutlined />}>
        {scan.lab.name}
      </Tag>,
      scan.organ && <Tag icon={<DeploymentUnitOutlined />}>
        {scan.organ.name}
      </Tag>,
      <Tag icon={<BgColorsOutlined />}>
        {scan.staining.name}
      </Tag>
    ]
  }

  const goToPage = (page: number) => {
    setPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Layout>
      <Spin tip="Loading Images..." spinning={isLoading || isFetching}>
        <Flex vertical={false}>
          <ImageScanFilters filters={filters} setFilters={setFilters} />
        </Flex>
        <div className="hotspot-image-grid">
          <Row gutter={[16, 24]} style={{ marginRight: 0 }}>
            {images && images.items.map((image) => {
              return (
                <Col className="gutter-row" style={{ width: '25%' }} key={image.id}>
                  <Card
                    id={image.id}
                    className="grid-item"
                    onClick={() => loadImage(image)}
                    size="small"
                    hoverable
                    cover={<WSIThumbnail height={200} image={image} />}
                  >
                    <Card.Meta
                      title={image.name}
                      description={
                        <>
                          {image.file_type && <Tag icon={<FileImageOutlined />}>
                            {image.file_type}
                          </Tag>}
                          {renderScanInfoTags(image.scan)}
                          {!image.wsi_region && <Tag icon={<ExclamationCircleOutlined />} color="orange">
                            WSI Region not set
                          </Tag>}
                        </>
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Pagination
            current={page}
            onChange={goToPage}
            total={images?.count ?? 0}
            pageSize={100}
            showSizeChanger={false}
            showTotal={(total: number) => `${total} images`}
          />
        </div>
      </Spin>
    </Layout>
  );
}
