import { Button, Tooltip, Typography } from 'antd';
import { ArrowLeftOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Title } = Typography;

/**
 * This replaces AntD's PageHeader which was removed in v5 of the library
 */
export default function CustomPageHeader({ title, isCollapsed, onCollapse, onBack }: CustomPageHeaderType) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '16px' }}>
      {isCollapsed ? null : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Go back to list of Case Images">
            <Button
              className="header-back-button"
              onClick={onBack}
              type="text"
              icon={<ArrowLeftOutlined />}
              style={{ marginRight: '4px' }}
            />
          </Tooltip>
          <Tooltip title={title}>
            <Title level={4} style={{ margin: 0 }}>
              {title}
            </Title>
          </Tooltip>
        </div>
      )}
      {isCollapsed ? (
        <Tooltip key="menu_fold_tooltip" title="Maximise Sidebar">
          <Button shape="circle" icon={<MenuUnfoldOutlined />} onClick={onCollapse} />
        </Tooltip>
      ) : (
        <Tooltip key="menu_fold_tooltip" title="Minimise Sidebar">
          <Button shape="circle" icon={<MenuFoldOutlined />} onClick={onCollapse} />
        </Tooltip>
      )}
    </div>
  );
}
