import Collection from 'ol/Collection';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import DragBox from 'ol/interaction/DragBox';
import Style from 'ol/style/Style';
import { singleClick, platformModifierKeyOnly } from 'ol/events/condition';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';

import {
  OL_LAYER_NAME,
  CHANGE_ACTION,
  DEFAULT_MICRONS_PER_PIXEL,
  DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS,
} from 'utils/Constants';
import { generateAnnotationName, isUserPathologist, getRoundedSinglePixel, createAnnotationEvent } from 'utils/Utils';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { v4 as uuidv4 } from 'uuid';
import {
  setActiveAnnotationClassification,
  setActiveReviewAnnotation,
  setScrollToAnnotationId,
  selectActiveReviewAnnotation,
  selectAnnotationFilters,
  selectActiveAnnotationClassification,
  selectIsAnnotatingEnabled,
  selectIsMeasurementToolActive,
  toggleTool,
} from 'redux/slices/viewerOptions';
import {
  appendToChangeset,
  extendChangeset,
  selectSelectedAnnotationIds,
  setSelectedAnnotationIds,
  removeFromSelectedAnnotationIds,
  appendToSelectedAnnotationIds,
} from 'redux/slices/annotationDetails';
import { Feature, MapBrowserEvent } from 'ol';
import { Point } from 'ol/geom';
import { Layer } from 'ol/layer';
import { getWidth } from 'ol/extent';
import { isPointInPolygon } from 'utils/geometry';
import VectorSource from 'ol/source/Vector';
import { AddCellEventSchema, JobAnnotationsSchema } from 'redux/slices/imageServerApi';
import { AnnotationEventSchema } from 'types';
import { ImageInfo } from 'redux/slices/api';

interface CellInteractionProps {
  source: VectorSource<Feature<Point>>;
  annotations: JobAnnotationsSchema;
  roiId: string;
  imageInfo: ImageInfo;
};


const CellInteraction: React.FC<CellInteractionProps> = ({ source, annotations, roiId, imageInfo }) => {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);
  const annotationFilters = useSelector(selectAnnotationFilters);
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const activeReviewAnnotation = useSelector(selectActiveReviewAnnotation);
  const isMeasurementToolActive = useSelector(selectIsMeasurementToolActive);
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);

  const drawDotAnnotationRef = useRef<Draw | null>(null);
  const modifyDotAnnotationRef = useRef<Modify | null>(null);
  const modifyStartCoordinates = useRef<Array<number> | null>(null);
  const selectDotAnnotationRef = useRef<Select | null>(null);
  const multiSelectDotAnnotationRef = useRef<DragBox | null>(null);
  const abortDotAnnotationRef = useRef(false);
  const handlePointerMove = useRef<((event: MapBrowserEvent<PointerEvent>) => void) | null>(null);

  const deselectDotAnnotations = () => {
    dispatch(setSelectedAnnotationIds([]));
  };

  const checkForDoubleDots = (featureToPlace: Feature) => {
    const geometry = featureToPlace.getGeometry() as Point;
    let coordinates = geometry.getCoordinates();
    const pixelRadius =
      DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS /
      (imageInfo.micronsPerPixel || DEFAULT_MICRONS_PER_PIXEL);
    const extent = [
      coordinates[0] - pixelRadius,
      coordinates[1] - pixelRadius,
      coordinates[0] + pixelRadius,
      coordinates[1] + pixelRadius,
    ];
    const featuresInExtent = source.getFeaturesInExtent(extent);
    for (let i = 0; i < featuresInExtent.length; i += 1) {
      if (featuresInExtent[i].getId() !== featureToPlace.getId()) return true;
    }
    return false;
  };

  const handleBeforeAddDotAnnotation = (event: DrawEvent) => {
    if (!activeAnnotationClassification) {
      notification['error']({
        message: 'No Classification Selected',
        description: 'Please select a classification before you try to create an Annotation',
        duration: 5,
      });
    }

    // If currentHotspot exists, check that Dot being added is inside it
    if (checkForDoubleDots(event.feature)) {
      // TODO: just throw the error and leave handling to the parent application
      notification['error']({
        message: 'You cannot place Dot Annotations this close together.',
        description: 'The Dot Annotation has not been placed.',
        duration: 5,
      });
      abortDotAnnotationRef.current = true;
    }

    // Check if point is within a polygon
    const roi = annotations.rois[roiId];
    const featureGeometry = event.feature.getGeometry() as Point;
    const featureCoordinates = featureGeometry.getCoordinates();
    if (!isPointInPolygon(featureCoordinates[0], -featureCoordinates[1], roi.coordinates)) {
      notification['error']({
        message: 'You cannot place Dot Annotations outside of the Hotspot.',
        description: 'The Dot Annotation has not been placed.',
        duration: 5,
      });
      abortDotAnnotationRef.current = true;
    }


    if ((!selectDotAnnotationRef.current || selectedAnnotationIds.length === 0) && activeAnnotationClassification)
      return;
    deselectDotAnnotations();
    abortDotAnnotationRef.current = true;
  };

  const handleAfterAddDotAnnotation = (drawEvent: DrawEvent) => {
    //Abort DotAnnotation here as aborting in handleBeforeAddDotAnnotation causes getGeometry == null error
    if (drawDotAnnotationRef.current && abortDotAnnotationRef.current) {
      abortDotAnnotationRef.current = false;
      return;
    }
    if (!activeAnnotationClassification) {
      notification['error']({
        message: 'You must have a Classification selected',
        description: `Please select a Classification before adding more annotations`,
        duration: 5,
      });
      if (drawDotAnnotationRef.current) {
        drawDotAnnotationRef.current.abortDrawing();
      }
      return;
    }

    const geometry = drawEvent.feature.getGeometry() as Point;
    let coordinate = geometry.getCoordinates();
    const coordinates = getRoundedSinglePixel(coordinate);

    const annotationEvent: AddCellEventSchema = {
      id: uuidv4(),
      timestamp: new Date().toISOString(),
      type: 'ADD_CELL',
      payload: {
        id: uuidv4(),
        name: generateAnnotationName(annotations.cells, 'c'),
        coordinate: [coordinates.x, -coordinates.y],
        class_id: activeAnnotationClassification,
        is_reviewed: isUserPathologist(),
      }
    }

    dispatch(appendToChangeset(annotationEvent));
  };

  const handleSelectDotAnnotation = (event: SelectEvent) => {
    dispatch(setScrollToAnnotationId(''));

    const isCtrlPressed = event.mapBrowserEvent.originalEvent.ctrlKey;

    if (!isCtrlPressed) {
      deselectDotAnnotations();
    }

    const selectedFeature = event.target.getFeatures().getArray()[0];

    if (!selectedFeature) return;

    let selectedIds: Array<string> = [];

    if (selectedFeature.get('is_filtered') === 'true') return;

    const selectedFeatureID = selectedFeature.getId()?.toString();
    if (!selectedFeatureID) throw new Error('Selected Feature does not have an ID');
    selectedIds.push(selectedFeatureID);

    if (isCtrlPressed) {
      if (selectedAnnotationIds.includes(selectedFeatureID)) {
        dispatch(removeFromSelectedAnnotationIds(selectedFeatureID));
      } else {
        dispatch(appendToSelectedAnnotationIds(selectedIds));
      }
    } else {
      const classId = selectedFeature.get('class_id');
      dispatch(setActiveAnnotationClassification(classId));
      dispatch(setSelectedAnnotationIds(selectedIds));
    }

    dispatch(setScrollToAnnotationId(selectedFeatureID));
  };

  const handleMultiSelectDotAnnotationStart = () => {
    // Temporarily remove pointermove event as causes slow-down when drawing Dragbox
    if (map && handlePointerMove.current) {
      map.un('pointermove', handlePointerMove.current);
    }
  };

  const handleMultiSelectDotAnnotationEnd = () => {
    if (!map || !multiSelectDotAnnotationRef.current) return;
    const dragBox = multiSelectDotAnnotationRef.current.getGeometry();
    let annotationClassifications: Array<string> = [];
    let selectedFeatures: Collection<Feature> = new Collection<Feature>();
    let selectedIds: Array<string> = [];

    const featuresInExtent = source.getFeaturesInExtent(dragBox.getExtent());

    const mapExtent = map.getView().getProjection().getExtent();
    const mapWidth = getWidth(mapExtent);

    // If the view is obliquely rotated the box extent will exceed its geometry so both the box and the candidate
    // feature geometries are rotated around a common anchor to confirm that, with the box geometry aligned with its
    // extent, the geometries intersect.
    // If the view is not obliquely rotated the box geometry and its extent are equivalent so intersecting features can
    // be added directly to the collection.
    const rotation = map.getView().getRotation();
    const oblique = rotation % (Math.PI / 2) !== 0;
    if (oblique) {
      const anchor = [0, 0];
      const geometry = dragBox.clone();
      geometry.translate(-0 * mapWidth, 0);
      geometry.rotate(-rotation, anchor);
      const extent = geometry.getExtent();
      featuresInExtent.forEach((feature: Feature) => {
        const geometry = feature!.getGeometry()!.clone();
        geometry.rotate(-rotation, anchor);
        if (geometry.intersectsExtent(extent)) {
          selectedFeatures.push(feature);
        }
      });
    } else {
      selectedFeatures.extend(featuresInExtent);
    }

    selectedFeatures.forEach((feature: Feature) => {
      if (feature.get('is_filtered') === 'true') return;
      selectedIds.push(feature.getId() as string);
      const dotClassification = feature.get('classification');
      if (annotationClassifications.indexOf(dotClassification) === -1) {
        annotationClassifications.push(dotClassification);
      }
    });

    dispatch(setActiveReviewAnnotation(''));
    dispatch(setActiveAnnotationClassification(null));
    dispatch(setScrollToAnnotationId(''));
    dispatch(appendToSelectedAnnotationIds(selectedIds));

    // Re-add pointer move event
    if (map && handlePointerMove.current) {
      map.on('pointermove', handlePointerMove.current);
    }
  };

  const deleteDotAnnotations = () => {
    if (selectedAnnotationIds.length === 0) return;
    let annotationEvents: Array<AnnotationEventSchema> = [];
    selectedAnnotationIds.forEach(selectedDotAnnotationId => {
      annotationEvents.push(createAnnotationEvent({ type: 'DELETE_CELL', payload: { id: selectedDotAnnotationId } }));
    });

    dispatch(extendChangeset(annotationEvents));
    dispatch(setSelectedAnnotationIds([]));

    notification['info']({
      message: `Removed ${selectedAnnotationIds.length} Dot Annotation${selectedAnnotationIds.length === 1 ? '' : 's'}`,
    });
  };

  const handleClickDotAnnotation = (event: SelectEvent) => {
    if (event.mapBrowserEvent.type === 'singleclick') handleSelectDotAnnotation(event);
  };

  const handleDoubleClickDotAnnotation = (event: MapBrowserEvent<PointerEvent>) => {
    if (!map) return;
    map
      .getFeaturesAtPixel(event.pixel, {
        layerFilter: (layer: Layer) => {
          return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
        },
      })
      .forEach((feature: Feature) => {
        let changesetAddition: Array<AnnotationEventSchema> = [];
        if (feature.get('is_filtered') === 'true') return;
        changesetAddition.push(createAnnotationEvent({
          type: 'DELETE_CELL',
          payload: {
            id: feature.getId()
          }
        }));

        dispatch(extendChangeset(changesetAddition));
        dispatch(setSelectedAnnotationIds([]));
        notification['info']({
          message: 'Removed 1 Dot Annotation',
        });
      });
  };

  const handleModifyDotAnnotationStart = (event: ModifyEvent) => {
    const feature = event.features.getArray()[0] as Feature<Point>;
    if (feature && modifyDotAnnotationRef.current) {
      modifyStartCoordinates.current = feature.getGeometry()!.getCoordinates();
    }
  };

  const handleModifyDotAnnotationEnd = (event: ModifyEvent) => {
    const feature = event.features.getArray()[0] as Feature<Point>;
    if (!feature || !modifyDotAnnotationRef.current) return;

    const coordinates = feature.getGeometry()!.getCoordinates();
    const roundedCoordinates = getRoundedSinglePixel(coordinates);

    dispatch(
      appendToChangeset(
        createAnnotationEvent({
          type: 'UPDATE_CELL',
          payload: {
            id: feature.getId(),
            coordinate: [roundedCoordinates.x, -roundedCoordinates.y]
          }
        })
      ),
    );
    dispatch(setSelectedAnnotationIds([]));
  };

  const changeSelectedDotAnnotationClassification = () => {
    let changesetAddition: Array<AnnotationEventSchema> = [];
    selectedAnnotationIds.forEach(selectedCellId => {
      const cellFeature = source.getFeatureById(selectedCellId);
      if (!cellFeature || cellFeature.get('class_id') === activeAnnotationClassification) return;
      const updateCellEvent = createAnnotationEvent({ type: 'UPDATE_CELL', payload: { id: selectedCellId, class_id: activeAnnotationClassification, } })
      changesetAddition.push(updateCellEvent);
    });
    dispatch(extendChangeset(changesetAddition));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Delete':
      case 'Backspace':
        deleteDotAnnotations();
        break;
      case 'm':
        if (drawDotAnnotationRef.current) {
          drawDotAnnotationRef.current.abortDrawing();
        }
        dispatch(toggleTool('measurement'));
        break;
      default:
        break;
    }
  };

  // Needed for Cypress tests
  if (window.Cypress) {
    window.selectedCellIds = selectedAnnotationIds;
  }

  useEffect(() => {
    if (
      !selectDotAnnotationRef.current ||
      !activeAnnotationClassification ||
      !isAnnotatingEnabled ||
      !map
    )
      return;
    changeSelectedDotAnnotationClassification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnnotationClassification]);

  useEffect(() => {
    if (
      !map ||
      Object.keys(annotations.cells).length === 0 ||
      !isAnnotatingEnabled ||
      isMeasurementToolActive
    )
      return;

    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => selectedAnnotationIds.includes(a.getId() as string)),
    );

    modifyDotAnnotationRef.current = new Modify({
      features: selectedFeatures,
      style: new Style({}),
      pixelTolerance: 10,
    });

    selectDotAnnotationRef.current = new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
      },
      condition: e => singleClick(e),
      style: null,
      hitTolerance: 10,
      features: selectedFeatures,
    });

    multiSelectDotAnnotationRef.current = new DragBox({
      condition: event => platformModifierKeyOnly(event),
    });

    handlePointerMove.current = (event: MapBrowserEvent<PointerEvent>) => {
      if (!map) return;
      const pixel = map.getEventPixel(event.originalEvent);
      const featureAtPixel = map.getFeaturesAtPixel(pixel, {
        layerFilter: (layer: Layer) => {
          return layer.get('name') === OL_LAYER_NAME.ANNOTATIONS;
        },
      }) as Array<Feature>;
      const cursorStyle = (feature: Feature) => {
        if (!feature) return 'crosshair';
        return 'auto';
      };
      map.getViewport().style.cursor = cursorStyle(featureAtPixel[0]);
    };

    map.addInteraction(modifyDotAnnotationRef.current);
    map.addInteraction(selectDotAnnotationRef.current);
    map.addInteraction(multiSelectDotAnnotationRef.current);
    map.on('dblclick', handleDoubleClickDotAnnotation);

    modifyDotAnnotationRef.current.on('modifystart', handleModifyDotAnnotationStart);
    modifyDotAnnotationRef.current.on('modifyend', handleModifyDotAnnotationEnd);
    selectDotAnnotationRef.current.on('select', handleClickDotAnnotation);
    multiSelectDotAnnotationRef.current.on('boxstart' as any, handleMultiSelectDotAnnotationStart);
    multiSelectDotAnnotationRef.current.on('boxend' as any, handleMultiSelectDotAnnotationEnd);
    map.on('pointermove', handlePointerMove.current);

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      if (handlePointerMove.current) {
        map.un('pointermove', handlePointerMove.current);
      }

      if (selectDotAnnotationRef.current) {
        selectDotAnnotationRef.current.un('select', handleClickDotAnnotation);
        map.removeInteraction(selectDotAnnotationRef.current);
      }

      if (modifyDotAnnotationRef.current) {
        modifyDotAnnotationRef.current.un('modifyend', handleModifyDotAnnotationEnd);
        modifyDotAnnotationRef.current.un('modifystart', handleModifyDotAnnotationStart);
        map.removeInteraction(modifyDotAnnotationRef.current);
      }

      if (multiSelectDotAnnotationRef.current) {
        multiSelectDotAnnotationRef.current.un('boxstart' as any, handleMultiSelectDotAnnotationStart);
        multiSelectDotAnnotationRef.current.un('boxend' as any, handleMultiSelectDotAnnotationEnd);
        map.removeInteraction(multiSelectDotAnnotationRef.current);
      }

      map.un('dblclick', handleDoubleClickDotAnnotation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    annotations,
    isAnnotatingEnabled,
    activeReviewAnnotation,
    annotationFilters,
    isMeasurementToolActive,
    selectedAnnotationIds,
  ]);

  useEffect(() => {
    if (
      !map ||
      !isAnnotatingEnabled ||
      isMeasurementToolActive
    )
      return;

    drawDotAnnotationRef.current = new Draw({
      source,
      type: 'Point',
      style: new Style({}),
      stopClick: true,
      condition: e => {
        const dotAnnotationsAtPixel = map.getFeaturesAtPixel(e.pixel, {
          hitTolerance: 10,
          layerFilter: (layer: Layer) => layer.get('name') === OL_LAYER_NAME.ANNOTATIONS,
        });
        return e.originalEvent.button === 0 && dotAnnotationsAtPixel.length === 0 && selectedAnnotationIds.length === 0;
      },
    });

    map.addInteraction(drawDotAnnotationRef.current);

    drawDotAnnotationRef.current.on('drawstart', handleBeforeAddDotAnnotation);
    drawDotAnnotationRef.current.on('drawend', handleAfterAddDotAnnotation);

    return () => {
      if (drawDotAnnotationRef.current) {
        map.removeInteraction(drawDotAnnotationRef.current);
        drawDotAnnotationRef.current.un('drawstart', handleBeforeAddDotAnnotation);
        drawDotAnnotationRef.current.un('drawend', handleAfterAddDotAnnotation);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnnotatingEnabled,
    activeAnnotationClassification,
    annotations,
    isMeasurementToolActive,
    selectedAnnotationIds,
  ]);

  return null;
}

export default CellInteraction;
