import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'ol/ol.css';
import View from 'ol/View';
import * as Coordinate from 'ol/coordinate';
import {
  defaults as defaultInteractions,
  DragPan,
  DragRotate,
  DragZoom,
  KeyboardPan,
  KeyboardZoom,
  MouseWheelZoom,
  PinchRotate,
  PinchZoom,
  DragRotateAndZoom,
} from 'ol/interaction';
import { easeOut } from 'ol/easing'
import { ScaleLine } from 'ol/control';
import './style.css';
import {
  selectIsSidebarCollapsed,
  selectIsViewerLocked,
  selectJumpToCoordinate,
  selectJumpToLocation,
  setIsTileLoadProgressBarActive,
  setJumpToLocation,
  setMouseCoordinates,
} from 'redux/slices/viewerOptions';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { selectChangeset } from 'redux/slices/annotationDetails';
import { MapBrowserEvent, Map } from 'ol';
import { store } from 'redux/store';
import { notification } from 'antd';
import CustomRotationArrow from 'components/CustomControls/CustomRotationArrow';
import { OpenLayersSources } from 'types';
import { Extent, extend as extendExtent } from 'ol/extent';

interface OpenLayersMapProps {
  children: ReactNode;
  sources: OpenLayersSources;
}

const OpenLayersMap: React.FC<OpenLayersMapProps> = ({ children, sources }) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const [map, setMap] = useState<Map | null>(null);
  const jumpToLocation = useSelector(selectJumpToLocation);
  const jumpToCoordinate = useSelector(selectJumpToCoordinate);
  const changeset = useSelector(selectChangeset);
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
  const isViewerLocked = useSelector(selectIsViewerLocked);

  const showDialogWhenUserLeavesPageIfChangesetNotEmpty = (event: BeforeUnloadEvent) => {
    if (changeset.length > 0) {
      // Cancel the event
      event.preventDefault(); // Prompt will always be shown in Mozilla Firefox if behavior behaviour prevented
      // returnValue to be set must be set for Chrome
      event.returnValue = '';
    }
  };

  const handlePointerMove = (event: MapBrowserEvent<PointerEvent>) => {
    let coordinates = [event.coordinate[0], event.coordinate[1] * -1];
    dispatch(setMouseCoordinates(Coordinate.format(coordinates, 'x: {x}, y: {y}', 0)));
  };

  const initOLMap = () => {
    const annotationView = new View({
      center: [0, 0],
      zoom: 2,
    });

    const scaleControl = new ScaleLine({
      units: 'metric',
    });

    const interactions = defaultInteractions({
      doubleClickZoom: false,
    }).extend([new DragRotateAndZoom()]);

    return new Map({
      interactions,
      layers: [],
      target: 'map',
      view: annotationView,
      controls: [scaleControl],
    });
  };

  const getFeatureById = (featureId: string) => {
    const sourcesList = Object.values(sources);
    for (let i = 0; i < sourcesList.length; i += 1) {
      const feature = sourcesList[i].getFeatureById(featureId);
      if (feature) return feature;
    }
  };

  useEffect(() => {
    let olMap = initOLMap();
    window.olMap = olMap;
    if (mapRef.current) {
      olMap.setTarget(mapRef.current);
    }
    olMap.on('pointermove', handlePointerMove);
    olMap.getViewport().addEventListener('contextmenu', e => {
      e.preventDefault();
    });

    setMap(olMap);

    olMap.addControl(CustomRotationArrow({ map: olMap }));

    olMap.on('loadstart', function () {
      dispatch(setIsTileLoadProgressBarActive(true));
    });
    olMap.on('loadend', function () {
      dispatch(setIsTileLoadProgressBarActive(false));
    });

    const __handleKeyDown = (event: KeyboardEvent) => {
      const props = store.getState();
      const { isHotspotDetailModalVisible, mouseCoordinates } = props.viewerOptions;

      if (isHotspotDetailModalVisible) return;

      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        const cursorCoordinateCopyText = '{' + mouseCoordinates + '}';
        navigator.clipboard.writeText(cursorCoordinateCopyText).then(
          function () {
            notification['info']({
              message: 'Cursor coordinates copied to clipboard',
              description: cursorCoordinateCopyText,
            });
          },
          function (err) {
            notification['info']({
              message: 'Error copying cursor coordinates to clipboard',
              description: err,
            });
          },
        );
        return;
      }
    };

    olMap.getViewport().addEventListener(
      'mouseenter',
      function () {
        document.addEventListener('keydown', __handleKeyDown);
      },
      false,
    );

    olMap.getViewport().addEventListener(
      'mouseout',
      function () {
        document.removeEventListener('keydown', __handleKeyDown);
      },
      false,
    );

    return function cleanup() {
      olMap.setTarget(undefined);
      olMap.un('pointermove', handlePointerMove);
      olMap.getViewport().removeEventListener('contextmenu', e => {
        e.preventDefault();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', showDialogWhenUserLeavesPageIfChangesetNotEmpty);
    return () => {
      window.removeEventListener('beforeunload', showDialogWhenUserLeavesPageIfChangesetNotEmpty);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeset]);

  /**
   * Fit the map to the combined extent of all annotation sources. This relies on
   * a somewhat nasty workaround that uses timeout to perform the fitting after
   * 0.5 seconds. It would be better to do the fitting once the OL sources are ready.
   */
  useEffect(() => {
    if (!map || !sources) return;

    const fitMapWithDelay = () => {
      let combinedExtent: Extent | null = null;

      Object.values(sources).forEach((source) => {
        let sourceExtent: Extent = source.getExtent();
        if (combinedExtent === null) {
          combinedExtent = sourceExtent;
        } else {
          combinedExtent = extendExtent(combinedExtent, sourceExtent);
        }
      });

      if (!combinedExtent || combinedExtent.some(c => !isFinite(c))) return;
      console.log('Fitting map after delay');
      map.getView().fit(combinedExtent, {
        padding: [10, 10, 10, 10],
        duration: 1000, // duration of 1 second for smooth transition
        easing: easeOut,
      });
    };

    const delay = 500; // 0.5 seconds delay
    const timeoutId = setTimeout(fitMapWithDelay, delay);

    // Clear timeout if the component unmounts or dependencies change
    return () => clearTimeout(timeoutId);
  }, [map, sources]);

  useEffect(() => {
    if (!map || !jumpToLocation) return;
    const feature = getFeatureById(jumpToLocation);
    if (!feature) {
      dispatch(setJumpToLocation(null));
      return;
    }
    const geometry = feature.getGeometry();
    if (!geometry) return;
    let extent = geometry.getExtent();
    if (geometry.getType() === 'Polygon') {
      map.getView().fit(extent, {
        padding: [20, 20, 20, 20],
        maxZoom: 18,
      });
    } else {
      let keepZoom = map.getView().getZoom();
      map.getView().fit(extent, {
        maxZoom: keepZoom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, jumpToLocation, sources]);

  useEffect(() => {
    if (!map || !jumpToCoordinate) return;

    map.getView().fit(jumpToCoordinate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, jumpToCoordinate]);

  useEffect(() => {
    if (!map) return;
    //map.updateSize() doesn't work without a small delay
    setTimeout(() => {
      map.updateSize();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarCollapsed]);

  useEffect(() => {
    if (!map) return;

    //If isViewerLocked = true, disable all movement-related Interactions
    map.getInteractions().forEach(interaction => {
      if (
        interaction instanceof DragRotateAndZoom ||
        interaction instanceof DragRotate ||
        interaction instanceof DragPan ||
        interaction instanceof DragZoom ||
        interaction instanceof PinchRotate ||
        interaction instanceof PinchZoom ||
        interaction instanceof KeyboardPan ||
        interaction instanceof KeyboardZoom ||
        interaction instanceof MouseWheelZoom
      ) {
        interaction.setActive(!isViewerLocked);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewerLocked]);

  return (
    <div id="map-container">
      <OpenLayersMapContext.Provider value={{ map }}>
        <div ref={mapRef} id="map">
          {children}
        </div>
      </OpenLayersMapContext.Provider>
    </div>
  );
}

export default OpenLayersMap;
