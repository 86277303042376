import { Button, Col, Row, Slider, Space } from 'antd';
import { Control } from 'ol/control';
import { useContext, useEffect, useRef } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import {
  selectImageZoomButtonLevel,
  selectImageZoomLevel,
  setImageZoomButtonLevel,
  setImageZoomLevel,
} from 'redux/slices/viewerOptions';

const ZOOM_LEVEL_LIST = [
  {
    label: 'X',
    value: 0,
  },
  {
    label: '0.5x',
    value: 1,
  },
  {
    label: '1x',
    value: 2,
  },
  {
    label: '2x',
    value: 3,
  },
  {
    label: '5x',
    value: 4,
  },
  {
    label: '10x',
    value: 5,
  },
  {
    label: '20x',
    value: 6,
  },
  {
    label: '40x',
    value: 7,
  },
  {
    label: '80x',
    value: 8,
  },
  {
    label: '160x',
    value: 9,
  },
];

export default function CustomScaleZoomBar() {
  const { map } = useContext(OpenLayersMapContext);
  const CustomScaleZoomBar = useRef<Control | null>(null);
  const scaleZoomBarDiv = useRef<HTMLElement | undefined>(undefined);
  const root = useRef<Root | null>(null);
  const dispatch = useDispatch();
  const imageZoomButtonLevel = useSelector(selectImageZoomButtonLevel);
  const imageZoomLevel = useSelector(selectImageZoomLevel);

  const handleInputChange = (value: number) => {
    if (!map) return;
    map.getView().setZoom(value);
    dispatch(setImageZoomLevel(value));
  };

  const radioOnChange = (newZoomLevel: number) => {
    if (!map) return;
    map.getView().setZoom(newZoomLevel);
    dispatch(setImageZoomButtonLevel(newZoomLevel));
  };

  const createCustomScaleZoomBar = () => {
    if (!root.current) return null;
    root.current.render(
      <Row>
        <Col span={12} style={{ margin: '10px 0px 16px 0px' }}>
          <Slider
            vertical
            value={imageZoomLevel}
            defaultValue={0}
            onChange={handleInputChange}
            max={9}
            step={0.1}
            tooltip={{ open: false }}
            reverse
            trackStyle={{
              background: '#6300cc',
            }}
          />
        </Col>
        <Col span={12}>
          <Button.Group>
            <Space direction="vertical" size={0}>
              {ZOOM_LEVEL_LIST.map(zoomLevel => (
                <Button
                  key={`zoom-${zoomLevel.label}`}
                  id={`zoom-${zoomLevel.value}`}
                  className="custom-scale-zoom-bar-button"
                  style={{
                    background: imageZoomButtonLevel === zoomLevel.value ? '#6300cc' : '#ffffff',
                    color: imageZoomButtonLevel === zoomLevel.value ? '#ffffff' : '#000000',
                  }}
                  onClick={() => radioOnChange(zoomLevel.value)}
                >
                  {zoomLevel.label}
                </Button>
              ))}
            </Space>
          </Button.Group>
        </Col>
      </Row>,
    );

    return new Control({ element: scaleZoomBarDiv.current });
  };

  useEffect(() => {
    if (!map) return;
    scaleZoomBarDiv.current = document.createElement('div');
    scaleZoomBarDiv.current.className = 'custom-scale-zoom-bar';
    root.current = createRoot(scaleZoomBarDiv.current);
    CustomScaleZoomBar.current = createCustomScaleZoomBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (!map || !CustomScaleZoomBar.current || imageZoomLevel === imageZoomButtonLevel) return;
    CustomScaleZoomBar.current = createCustomScaleZoomBar();
    if (!CustomScaleZoomBar.current) return;
    map.removeControl(CustomScaleZoomBar.current);
    map.addControl(CustomScaleZoomBar.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, imageZoomLevel]);

  return null;
}
