import {
  Badge,
  Button,
  Divider,
  Modal,
  Radio,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { CheckOutlined, UndoOutlined, RedoOutlined, EditOutlined } from '@ant-design/icons';
import ClearActiveClassificationButton from 'components/ViewerOptions/ClearActiveClassificationButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import {
  setActiveAnnotationClassification,
  setIsAnnotatingEnabled,
  selectIsAnnotatingEnabled,
  activateDefaultTool,
} from 'redux/slices/viewerOptions';
import {
  decrementChangesetPointer,
  incrementChangesetPointer,
  selectChangeset,
  selectChangesetPointer,
  selectSimplifiedChangeset,
  clearChangeset,
} from 'redux/slices/annotationDetails';
import { CHANGE_ACTION } from 'utils/Constants';
import {
  isUserCandidatePathologist,
  isUserQualityAssurance,
  getCurrentUser,
} from 'utils/Utils';
import AnnotationsVisibilitySwitch from 'components/ViewerOptions/AnnotationsVisibilitySwitch';

import './style.css';
import useEventListener from 'utils/useEventListener';
import { PipelineSchema, useAppendJobEventsMutation, useCompleteJobMutation, useGetClassificationsQuery, useGetTaskQuery, useGetJobAnnotationsQuery, JobAnnotationsSchema, JobSchema } from 'redux/slices/imageServerApi'
import ClassificationSelection from 'components/ClassificationSelection';
import QaCheckInput from 'components/QaCheckInput';
import CompleteReassignJobModal from 'components/CompleteReassignJobModal';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

interface ViewerOptionsProps {
  job: JobSchema,
  annotations?: JobAnnotationsSchema,
  pipeline?: PipelineSchema
}

const ViewerOptions: React.FC<ViewerOptionsProps> = ({ job, annotations, pipeline }) => {
  const dispatch = useDispatch();

  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const { data: classifications } = useGetClassificationsQuery();
  const changeset = useSelector(selectChangeset);
  const changesetPointer = useSelector(selectChangesetPointer);
  const simplifiedChangeset = useSelector(selectSimplifiedChangeset);

  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  const { refetch: refetchJobAnnotations } = useGetJobAnnotationsQuery({ id: job?.id }, { skip: !job });
  const { data: task } = useGetTaskQuery({ name: job?.task }, { skip: !job })
  const [appendEvents, { isLoading: isAppendingEvents }] = useAppendJobEventsMutation();

  const setClassification = (selectedClassificationId: string) => {
    dispatch(setActiveAnnotationClassification(selectedClassificationId));
  };

  const handleEnableAnnotationsSwitch = (checked: boolean) => {
    dispatch(setIsAnnotatingEnabled(checked));
    dispatch(activateDefaultTool());
  };

  const saveAnnotations = async () => {
    if (!job || changeset.length === 0) return;
    await appendEvents({ id: job.id, events: changeset });
    dispatch(clearChangeset());
    refetchJobAnnotations();
  }

  const __handleKeyDown = (event: KeyboardEvent) => {
    const props = store.getState();
    const { isHotspotDetailModalVisible } = props.viewerOptions;

    if (isHotspotDetailModalVisible) return;
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      event.preventDefault();
      saveAnnotations();
      return;
    }

    const selectedClassification = classifications.find(item => item.hot_key.toString() === event.key);

    if (!selectedClassification) {
      return;
    }

    setClassification(selectedClassification.id);
  };

  const isAnnotatingCheckboxEnabled = (job: JobSchema) => {
    const isSameUserOrQA = currentUser?.username === job.username || isUserQualityAssurance();
    return isSameUserOrQA && !job.is_completed;
  };

  useEventListener('keydown', __handleKeyDown);

  // Auto-Save every 2 minutes
  useEffect(() => {
    const autosave = setInterval(saveAnnotations, 120000); // 120000 ms equals 2 minutes
    return () => clearInterval(autosave); // cleanup on component unmount
  }, [changeset]);

  if (!job?.image || !task) return null;

  const isUndoEnabled = isAnnotatingEnabled && changeset.length > 0 && changesetPointer !== 0;
  const isRedoEnabled = isAnnotatingEnabled && changesetPointer !== null && changesetPointer !== changeset.length;

  const isCompletingJobDisabled = () => {
    return (
      !job ||
      !annotations ||
      job.is_completed ||
      Object.values(annotations.comments).some(c => !c.is_resolved) ||
      simplifiedChangeset.length > 0
    );
  };

  if (!job || !annotations || !pipeline) return null;

  return (
    <div className="viewer-options">
      <div id="viewer-annotation-options" className="viewer-options-row">
        <Space>
          <Tooltip
            title={
              <span>
                Enable Annotations
                <Tag style={{ marginLeft: 4, marginRight: 0 }}>Space</Tag>
              </span>
            }
          >
            <Switch
              id="enable-annotations"
              checkedChildren={<EditOutlined />}
              unCheckedChildren={<EditOutlined />}
              onChange={handleEnableAnnotationsSwitch}
              checked={isAnnotatingEnabled}
              disabled={job.is_completed || job.username !== getCurrentUser()?.username}
            />
          </Tooltip>

          <AnnotationsVisibilitySwitch />
        </Space>

        {
          task.name?.startsWith('REVIEW_') ? (
            <>
              <Divider type="vertical" />
              <QaCheckInput />
            </>
          ) : null
        }


        <Divider type="vertical" />

        <Radio.Group className="changeset-undo-redo">
          <Radio.Button
            id="undo-button"
            value="undo"
            onClick={() => dispatch(decrementChangesetPointer())}
            disabled={!isUndoEnabled}
          >
            <UndoOutlined />
          </Radio.Button>
          <Radio.Button
            id="redo-button"
            value="redo"
            onClick={() => dispatch(incrementChangesetPointer())}
            disabled={!isRedoEnabled}
          >
            <RedoOutlined />
          </Radio.Button>
        </Radio.Group>

        <Divider type="vertical" />

        <Tooltip
          title="Toggle editing annotations"
          trigger={isAnnotatingEnabled && simplifiedChangeset.length === 0 ? 'hover' : undefined}
        >
          <Button
            className="annotation-tool-save-button"
            type="primary"
            onClick={saveAnnotations}
            loading={isAppendingEvents}
            disabled={simplifiedChangeset.length === 0}
          >
            Save <CheckOutlined />
            <Tooltip
              mouseLeaveDelay={100}
              title={
                <div>
                  <p id="changeset-added-count">{`${simplifiedChangeset.filter(item => item.action === CHANGE_ACTION.ADD).length
                    } added`}</p>
                  <p id="changeset-updated-count">{`${simplifiedChangeset.filter(item => item.action === CHANGE_ACTION.UPDATE).length
                    } updated`}</p>
                  <p id="changeset-removed-count">{`${simplifiedChangeset.filter(item => item.action === CHANGE_ACTION.REMOVE).length
                    } removed`}</p>
                </div>
              }
              trigger={isAnnotatingEnabled && simplifiedChangeset.length > 0 ? 'hover' : undefined}
              destroyTooltipOnHide
            >
              <Badge
                className="save-button-count-badge"
                showZero
                overflowCount={999}
                count={simplifiedChangeset.length}
                style={{
                  backgroundColor: simplifiedChangeset.length > 0 ? '#ff0000' : '#aaaaaa',
                  color: '#ffffff',
                }}
              />
            </Tooltip>
          </Button>
        </Tooltip>
        <span>
          <Divider type="vertical" />
          <Tooltip
            title={
              'Mark the Job as completed'
            }
          >
            <CompleteReassignJobModal job={job} pipeline={pipeline} isCompletingDisabled={isCompletingJobDisabled()} />
          </Tooltip>
        </span>
      </div>
      <div className="viewer-options-row">
        <ClassificationSelection classificationScenarioId={pipeline.classification_scenario} />
        <ClearActiveClassificationButton />
      </div>
    </div>
  );
}

export default ViewerOptions;
