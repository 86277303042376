/// <reference types="vite-plugin-svgr/client" />
import RulerIcon from 'assets/icons/Ruler.svg?react';
import { Tag, Tooltip, Typography } from 'antd';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Control } from 'ol/control';
import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { Tool, selectActiveTool, toggleTool } from 'redux/slices/viewerOptions';
import './style.css';

const { Text } = Typography;

interface CustomToolProps {
  name: Tool;
  label: string;
  offset: number;
  icon?: ReactNode;
  iconText?: string;
  shortcut?: string;
  disabled?: boolean;
}

const CustomTool: React.FC<CustomToolProps> = ({ name, label, icon, iconText, shortcut, offset, disabled = false }) => {
  const dispatch = useDispatch();
  const { map } = useContext(OpenLayersMapContext);
  const activeTool = useSelector(selectActiveTool);

  let customToolRef = useRef<Control | null>(null);

  const initButtonClasses = (button: HTMLElement) => {
    if (activeTool === name) {
      button.classList.add('custom-tool-button-focus');
    } else {
      button.classList.remove('custom-tool-button-focus');
    }

    if (disabled) {
      button.classList.add('custom-tool-button-disabled');
    } else {
      button.classList.remove('custom-tool-button-disabled');
    }
  }

  const createCustomToolControl = () => {
    const button = document.createElement('button');
    button.id = `custom-${name}-tool-button`;
    button.className = 'custom-tool-button';
    initButtonClasses(button)

    const toolSpan = document.createElement('span');
    const root = createRoot(toolSpan);
    root.render(
      <Tooltip
        title={
          <span>
            {label}
            {shortcut && <Tag color="#fafafa" style={{ marginLeft: '8px', color: '#000000', border: '1px solid #d9d9d9' }}>
              {shortcut}
            </Tag>}
          </span>
        }
        placement="right"
      >
        {icon ?? <Text style={{ fontSize: '9px', color: 'white', fontStretch: '-2px' }}>{iconText}</Text>}
      </Tooltip>,
    );
    button.appendChild(toolSpan);

    const customToolContainerDiv = document.createElement('div');
    customToolContainerDiv.className = 'custom-tool ol-unselectable ol-control';

    // Add styles
    customToolContainerDiv.style.position = 'absolute';
    customToolContainerDiv.style.left = '0.5em';
    customToolContainerDiv.style.top = `${offset}px`;

    customToolContainerDiv.appendChild(button);

    button.addEventListener('click', () => {
      if (disabled) return;
      dispatch(toggleTool(name))
    });
    return new Control({ element: customToolContainerDiv });
  };

  useEffect(() => {
    if (!map) return;

    customToolRef.current = createCustomToolControl();
    map.addControl(customToolRef.current);

    return () => {
      if (customToolRef.current) {
        map.removeControl(customToolRef.current);
      }
    };

  }, [map, disabled]);


  useEffect(() => {
    const button = document.getElementById(`custom-${name}-tool-button`);
    if (!button) return;
    initButtonClasses(button)
  }, [activeTool, disabled, name]);

  return null;
}

export default CustomTool;
