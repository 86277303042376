export const CELL_CLASS = {
  DEFAULT: 'unclassified',
};

export const CELL_STYLE = {
  DEFAULT: {
    FILL: '#546e7a',
    STROKE: '#000000',
  },
};

export const DRAW_ACTION_TYPE = {
  ANNOTATION_DOT: 'Dot',
  HOTSPOT_POLYGON: 'Hotspot',
  NO_HOTSPOT: 'no_hotspot',
  ANNOTATION_POLYGON: 'Polygon',
  ANNOTATION_SEGMENTATION: 'Segmentation',
};

export const DRAW_ACTION_TYPES = [
  {
    key: DRAW_ACTION_TYPE.ANNOTATION_DOT,
    label: DRAW_ACTION_TYPE.ANNOTATION_DOT,
    value: DRAW_ACTION_TYPE.ANNOTATION_DOT,
  },
  {
    key: DRAW_ACTION_TYPE.HOTSPOT_POLYGON,
    label: DRAW_ACTION_TYPE.HOTSPOT_POLYGON,
    value: DRAW_ACTION_TYPE.HOTSPOT_POLYGON,
  },
  {
    key: DRAW_ACTION_TYPE.ANNOTATION_SEGMENTATION,
    label: DRAW_ACTION_TYPE.ANNOTATION_SEGMENTATION,
    value: DRAW_ACTION_TYPE.ANNOTATION_SEGMENTATION,
  },
];

export const CHANGE_ACTION = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',
};

export const USER_GROUP = {
  ANNOTATOR: 'Annotator',
  PATHOLOGIST: 'Pathologist',
  STUDY_PARTICIPANT: 'Study Participant',
  CANDIDATE_PATHOLOGIST: 'Candidate Pathologist',
  QUALITY_ASSURANCE: 'Quality Assurance',
  DATA_SCIENTIST: 'Data Scientist',
  ANNOTATION_MANAGER: 'Annotation Manager (Django Admin only)'
};

export const HOTSPOT_STATUS = {
  REVIEW_PATHOLOGIST: 'Review_Pathologist',
  SEGMENTATION: 'Segmentation',
  REVIEW_PATHOLOGIST_SEGMENTATION: 'Review_Pathologist_Segmentation',
};

export const HOTSPOT_PRIORITY = {
  LOW: 0,
  NORMAL: 1,
  HIGH: 2,
  URGENT: 3,
};

export const HOTSPOT_PRIORITIES = [
  {
    key: HOTSPOT_PRIORITY.LOW,
    label: 'Low',
    value: HOTSPOT_PRIORITY.LOW,
  },
  {
    key: HOTSPOT_PRIORITY.NORMAL,
    label: 'Normal',
    value: HOTSPOT_PRIORITY.NORMAL,
  },
  {
    key: HOTSPOT_PRIORITY.HIGH,
    label: 'High',
    value: HOTSPOT_PRIORITY.HIGH,
  },
  {
    key: HOTSPOT_PRIORITY.URGENT,
    label: 'Urgent',
    value: HOTSPOT_PRIORITY.URGENT,
  },
];

export const OL_LAYER_NAME = {
  HOTSPOTS: 'hotspots',
  ANNOTATIONS: 'annotations',
  ANNOTATIONS_WEBGL_OUTLINE: 'annotations_webgl_outline',
  ANNOTATIONS_SELECT: 'annotations_select',
  ANNOTATION_POLYGON: 'annotation_polygon',
  ANNOTATION_SEGMENTATION: 'annotation_segmentation',
  ANNOTATION_POLYGON_ISSUE: 'annotation_polygon_issue',
  COMMENTS: 'comments',
  WSI_REGION: 'wsi_region',
};

export const DEFAULT_MICRONS_PER_PIXEL = 0.25;
export const DOUBLE_DOTS_PREVENTION_RADIUS_MICRONS = 1.5;

export const SORT_QA_ITEMS_BY = {
  nameASC: 'Name (A-Z)',
  nameDESC: 'Name (Z-A)',
  completedDateDESC: 'Completed Date (Newest first)',
  completedDateASC: 'Completed Date (Oldest first)',
};

export const ASSIGNMENT_TAB = {
  JOBS: {
    key: 'jobs',
    title: 'Jobs',
  },
  PIPELINES: {
    key: 'pipelines',
    title: 'Pipelines',
  },
  IMAGES: {
    key: 'images',
    title: 'Images',
  },
};

export const ASSIGNMENT_TYPE = {
  HOTSPOT: 'Hotspot',
  IMAGE_ANNOTATION: 'Image Annotation',
  IMAGE: 'Image',
};
