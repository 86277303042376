import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useContext, useEffect, useRef } from 'react';

import { OL_LAYER_NAME } from 'utils/Constants';
import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';
import { RoiAnnotationSchema } from 'redux/slices/imageServerApi';


interface ROILayerProps {
  source: VectorSource<Feature<Geometry>>;
  roiAnnotations: Array<RoiAnnotationSchema>;
  visible?: boolean;
};

const ROILayer: React.FC<ROILayerProps> = ({ source, roiAnnotations, visible = true }) => {
  const { map } = useContext(OpenLayersMapContext);

  let hotspotLayer = useRef<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);

  const initOLLayer = () => {
    const hotspotLayer = new VectorLayer({
      className: 'hotspot-layer',
      properties: { name: OL_LAYER_NAME.HOTSPOTS },
      source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: new Style({
        stroke: new Stroke({
          color: 'black',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
    });
    hotspotLayer.setZIndex(1);
    return hotspotLayer;
  };

  useEffect(() => {
    if (!map) return;
    hotspotLayer.current = initOLLayer();
    map.addLayer(hotspotLayer.current);

    return () => {
      if (map && hotspotLayer.current) {
        map.removeLayer(hotspotLayer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source]);

  useEffect(() => {
    if (!map || !hotspotLayer.current) return;
    hotspotLayer.current.setVisible(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (!source || !hotspotLayer.current) return;
    hotspotLayer.current.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roiAnnotations]);

  useEffect(() => {
    if (!source) return;
    source.clear();
  }, [source]);

  useEffect(() => {
    if (!roiAnnotations || roiAnnotations.length === 0) return;

    source.clear();
    roiAnnotations.forEach(roi => {
      let newROI = new Feature({});

      let coordinates: Array<Array<number>> = [];

      roi.coordinates.forEach(coordinate => {
        coordinates.push([coordinate[0], -coordinate[1]]);
      });
      newROI.setGeometry(new Polygon([coordinates]));

      newROI.setId(roi.id);

      source.addFeature(newROI);
      source.changed();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roiAnnotations]);

  return null
}

export default ROILayer;
