import React from 'react';
import { Space, Select, Input } from 'antd';
import { useGetLabsQuery, useGetOrgansQuery, useGetStainingsQuery, GetImagesApiArg, useGetProjectOverviewQuery } from 'redux/slices/imageServerApi';

const { Search } = Input;

interface ImageScanFiltersProps {
  setFilters: (filters: GetImagesApiArg) => void;
  filters: GetImagesApiArg;
}

export const ImageScanFilters: React.FC<ImageScanFiltersProps> = ({ setFilters, filters }) => {
  const { data: projects } = useGetProjectOverviewQuery();
  const { data: labs } = useGetLabsQuery();
  const { data: stainings } = useGetStainingsQuery();
  const { data: organs } = useGetOrgansQuery();

  return (
    <Space wrap style={{ padding: '8px 16px' }}>
      <Search
        placeholder="Image Name"
        onSearch={(value) => setFilters({ ...filters, name: value })}
        style={{ width: 200 }}
        allowClear
      />
      <Select
        style={{ width: 160 }}
        placeholder="Project"
        allowClear
        options={projects?.map(p => ({ value: p.id, label: p.name }))}
        onChange={(value) => setFilters({ ...filters, project: value })}
        value={filters.project}
        showSearch
        optionFilterProp='label'
      />
      <Select
        style={{ width: 160 }}
        placeholder="Lab"
        mode="multiple"
        allowClear
        options={labs?.map(l => ({ value: l.id, label: l.name }))}
        onChange={(value) => setFilters({ ...filters, labId: value })}
        value={filters.labId}
        showSearch
        optionFilterProp='label'
      />
      <Select
        style={{ width: 160 }}
        placeholder="Organ"
        mode="multiple"
        allowClear
        options={organs?.map(o => ({ value: o.id, label: o.name }))}
        onChange={(value) => setFilters({ ...filters, organ: value })}
        value={filters.organ}
        showSearch
        optionFilterProp='label'
      />
      <Select
        style={{ width: 160 }}
        placeholder="Staining"
        mode="multiple"
        allowClear
        options={stainings?.map(s => ({ value: s.id, label: s.name }))}
        onChange={(value) => setFilters({ ...filters, staining: value })}
        value={filters.staining}
        showSearch
        optionFilterProp='label'
      />
    </Space>
  );
}
