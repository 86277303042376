import { useDispatch, useSelector } from 'react-redux';
import { Button, Switch, Tag, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import {
  selectIsAnnotationsVisible,
  selectIsHotspotDetailModalVisible,
  setIsAnnotationsVisible,
} from 'redux/slices/viewerOptions';

export default function AnnotationsVisibilitySwitch() {
  const dispatch = useDispatch();
  const isAnnotationsVisible = useSelector(selectIsAnnotationsVisible);
  const isHotspotDetailModalVisible = useSelector(selectIsHotspotDetailModalVisible);

  const handleShowHideAnnotations = () => {
    const AnnotationVisibilityButton = document.getElementById('toggle-annotation-visibility-button');
    if (AnnotationVisibilityButton) {
      AnnotationVisibilityButton.blur();
    }
    dispatch(setIsAnnotationsVisible(!isAnnotationsVisible));
  };

  useEffect(() => {
    const __handleKeyDown = (event: KeyboardEvent) => {
      if (isHotspotDetailModalVisible) return;
      if (event.key === 'Space') {
        handleShowHideAnnotations();
      }
    };

    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
  });

  return (
    <Tooltip
      title={
        <span>
          Show/Hide Annotations
          <Tag style={{ marginLeft: 4, marginRight: 0 }}>a</Tag>
        </span>
      }
    >
      <Switch
        checkedChildren={<EyeOutlined />}
        unCheckedChildren={<EyeInvisibleOutlined />}
        onChange={handleShowHideAnnotations}
        checked={isAnnotationsVisible}
      />
    </Tooltip>
  );
}
