import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Login from 'components/Login';
import ResetPassword from 'components/ResetPassword';
import AppLayout from 'components/AppLayout';
import ImageGrid from 'components/ImageGrid';
import AnnotationLayout from 'components/AnnotationLayout';
import ImageQALayout from 'components/ImageQALayout';
import JobOutputTable from 'components/JobOutputsTable';
import { ImageTable } from 'components/ImageTable';
import PipelineTable from 'components/PipelineTable';
import PipelineGrid from 'components/PipelineGrid';
import BacklogGrid from 'components/BacklogGrid';
import { getCurrentUser } from 'utils/Utils';
import JobTable from 'components/JobTable';

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="*" element={
          getCurrentUser() ? (
            <AppLayout>
              <Routes>
                <Route path="/" element={<Navigate to="/jobs" replace />} />
                <Route path="jobs/:jobId" element={<AnnotationLayout />} />
                <Route path="jobs/*" element={<JobTable />} />
                <Route path="pipelines/" element={<PipelineTable />} />
                <Route path="pipelines/grid/" element={<PipelineGrid />} />
                <Route path="pipelines/create" element={<ImageTable />} />
                <Route path="pipelines/:pipelineId" element={<PipelineTable />} />
                <Route path="backlogs/" element={<BacklogGrid />} />
                <Route path="annotation-outputs/" element={<JobOutputTable />} />
                <Route path="images/*" element={<ImageGrid />} />
                <Route path="images/:imageId" element={<ImageQALayout />} />
              </Routes>
            </AppLayout>
          ) : (
            <Navigate to="/login" replace />
          )
        } />
      </Routes>
    </Router>
  );
}