import { Tag, Tooltip } from 'antd';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { Control } from 'ol/control';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsViewerLocked, setIsViewerLocked } from 'redux/slices/viewerOptions';
import { store } from 'redux/store';
import './style.css';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

export default function CustomLockTool() {
  const dispatch = useDispatch();
  const { map } = useContext(OpenLayersMapContext);
  const isViewerLocked = useSelector(selectIsViewerLocked);

  let CustomLockTool = useRef<Control | null>(null);

  const createCustomLockTool = (isViewerLocked: boolean) => {
    const button = document.createElement('button');
    button.id = isViewerLocked ? 'custom-lock-button-focus' : 'custom-lock-button';

    const customLockSpan = document.createElement('span');
    const root = createRoot(customLockSpan);
    root.render(
      <Tooltip
        title={
          <span>
            Lock Tool
            <Tag color="#fafafa" style={{ marginLeft: '8px', color: '#000000', border: '1px solid #d9d9d9' }}>
              L
            </Tag>
          </span>
        }
        color="#6300cc"
        placement="right"
      >
        {isViewerLocked ? <LockOutlined style={{ margin: '3px' }} /> : <UnlockOutlined style={{ margin: '3px' }} />}
      </Tooltip>,
    );
    button.appendChild(customLockSpan);

    const CustomLockToolContainerDiv = document.createElement('div');
    CustomLockToolContainerDiv.id = 'custom-lock-tool-id';
    CustomLockToolContainerDiv.className = 'custom-lock-tool ol-unselectable ol-control';

    CustomLockToolContainerDiv.appendChild(button);

    button.addEventListener('click', () => {
      const props = store.getState();
      const { isViewerLocked } = props.viewerOptions;
      dispatch(setIsViewerLocked(!isViewerLocked));
    });
    return new Control({ element: CustomLockToolContainerDiv });
  };

  const __handleKeyDown = useCallback((event: KeyboardEvent) => {
    const props = store.getState();
    const { isHotspotDetailModalVisible, isViewerLocked } = props.viewerOptions;

    if (isHotspotDetailModalVisible) return;
    if (event.key === 'l') {
      dispatch(setIsViewerLocked(!isViewerLocked));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    CustomLockTool.current = createCustomLockTool(false);
    document.addEventListener('keydown', __handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', __handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!map) return;

    map.removeControl(CustomLockTool.current);
    CustomLockTool.current = createCustomLockTool(isViewerLocked);
    map.addControl(CustomLockTool.current);

    return () => {
      if (CustomLockTool.current) {
        map.removeControl(CustomLockTool.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isViewerLocked]);

  return null;
}
