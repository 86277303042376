import { useSelector } from 'react-redux';
import { selectMouseCoordinates } from 'redux/slices/viewerOptions';
import { Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function MouseCoordinates() {
  const mouseCoordinates = useSelector(selectMouseCoordinates);
  return (
    <span style={{ float: 'right' }}>
      {mouseCoordinates}
      <Tooltip
        title={
          <>
            Press <Tag style={{ marginLeft: 4, marginRight: 0 }}>ctrl</Tag> and{' '}
            <Tag style={{ marginLeft: 4, marginRight: 0 }}>c</Tag> at any time to copy the cursor coordinates to your
            keyboard
          </>
        }
        placement="topRight"
      >
        <InfoCircleOutlined style={{ marginLeft: 4 }} />
      </Tooltip>
    </span>
  );
}
