import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { v4 as uuidv4 } from 'uuid';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAnnotationEvent, getCurrentUser } from 'utils/Utils';

import {
  selectIsAnnotatingEnabled,
} from 'redux/slices/viewerOptions';
import {
  appendToChangeset,
} from 'redux/slices/annotationDetails';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { Collection, Feature, Overlay } from 'ol';
import { Layer } from 'ol/layer';
import { Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

type CommentInteractionProps = {
  source: VectorSource;
};

export default function CommentInteraction({ source }: CommentInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

  const drawHotspotRef = useRef<Draw | null>(null);
  const selectHotspotRef = useRef<Select | null>(null);

  const __handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        if (drawHotspotRef.current) {
          drawHotspotRef.current.abortDrawing();
        }
        break;
      case 'Backspace':
        if (drawHotspotRef.current) {
          drawHotspotRef.current.removeLastPoint();
        }
        break;
      default:
        break;
    }
  };

  const drawHotspotOnDrawEnd = (event: DrawEvent) => {
    if (!map) return;
    const tempId = uuidv4();
    event.feature.setId(tempId);

    const coordinates = (event.feature.getGeometry() as Polygon).getCoordinates()[0] as [number, number][];

    source.addFeatures([event.feature]);

    // Create an overlay element
    var overlayElement = document.createElement('div');
    overlayElement.innerHTML = `
    <form id="commentForm" style="background-color:white;padding:5px;">
      <label>
        Comment:
        <input type="text" name="comment" required/>
      </label>
      <br/>
      <button type="submit">Submit</button>
      <button type="button" id="abortBtn">Abort</button> <!-- Abort button -->
    </form>`;

    // Stop key propagation in order not to trigger shortcuts
    var commentInput = overlayElement.querySelector('input[name="comment"]');
    if (commentInput) {
      commentInput.addEventListener('keydown', function (event) {
        event.stopPropagation();
      });
    }

    // Create the overlay
    var overlay = new Overlay({
      element: overlayElement,
      position: coordinates[0],
    });

    map.addOverlay(overlay);

    // Handle abort button click
    document.getElementById('abortBtn')?.addEventListener('click', (e) => {
      e.preventDefault();

      // Remove the feature from the source
      source.removeFeature(event.feature);

      // Remove the overlay
      map.removeOverlay(overlay);
    });

    // Handle form submission
    document.getElementById('commentForm')?.addEventListener('submit', (e) => {
      e.preventDefault();

      let comment = (e.target as any).comment.value;

      // Dispatch with the comment
      dispatch(
        appendToChangeset(createAnnotationEvent({
          type: "ADD_COMMENT",
          payload: {
            id: uuidv4(),
            content: comment,
            coordinates: coordinates.map(c => ([Math.round(c[0]), Math.round(-c[1])])),
            type: "internal",
            username: getCurrentUser()?.username
          },
        })),
      );

      // Remove the overlay once done
      map.removeOverlay(overlay);
    });
  };

  const initDrawHotspot = () => {
    if (!map) return;
    drawHotspotRef.current = new Draw({
      source,
      type: 'Polygon',
      condition: e => {
        return e.originalEvent.button === 0;
      },
    });
    drawHotspotRef.current.on('drawend', drawHotspotOnDrawEnd);
    map.addInteraction(drawHotspotRef.current);
  };

  const handleSelectHotspot = (event: SelectEvent) => {
    if (drawHotspotRef.current) {
      drawHotspotRef.current.abortDrawing();
    }
    event.deselected.forEach(deselectedFeature => {
      deselectedFeature.set('is_selected', false);
    });
    if (!event.selected[0]) return;
    const selectedFeature = event.selected[0];
    if (selectedFeature.get('is_filtered') === 'true') return;
    selectedFeature.set('is_selected', true);
  };

  const initSelectHotspot = () => {
    if (!map) return;
    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => a.get('is_selected')),
    );
    selectHotspotRef.current = new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.COMMENTS;
      },
      features: selectedFeatures,
      style: [
        new Style({
          stroke: new Stroke({
            color: '#ffffff',
            width: 5,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: '#0099ff',
            width: 3,
          }),
        }),
      ],
    });
    selectHotspotRef.current.on('select', handleSelectHotspot);
    map.addInteraction(selectHotspotRef.current);
  };

  window.roiAnnotationSource = source;

  useEffect(() => {
    if (!map || !source || !isAnnotatingEnabled) return;

    initDrawHotspot();
    initSelectHotspot();

    document.addEventListener('keydown', __handleKeyDown);

    return () => {
      if (map) {
        if (selectHotspotRef.current) {
          map.removeInteraction(selectHotspotRef.current);
          selectHotspotRef.current.un('select', handleSelectHotspot);
        }

        if (drawHotspotRef.current) {
          map.removeInteraction(drawHotspotRef.current);
          drawHotspotRef.current.un('drawend', drawHotspotOnDrawEnd);
        }

        document.removeEventListener('keydown', __handleKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingEnabled, map]);

  return null;
}
