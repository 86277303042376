import { notification } from 'antd';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import IIIFInfo from 'ol/format/IIIFInfo';
import OLTileLayer from 'ol/layer/Tile';
import Projection from 'ol/proj/Projection';
import IIIF from 'ol/source/IIIF';
import View from 'ol/View';
import { useContext, useEffect, useState } from 'react';
import {
  clearImageImageZoomButtonLevel,
  incrementTileLoadProgressLoaded,
  incrementTileLoadProgressLoading,
  setImageZoomLevel,
} from 'redux/slices/viewerOptions';
import { authenticatedTileLoader } from 'service/api';
import { DEFAULT_MICRONS_PER_PIXEL } from 'utils/Constants';
import { captureApplicationError } from 'utils/Utils';
import { useAppDispatch } from 'utils/Hooks';
import { ObjectEvent } from 'ol/Object';
import { useGetImageInfoQuery } from 'redux/slices/api';
import { ImageSchema } from 'redux/slices/imageServerApi';

interface WholeSlideImageLayerProps {
  image: ImageSchema;
}

const WholeSlideImageLayer: React.FC<WholeSlideImageLayerProps> = ({ image }) => {
  const dispatch = useAppDispatch();
  const { map } = useContext(OpenLayersMapContext);
  const [source, setSource] = useState<IIIF>();
  const { data: imageInfo } = useGetImageInfoQuery(`${image.name}${image.file_type}`)

  const wholeSlideImageLayer = new OLTileLayer({
    className: 'whole-slide-image-layer',
    preload: Infinity,
  });

  useEffect(() => {
    if (!imageInfo) return;

    let imageInfoUnfrozen = JSON.parse(JSON.stringify(imageInfo));
    let iiifInfo = new IIIFInfo(imageInfoUnfrozen).getTileSourceOptions();

    if (iiifInfo === undefined || iiifInfo.version === undefined) {
      captureApplicationError(`error reading image info json - 'Data is not valid IIIF image information.'`);
      notification['error']({
        message: 'Error loading Image',
      });
      return;
    }

    iiifInfo.zDirection = -1;

    const iiifTileSource = new IIIF(iiifInfo);
    iiifTileSource.setTileLoadFunction(authenticatedTileLoader);
    setSource(iiifTileSource);
  }, [imageInfo]);

  useEffect(() => {
    if (!map || !source || !imageInfo) return;

    wholeSlideImageLayer.setSource(source);

    map.addLayer(wholeSlideImageLayer);
    wholeSlideImageLayer.setZIndex(-100);

    const micronsPerPixel = imageInfo.micronsPerPixel ?? DEFAULT_MICRONS_PER_PIXEL;

    const projection = new Projection({
      code: 'custom_projection',
      getPointResolution(resolution) {
        return resolution * micronsPerPixel * 0.000001;
      },
      units: 'pixels',
      extent: [0, 0, imageInfo.width, imageInfo.height],
    });

    //Resolution is 'projection units per pixel'. Above, we set projection units to μm
    let initialResolution = imageInfo.width * imageInfo.height * micronsPerPixel * 0.0000001;
    //If initialResolution from calculation is too low, set it to something above 18
    if (initialResolution <= 18 / micronsPerPixel) {
      initialResolution = 30 / micronsPerPixel;
    }
    const resolutions = [
      initialResolution, // No zoom level
      18 / micronsPerPixel, // 0.5x
      9 / micronsPerPixel, // 1x
      5 / micronsPerPixel, // 2x
      2 / micronsPerPixel, // 5x
      1 / micronsPerPixel, // 10x
      0.5 / micronsPerPixel, // 20x
      0.25 / micronsPerPixel, // 40x
      0.1 / micronsPerPixel, // 80x
      0.05 / micronsPerPixel, // 160x
    ];

    map.setView(
      new View({
        projection,
        resolutions,
        zoomFactor: 1.5,
        extent: source.getTileGrid()!.getExtent(),
        showFullExtent: true,
        smoothExtentConstraint: false,
      }),
    );

    map.getView().fit(source.getTileGrid()!.getExtent());

    map.getView().on('change:resolution', (event: ObjectEvent) => {
      const currentZoom = event.target.getZoom();
      dispatch(setImageZoomLevel(currentZoom.toFixed(1)));
      dispatch(clearImageImageZoomButtonLevel());
    });

    map.getView().on('change:rotation', (event: ObjectEvent) => {
      const view = event.target;
      const viewRotation = view.getRotation();

      const CustomRotationArrow = document.getElementsByClassName(
        'custom-rotation-arrow ol-unselectable ol-control',
      )[0] as HTMLElement;
      //this check deals with hiding CustomRotationArrow correctly if radian value is >(2 * Math.PI) or <(2 * Math.PI)...
      // e.g. a user has rotated many times without resetting the rotation
      if (viewRotation === 0) {
        CustomRotationArrow.style.opacity = '0.3';
        const CustomRotationArrowArrow = document.getElementById('custom-rotation-arrow-icon');
        if (CustomRotationArrowArrow) {
          CustomRotationArrowArrow.style.transform = `rotate(0rad)`;
        }
      } else {
        CustomRotationArrow.style.opacity = '1';
        const CustomRotationArrowArrow = document.getElementById('custom-rotation-arrow-icon');
        if (CustomRotationArrowArrow) {
          CustomRotationArrowArrow.style.transform = `rotate(${viewRotation}rad)`;
        }
      }
    });

    map.updateSize();

    source.on('tileloadstart', function () {
      dispatch(incrementTileLoadProgressLoading());
    });
    source.on(['tileloadend', 'tileloaderror'], function () {
      dispatch(incrementTileLoadProgressLoaded());
    });

    return function cleanup() {
      if (map) {
        map.removeLayer(wholeSlideImageLayer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, map, imageInfo]);

  return null;
}

export default WholeSlideImageLayer