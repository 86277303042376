import { captureApplicationError, getCurrentUser } from 'utils/Utils';
import { notification } from 'antd';
import FetchRetry from 'fetch-retry';
import { LoadFunction } from 'ol/Tile';
import { ImageTile } from 'ol';

export const backendUrl = (endpoint: string): URL => {
  const base = window._env_.REACT_APP_BACKEND_API_URL || 'http://localhost:8000';
  return new URL(`${base}/api/${endpoint}`);
};

export const readerUrl = (endpoint: string): URL => {
  const base = window._env_.REACT_APP_READER_API_URL || 'http://localhost:8008';
  return new URL(`${base}/api/v1/image/iiif/${endpoint}`);
};

export const thumbnailUrl = (endpoint: string): URL => {
  const base = window._env_.REACT_APP_READER_API_URL || 'http://localhost:8008';
  return new URL(`${base}/api/v1/image/thumbnail/${endpoint}`);
};

export const getAuthorization = () => {
  const token = getCurrentUser()?.accessToken;
  if (!token) return null;
  return `Bearer ${token}`;
};

const fetchRetry = FetchRetry(fetch, {
  retryDelay: 1000,
  retryOn: function (attempt, error, response) {
    if (attempt === 1) {
      return false;
    }

    if (!response) return true;

    return error !== null || response.status >= 400;
  },
});

export const getRequestWithAuth = async (url: URL, signal: AbortSignal | null) => {
  const fetchResponse = await fetchRetry(url.toString(), {
    headers: {
      Authorization: getAuthorization(),
    },
    signal: signal,
  }).catch(err => {
    //This catches errors like 'Failed to fetch' - server errors are dealt with below
    captureApplicationError(`error fetching ${url} - ${err}`);
  });

  if (!fetchResponse) throw new Error('');

  if (!fetchResponse.ok || fetchResponse.status >= 400) {
    let fetchJSON = null;
    try {
      fetchJSON = await fetchResponse.json();
    } catch (err) {
      throw new Error('');
    }
    if (fetchJSON.detail) {
      throw new Error('');
    }
    if (fetchJSON.error) {
      throw new Error(fetchJSON.error);
    }
    throw new Error(fetchJSON);
  } else {
    return fetchResponse;
  }
};

export const simpleGetRequestWithAuth = async (url: URL, signal: AbortSignal | null) => {
  return await fetchRetry(url.toString(), {
    headers: {
      Authorization: getAuthorization(),
    },
    signal: signal,
  }).catch(err => {
    //This catches errors like 'Failed to fetch'
    captureApplicationError(`error fetching ${url} - ${err}`);
  });
};

export const authenticatedTileLoader: LoadFunction = (tile, src) => {
  getRequestWithAuth(src as any as URL, null)
    .then(resp => {
      if (!resp) {
        throw new Error('Error loading tile');
      }
      const response = resp as Response;

      let data = response.blob();

      if (data !== undefined) {
        return data;
      }
      //3 = TileState.ERROR from ol but ol import breaks unit tests
      tile.setState(3);
    })
    .then(result => {
      if (result) {
        ((tile as ImageTile).getImage() as HTMLImageElement).src = URL.createObjectURL(result);
      }
    })
    .catch(error => {
      captureApplicationError(`error loading tile - ${error.toString()}`);
    });
};

export const simplePostRequest = (url: URL, data: any) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    },
    body: JSON.stringify(data),
  });
};

export const postRequest = (url: URL, data: any) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthorization(),
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      if (response.status === 401) {
        throw new Error('401 Unauthorised');
      }
      return response;
    })
    .catch(error => {
      captureApplicationError(`base post request error - ${error}`);
      if (error.toString().includes('401 Unauthorised')) {
        //store.dispatch(setShowUserReAuthDialog(true));
        notification['error']({
          message: 'Your session has expired. Please log in again.',
        });
        return 'Unauthorised';
      }
    });
};

export const getThumbnailBlobPromise = (uri: URL) => {
  return new Promise((resolve, reject) => {
    const options = {
      headers: {
        Authorization: getAuthorization(),
      },
    };

    fetch(uri, options)
      .then(res => res.blob())
      .then(resolve)
      .catch(reject);
  });
};
