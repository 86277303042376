import { useState } from 'react';
import { Alert, Button, Card, Form, Input, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './style.css';
import logo from 'assets/images/mindpeak_logoform_digital_purple_borderless.png';
import { useNavigate } from 'react-router-dom';
import { captureApplicationError } from 'utils/Utils';
import { backendUrl } from 'service/api';

export default function ResetPassword() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [username, setUsername] = useState('');
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInitiatePasswordReset = (values: { username: string; password: string }) => {
    setIsLoading(true);
    const initiatePasswordResetUrl = backendUrl('user/initiate_password_reset/');

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', values['username'].trim());

    fetch(initiatePasswordResetUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(() => {
        setIsVerificationCodeSent(true);
        setIsLoading(false);
        setUsername(values['username'].trim());
        setErrorMessage('');
        setIsAlertShown(false);
      })
      .catch(err => {
        err.json().then((errorMessage: string) => {
          captureApplicationError(`error requesting password reset verification code - ${errorMessage}`);

          setIsLoading(false);
          setUsername(values['username'].trim());
          setErrorMessage(errorMessage);
          setIsAlertShown(true);
        });
      });
  };

  const handleConfirmPasswordReset = (values: { verification_code: string; new_password: string }) => {
    setIsLoading(true);

    let bodyFormData = new window.FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('verification_code', values['verification_code']);
    bodyFormData.append('new_password', values['new_password']);

    const confirmPasswordResetUrl = backendUrl('user/confirm_password_reset/');
    fetch(confirmPasswordResetUrl, {
      method: 'POST',
      body: bodyFormData,
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(() => {
        setIsLoading(false);
        setIsAlertShown(true);
        setErrorMessage('');

        setTimeout(() => {
          navigate('/');
        }, 4000);
      })
      .catch(err => {
        err.json().then((errorMessage: string) => {
          captureApplicationError(`error resetting password to Mindpeak Annotation Tool - ${errorMessage}`);

          setIsLoading(false);
          setIsAlertShown(true);
          setErrorMessage(errorMessage);
        });
      });
  };

  return (
    <div className="reset-password" style={{ background: '#7AE1BF' }}>
      <div className="form-container">
        <Form
          className="reset-password-form"
          initialValues={{ remember: true }}
          onFinish={
            isVerificationCodeSent
              ? values =>
                  handleConfirmPasswordReset({
                    verification_code: values['verification_code'],
                    new_password: values['new_password'],
                  })
              : values =>
                  handleInitiatePasswordReset({
                    username: values['username'],
                    password: values['password'],
                  })
          }
          layout="vertical"
        >
          <Card>
            <Form.Item style={{ textAlign: 'center' }}>
              <img width={360} src={logo} alt="Mindpeak Logo" />
            </Form.Item>
            <Form.Item style={{ paddingTop: '10px' }}>
              <Typography>
                {!isVerificationCodeSent
                  ? 'Resetting your password is easy. ' +
                    'First, please enter your username so we can send you a verification code via email.'
                  : 'You should now have received a verification code via email. ' +
                    'Please enter the code here and specify a new password.'}
              </Typography>
            </Form.Item>
            {!isVerificationCodeSent ? (
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Username"
                  size="large"
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  label="Verification Code"
                  name="verification_code"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your verification code from the email.',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Verification Code"
                    size="large"
                    disabled={isAlertShown && !errorMessage}
                  />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your new Password!',
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="New Password"
                    size="large"
                    disabled={isAlertShown && !errorMessage}
                  />
                </Form.Item>
              </>
            )}
            <Button data-test="return-to-login" style={{ padding: 0, float: 'right' }} type="link" href="/login">
              Return to login
            </Button>
            <Form.Item style={{ clear: 'both' }}>
              <Button
                data-test="submit-reset-password"
                htmlType="submit"
                type="primary"
                style={{ width: '100%', marginTop: 16 }}
                loading={isLoading}
                size="large"
                disabled={isAlertShown && !errorMessage}
              >
                {!isVerificationCodeSent ? 'Request verification code' : 'Confirm new password'}
              </Button>
            </Form.Item>
            {isAlertShown &&
              (errorMessage ? (
                <Alert message="Error resetting password" description={errorMessage} type="error" showIcon />
              ) : (
                <Alert
                  message="Successfully reset password"
                  description="You may now log in with your new password."
                  type="success"
                  showIcon
                />
              ))}
          </Card>
        </Form>
      </div>
    </div>
  );
}
