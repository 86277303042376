import React, { useEffect, useState } from 'react';
import { Table, TableColumnsType, Button, Space, Tooltip } from 'antd';
import { PipelineSchema, useGetPipelinesQuery, useGetBacklogsQuery, useGetLabsQuery, useGetOrgansQuery, useGetScannersQuery, useGetStainingsQuery, useGetWorkflowQuery, useGetWorkflowsQuery, GetPipelinesApiArg } from 'redux/slices/imageServerApi';
import { useFilters } from 'redux/hooks';
import { CheckCircleTwoTone, ClockCircleTwoTone, FileImageOutlined, PartitionOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import JobTreeModal from 'components/JobTreeModal';

interface PipelineTableProps {
}

const PipelineTable: React.FC<PipelineTableProps> = () => {
  const { pipelineId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(100);
  const [modalRootJobId, setModalRootJobId] = useState<string | null>(null);
  const [filters, setFilters] = useFilters<GetPipelinesApiArg>();

  const { data: pipelines, isLoading, isFetching, refetch } = useGetPipelinesQuery({ ...filters, page })
  const { data: labs } = useGetLabsQuery();
  const { data: stainings } = useGetStainingsQuery();
  const { data: organs } = useGetOrgansQuery();
  const { data: scanners } = useGetScannersQuery();
  const { data: backlogs } = useGetBacklogsQuery();
  const { data: workflows } = useGetWorkflowsQuery();

  const columns: TableColumnsType<PipelineSchema> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Lab',
      dataIndex: ['image', 'scan', 'lab', 'name'],
      key: 'labId',
      filters: labs?.map(l => ({ text: l.name, value: l.id })),
      filteredValue: filters.labId,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Staining',
      dataIndex: ['image', 'scan', 'staining', 'name'],
      key: 'staining',
      filters: stainings?.map(s => ({ text: s.name, value: s.id })),
      filteredValue: filters.staining,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Organ',
      dataIndex: ['image', 'scan', 'organ', 'name'],
      key: 'organ',
      filters: organs?.map(o => ({ text: o.name, value: o.id })),
      filteredValue: filters.organ,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: 'Scanner',
      dataIndex: ['image', 'scan', 'scanner'],
      key: 'scanner',
      filters: scanners?.map(s => ({ text: s.name, value: s.id })),
      filteredValue: filters.scanner,
      filterSearch: true,
      filterMultiple: true,
    },
    {
      title: '# Jobs',
      dataIndex: 'num_jobs',
      key: 'num_jobs',
    },
    {
      title: 'Workflow',
      dataIndex: 'workflow',
      key: 'workflow',
      filters: workflows?.map(w => ({ text: w.name, value: w.name })),
      filteredValue: filters.workflow,
    },
    {
      title: 'Backlog',
      dataIndex: 'backlog',
      key: 'backlog',
      filters: backlogs?.map(b => ({ text: b.name, value: b.id })),
      render: (value) => backlogs?.find(b => b.id === value)?.name,
    },
    {
      title: 'Status',
      dataIndex: 'is_wip',
      key: 'isWip',
      render: (value) => !value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <ClockCircleTwoTone spin />,
      filters: [{ text: 'WIP', value: true }, { text: 'Complete', value: false }]
    },
    {
      title: 'Active Users',
      dataIndex: 'active_users',
      key: 'activeUsers',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => renderRowActions(record)
    }
  ];

  const renderRowActions = (record: PipelineSchema) => {
    return (
      <Space>
        <Tooltip title="Image QA">
          <Button type="default" icon={<FileImageOutlined />} onClick={() => navigate(`/images/${record.image.id}`)} />
        </Tooltip>
        <Tooltip title="Job History Tree">
          <Button type="default" icon={<PartitionOutlined />} onClick={() => navigate(`/pipelines/${record.id}`)} />
        </Tooltip>
      </Space>
    )
  }

  const totalItems = pipelines ? pipelines.count : 0;

  const handleTableChange = (pagination: any, filters: any) => {
    if (page !== pagination.current) {
      setPage(pagination.current);
    } else {
      setFilters(filters);
    }
    refetch();
  };

  useEffect(() => {
    if (!pipelines || !pipelineId) return;

    const pipeline = pipelines.items.find(ap => ap.id === pipelineId);
    if (pipeline?.root_job_id !== modalRootJobId) {
      setModalRootJobId(pipeline?.root_job_id || null);
    }
  }, [pipelines, pipelineId, modalRootJobId]);


  return (
    <>
      {modalRootJobId && (
        <JobTreeModal
          rootJobId={modalRootJobId}
          onCancel={() => {
            setModalRootJobId(null); // Reset modal ID when closing
            navigate('/pipelines');
          }}
        />
      )}
      <Table
        loading={isLoading || isFetching}
        dataSource={pipelines?.items}
        columns={columns}
        rowKey="id"
        pagination={{ current: page, pageSize: pageSize, total: totalItems, showTotal: total => `${total} Annotation Pipelines` }}
        onChange={handleTableChange}
        sticky
      />
    </>
  );
};

export default PipelineTable;