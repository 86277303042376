import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { v4 as uuidv4 } from 'uuid';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAnnotationEvent, getCurrentUser, getRoundedPolygonPixels } from 'utils/Utils';

import {
  selectIsAnnotatingEnabled,
  setScrollToAnnotationId,
} from 'redux/slices/viewerOptions';
import {
  appendToChangeset,
  extendChangeset,
  setSelectedAnnotationIds,
} from 'redux/slices/annotationDetails';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { Modify } from 'ol/interaction';
import { Collection, Feature, Map } from 'ol';
import { Layer } from 'ol/layer';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { useROIs } from 'redux/hooks';

interface ROIInteractionProps {
  source: VectorSource;
};

export default function ROIInteraction({ source }: ROIInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const { rois } = useROIs();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);

  const drawRoiRef = useRef<Draw | null>(null);
  const selectRoiRef = useRef<Select | null>(null);
  const modifyRoiRef = useRef<Modify | null>(null);

  const __handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        if (drawRoiRef.current) {
          drawRoiRef.current.abortDrawing();
        }
        break;
      case 'Backspace':
        if (drawRoiRef.current) {
          drawRoiRef.current.removeLastPoint();
        }
        break;
      case 'Delete':
        deleteROI();
        break;
      default:
        break;
    }
  };

  const deleteROI = () => {
    const selectedROIs = selectRoiRef.current ? selectRoiRef.current.getFeatures().getArray() : [];
    const events = selectedROIs.map(roiFeature => createAnnotationEvent({ type: 'DELETE_ROI', payload: { id: roiFeature.getId() } }));
    dispatch(extendChangeset(events));
  }

  const isLocationNotEmpty = (coordinate: Array<number>, layerName: string, olMap: Map) => {
    const pixel = olMap.getPixelFromCoordinate(coordinate);
    const olFeaturesAtLocation = olMap.getFeaturesAtPixel(pixel, {
      layerFilter: (layer: Layer) => {
        return layer.get('name') === layerName;
      },
    });
    return olFeaturesAtLocation.length > 0;
  };

  const drawRoiOnDrawEnd = (event: DrawEvent) => {
    if (!map) return;
    const tempId = uuidv4();
    event.feature.setId(tempId);

    const coordinates = (event.feature.getGeometry() as Polygon).getCoordinates();

    const doesPolygonIntersectWithExisting = coordinates[0].some(coordinate =>
      isLocationNotEmpty(coordinate, OL_LAYER_NAME.HOTSPOTS, map),
    );

    if (doesPolygonIntersectWithExisting) return;

    source.addFeatures([event.feature]);

    dispatch(
      appendToChangeset(createAnnotationEvent({
        type: 'ADD_ROI',
        payload: {
          id: tempId,
          name: `Annotation_${rois.length}`,
          coordinates: getRoundedPolygonPixels(coordinates),
        }
      })),
    );
  };

  const handleModifyRoiStart = () => {
    if (drawRoiRef.current) {
      drawRoiRef.current.abortDrawing();
    }
  };

  const handleModifyRoiEnd = (event: ModifyEvent) => {
    const feature = event.features.getArray()[0];
    if (!feature) return;

    const featureGeometry = feature.getGeometry() as Polygon;
    const featureCoordinates = featureGeometry.getCoordinates();
    const roiId = feature.getId();

    if (!roiId) return;

    dispatch(
      appendToChangeset(createAnnotationEvent({
        type: 'UPDATE_ROI',
        payload: {
          id: roiId,
          coordinates: getRoundedPolygonPixels(featureCoordinates)
        }
      })),
    );
  };

  const initDrawHotspot = () => {
    if (!map) return;
    drawRoiRef.current = new Draw({
      source,
      type: 'Polygon',
      condition: e => {
        const roisAtPixel = map.getFeaturesAtPixel(e.pixel, {
          layerFilter: (layer: Layer) => {
            return layer.get('name') === OL_LAYER_NAME.HOTSPOTS;
          },
        });
        return e.originalEvent.button === 0 && roisAtPixel.length === 0;
      },
    });
    drawRoiRef.current.on('drawend', drawRoiOnDrawEnd);
    map.addInteraction(drawRoiRef.current);
  };

  const initModifyHotspot = () => {
    if (!map) return;

    if (modifyRoiRef.current) {
      map.removeInteraction(modifyRoiRef.current);
    }
    const selectedROIs = selectRoiRef.current ? selectRoiRef.current.getFeatures().getArray() : [];
    modifyRoiRef.current = new Modify({ features: new Collection(selectedROIs) });
    modifyRoiRef.current.on('modifystart', handleModifyRoiStart);
    modifyRoiRef.current.on('modifyend', handleModifyRoiEnd);
    map.addInteraction(modifyRoiRef.current);
  };

  const handleSelectHotspot = (event: SelectEvent) => {
    if (drawRoiRef.current) {
      drawRoiRef.current.abortDrawing();
    }
    event.deselected.forEach(deselectedFeature => {
      deselectedFeature.set('is_selected', false);
    });
    if (!event.selected[0]) return;
    const selectedFeature = event.selected[0];
    if (selectedFeature.get('is_filtered') === 'true') return;
    selectedFeature.set('is_selected', true);
    const roiId = selectedFeature.getId() as string;
    if (roiId) {
      dispatch(setScrollToAnnotationId(roiId));
      dispatch(setSelectedAnnotationIds([roiId]));
    }
    initModifyHotspot();
  };

  const initSelectHotspot = () => {
    if (!map) return;
    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => a.get('is_selected')),
    );
    selectRoiRef.current = new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.HOTSPOTS;
      },
      features: selectedFeatures,
      style: [
        new Style({
          stroke: new Stroke({
            color: '#ffffff',
            width: 5,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: '#0099ff',
            width: 3,
          }),
        }),
      ],
    });
    selectRoiRef.current.on('select', handleSelectHotspot);
    map.addInteraction(selectRoiRef.current);
  };

  window.roiAnnotationSource = source;

  useEffect(() => {
    if (!map || !source || !isAnnotatingEnabled) return;

    initDrawHotspot();
    initModifyHotspot();
    initSelectHotspot();

    document.addEventListener('keydown', __handleKeyDown);

    return () => {
      if (map) {
        if (modifyRoiRef.current) {
          map.removeInteraction(modifyRoiRef.current);
          modifyRoiRef.current.un('modifystart', handleModifyRoiStart);
          modifyRoiRef.current.un('modifyend', handleModifyRoiEnd);
        }

        if (selectRoiRef.current) {
          map.removeInteraction(selectRoiRef.current);
          selectRoiRef.current.un('select', handleSelectHotspot);
        }

        if (drawRoiRef.current) {
          map.removeInteraction(drawRoiRef.current);
          drawRoiRef.current.un('drawend', drawRoiOnDrawEnd);
        }

        document.removeEventListener('keydown', __handleKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotatingEnabled, map, rois]);

  return null;
}
