import {
    ClockCircleOutlined, EditOutlined, EllipsisOutlined,
    PictureOutlined, SyncOutlined
} from "@ant-design/icons";
import {
    Row, Col, Card, Pagination, Spin, Tag, Space, Select, Layout
} from "antd";
import WSIThumbnail from 'components/WSIThumbnail';
import JobTreeModal from "components/JobTreeModal";
import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetClassificationScenariosQuery, useGetPipelinesQuery } from "redux/slices/imageServerApi";

interface PipelineFilters {
    classificationScenarioId?: string;
    backlogId?: string;
    priority?: number;
    workflowId?: string;
}

const PRIORITY_OPTIONS = [
    { value: 0, label: 'Low' },
    { value: 1, label: 'Normal' },
    { value: 2, label: 'High' },
    { value: 3, label: 'Urgent' },
];

const PipelineGrid = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { pipelineId } = useParams();

    const [page, setPage] = useState(1);
    const [modalRootJobId, setModalRootJobId] = useState<string | null>(null);

    const navigate = useNavigate();

    const initialFilters: PipelineFilters = useMemo(() => ({
        classificationScenarioId: searchParams.get('classification_scenario') ?? undefined,
        backlogId: searchParams.get('backlog_id') ?? undefined,
        priority: searchParams.get('priority') ? parseInt(searchParams.get('priority')!) : undefined,
        workflowId: searchParams.get('workflow_id') ?? undefined,
    }), [searchParams]);

    const [filters, setFilters] = useState<PipelineFilters>(initialFilters);

    const { data: pipelines, isLoading: isLoadingPipelines, isFetching: isFetchingPipelines } = useGetPipelinesQuery({ page, ...filters });
    const { data: classificationScenarios, isLoading: isLoadingClassificationScenarios } = useGetClassificationScenariosQuery();

    const handlePageChange = (page: number) => {
        setPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getPriorityLabel = (priority?: number) => {
        return PRIORITY_OPTIONS.find(option => option.value === priority)?.label ?? 'Unknown';
    };

    useEffect(() => {
        const newSearchParams = new URLSearchParams();
        if (filters.classificationScenarioId) newSearchParams.set('classification_scenario', filters.classificationScenarioId);
        setSearchParams(newSearchParams);
    }, [filters, setSearchParams]);

    useEffect(() => {
        const pipeline = pipelines?.items.find(ap => ap.id === pipelineId);
        setModalRootJobId(pipeline ? pipeline.root_work_item_id : null);
    }, [pipelines, pipelineId]);

    return (
        <Layout>
            <Spin tip="Loading Annotation Pipelines..." spinning={isLoadingPipelines || isFetchingPipelines}>
                <Space wrap style={{ padding: '8px 16px' }}>
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFilters({ ...filters, classificationScenarioId: value })}
                        loading={isLoadingClassificationScenarios}
                        placeholder="Classification Scenario"
                        allowClear
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        options={classificationScenarios?.map(c => ({ value: c.id, label: c.name }))}
                    />
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFilters({ ...filters, backlogId: value })}
                        placeholder="Backlog"
                        allowClear
                    />
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFilters({ ...filters, priority: value })}
                        placeholder="Priority"
                        allowClear
                        options={PRIORITY_OPTIONS}
                    />
                    <Select
                        style={{ width: 120 }}
                        onChange={(value) => setFilters({ ...filters, workflowId: value })}
                        placeholder="Workflow"
                        allowClear
                    />
                </Space>
                <div className="hotspot-image-grid">
                    <Row gutter={[16, 24]} style={{ marginRight: 0 }}>
                        {pipelines?.items.map(pipeline => (
                            <Col className="gutter-row" style={{ width: '25%' }} key={pipeline.id}>
                                <Card
                                    id={pipeline.id}
                                    className="grid-item"
                                    onClick={() => navigate(`/pipelines/${pipeline.id}`)}
                                    size="small"
                                    hoverable
                                    cover={<WSIThumbnail height={200} image={pipeline.image} />}
                                >
                                    <Card.Meta
                                        title={pipeline.id}
                                        description={
                                            <>
                                                <Tag icon={<PictureOutlined />}>{pipeline.image.name}</Tag>
                                                <Tag icon={<EditOutlined />} color="magenta">{classificationScenarios?.find(cs => cs.id === pipeline.classification_scenario)?.name ?? 'Unknown Scenario'}</Tag>
                                                {pipeline.is_wip && <Tag icon={<SyncOutlined spin />} color="processing">WIP</Tag>}
                                                <Tag icon={<ClockCircleOutlined />} color="purple">{`Priority: ${getPriorityLabel(pipeline.priority)}`}</Tag>
                                                <Tag icon={<EllipsisOutlined />} color="blue">{`Leaf Jobs: ${pipeline.num_leaf_jobs}`}</Tag>
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <JobTreeModal
                        rootJobId={modalRootJobId}
                        onCancel={() => navigate('/pipelines')}
                    />
                    <Pagination current={page} onChange={handlePageChange} total={pipelines?.count ?? 0} pageSize={100} />
                </div>
            </Spin>
        </Layout>
    );
};

export default PipelineGrid;
