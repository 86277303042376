import { Badge, Radio, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useGetClassificationScenarioQuery } from "redux/slices/imageServerApi";
import { selectActiveAnnotationClassification, selectIsAnnotatingEnabled, setActiveAnnotationClassification } from "redux/slices/viewerOptions";
import { lightOrDark } from "utils/Utils";

interface ClassificationSelectionProps {
    classificationScenarioId: string;
}

const ClassificationSelection: React.FC<ClassificationSelectionProps> = ({ classificationScenarioId }) => {
    const dispatch = useDispatch();

    const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
    const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);

    const { data: classificationScenario } = useGetClassificationScenarioQuery({ id: classificationScenarioId })

    if (!classificationScenario) return null;
    const { classifications } = classificationScenario;

    const handleClassificationChange = (class_id: string) => {
        dispatch(setActiveAnnotationClassification(class_id));
    }

    return (<Radio.Group
        onChange={e => handleClassificationChange(e.target.value)}
        defaultValue={classifications.length > 0 ? classifications[0].name : ''}
        disabled={!isAnnotatingEnabled}
        buttonStyle="solid"
        value={activeAnnotationClassification}
        className="annotation-class-selection"
    >
        {classifications.length > 0 &&
            classifications.map(classification => {
                let annotationCount = 0;
                return (
                    <Radio.Button key={classification.name} value={classification.id} style={{}}>
                        <Tooltip placement="bottom" title={`${classification.name} (${annotationCount})`}>
                            <Badge
                                className="classification-button-badge"
                                color={isAnnotatingEnabled ? classification.color : '#aaaaaa'}
                            />
                            <Tag color="#fafafa" style={{ marginLeft: 4, color: '#000000', border: '1px solid #d9d9d9' }}>
                                {classification.hot_key}{' '}
                            </Tag>
                            <Badge
                                className={`dot-annotation-badge-${classification.name}`}
                                showZero
                                overflowCount={999}
                                count={annotationCount}
                                style={{
                                    backgroundColor: isAnnotatingEnabled ? classification.color : '#aaaaaa',
                                    color: lightOrDark(classification.color) === 'light' ? '#000000' : undefined,
                                }}
                            />
                        </Tooltip>
                    </Radio.Button>
                );
            })}
    </Radio.Group>)
}

export default ClassificationSelection