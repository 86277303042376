import { useEffect } from 'react';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from 'assets/images/mindpeak_logoform_digital_purple_borderless.png';
import { useSelector } from 'react-redux';
import {
  authenticateImageServer,
  refreshAuthentication,
  selectIsLoginLoading,
  selectIsReAuthDialogShown,
  selectLoginError,
  selectParsedAccessToken,
  setIsReAuthDialogShown,
} from 'redux/slices/userSlice';
import { useAppDispatch } from 'utils/Hooks';

export default function UserReAuthDialog() {
  const dispatch = useAppDispatch();
  const showUserReAuthDialog = useSelector(selectIsReAuthDialogShown);
  const loggingIn = useSelector(selectIsLoginLoading);
  const loginError = useSelector(selectLoginError);

  const parsedAccessToken = selectParsedAccessToken();

  useEffect(() => {
    if (!parsedAccessToken) {
      dispatch(setIsReAuthDialogShown(true));
      return;
    }
    const { exp, iat } = parsedAccessToken;
    const checkAuthState = () => {
      // If there is an "iat" field, the token is potentially refreshable
      if (iat || !parsedAccessToken) {
        // ... if the token is not expired
        if (exp * 1000 > Date.now()) {
          // If the token is older than 12 hours, we want to refresh it
          if ((iat + 43200) * 1000 < Date.now()) {
            dispatch(refreshAuthentication());
          }
          // The token is still valid, or got refreshed. Everything is fine.
        }
      } else {
        // If we reach here, we need to show the re-auth dialog
        dispatch(setIsReAuthDialogShown(true));
      }
    };

    const _onLoadListener = () => checkAuthState();

    const _onVisibilityChangeListener = () => {
      if (document.visibilityState === 'visible') {
        checkAuthState();
      }
    };

    if (document.readyState === 'complete') {
      checkAuthState();
    } else {
      window.addEventListener('load', _onLoadListener);
    }

    window.addEventListener('visibilitychange', _onVisibilityChangeListener);

    return () => {
      // Clean up when unmounting component.
      window.removeEventListener('load', _onLoadListener);
      window.removeEventListener('visibilitychange', _onVisibilityChangeListener);
    };
  }, [dispatch, parsedAccessToken]);

  const handleSubmit = (values: { email: string; password: string }) => {
    dispatch(authenticateImageServer({ username: values['email'].trim(), password: values['password'] })).then(
      (res: any) => {
        if (!res.error) {
          window.location.reload();
        }
      },
    );
  };

  return (
    <Modal
      open={showUserReAuthDialog}
      okText="Login"
      footer={null}
      style={{ overflowY: 'hidden' }}
      closable={false}
      maskClosable={false}
      keyboard
    >
      <Form initialValues={{ remember: true }} layout="vertical" onFinish={handleSubmit}>
        <Form.Item style={{ textAlign: 'center' }}>
          <img width={360} src={logo} alt="Mindpeak Logo" />
        </Form.Item>
        <Form.Item>
          <Alert message="Your session has expired. Please log in again." type="warning" showIcon />
        </Form.Item>
        <Form.Item label="Username" name="email" rules={[{ required: true, message: 'Please input your Username!' }]}>
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            size="large"
            autoComplete="current-username"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{ width: '100%', marginTop: 16 }}
            loading={loggingIn}
            size="large"
          >
            {loggingIn ? 'Logging In' : 'Log in'}
          </Button>
        </Form.Item>
        {loginError && <Alert message={loginError.name} description={loginError.message} type="error" showIcon />}
      </Form>
    </Modal>
  );
}
