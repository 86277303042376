import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { z } from 'zod';

import { getAuthorization } from 'service/api'

const imageReaderUrl = new URL(`${window._env_.REACT_APP_READER_API_URL || 'http://localhost:8008'}/api/v1/image/iiif/`);


const imageInfoSchema = z.object({
    '@context': z.string(),
    id: z.string(),
    type: z.string(),
    protocol: z.string(),
    profile: z.string(),
    width: z.number(),
    height: z.number(),
    tiles: z.array(
        z.object({
            width: z.number(),
            height: z.number(),
            scaleFactors: z.array(z.number())
        })
    ),
    extraFeatures: z.array(z.string()),
    extraFormats: z.array(z.string()),
    preferredFormats: z.array(z.string()),
    micronsPerPixel: z.number().nullable(),
    layers: z.array(z.any()).optional()
});

export type ImageInfo = z.infer<typeof imageInfoSchema>;

export const imageReaderApi = createApi({
    reducerPath: 'imageReaderApi',
    baseQuery: fetchBaseQuery({
        baseUrl: imageReaderUrl.toString(),
        prepareHeaders: headers => {
            const token = getAuthorization()
            if (token) {
                headers.set('Authorization', token)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
        getImageInfo: builder.query<ImageInfo, string>({
            query: (imageFileName) => `${imageFileName}/info.json`,
            transformResponse: response => {
                return imageInfoSchema.parse(response);
            }
        }),
    }),
})

export const { useGetImageInfoQuery } = imageReaderApi;